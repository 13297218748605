/* eslint-disable no-console */
import { UIEvent, PhotoEditorSDKUI } from 'photoeditorsdk';
import { PhotoProvider } from './PhotoProvider';
import React from 'react';
import PropTypes from 'prop-types';

export class PhotoEditorSDK extends React.Component {
    componentDidMount() {
        this.initEditor();
    }
    async initEditor() {
        document.getElementsByTagName('html')[0].classList.add('overflow-none');
        const editor = await PhotoEditorSDKUI.init({
            container: '#editor',
            layout:    'basic',
            export:    {
                image: {
                    enableDownload: false
                }
            },
            library: {
                flattenCategories: true,
                provider:          PhotoProvider
            },
            custom: {
                languages: {
                    en: {
                        mainCanvasActions: {
                            buttonExport: 'Save'
                        }
                    }
                }
            },
            image:   this.props.imageUrl, // Image url or Image path relative to assets folder
            license: '{"api_token":"Tw6NGHfTXy44lj6f6RlSNw","app_identifiers":["*.myuniverse.com","myuniverse.com"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","customassets","whitelabel","adjustment","brush","filter","overlay","sticker","text","textdesign","transform"],"issued_at":1613024791,"minimum_sdk_version":"1.0","owner":"My Universe, LLC","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"j2xTiWJI+Cfy0PikXNF+DjzztJKBZCrXehyseQjyHdGzOYo1YA1pOz9XSK6AkA3FbP5dnaY5nO6OqvXT3ARxm1ZZKlZwvTU6ZufleeRbkUf37+2EXIxH8uXPKIgvUYDyW5K5YxnIgOSF2ZAEeN10h2OUEtKWQ5nyA/yUMjQ/xHKAbzI+TFSId4evy78SPFf+sQZxEOKudfyevireXr4JLyWfyRTLkzp2SWTvhzhIcPfrCxfQCFkdSURu+Fo9nX0Qxl8PmoX1QInmdDhJsa4dir/OgN3RbcRVFHRyHPEYL8yvPUZRaAMPaxmkFpysIlFulTQVGUmLNrXpnTPTmM9d8IZ6sZ4Yr9ukDGZChqdcLWt4L/e6cUlOMVHezomA6gC8mbcQr7glguYWybJMtsMz7Og6I74voq91KhzopVrDIla9wecn5KJJxJhGQtS9wNEPrXvgwMKJNuni/uzRXFxZrvVGIhI/b65C8cxInNsHmZwPAyElE4QbpRL3vN6tfuZ7Wg8B8h8mipa0ouhjNXpRaPVTTVnUHUEiJa+AoZr9Tlo9LP/Yhv1zTDFivdurwhhMyp2q03RFF7eQspWr336XRAAjnNo9wt9R3e4fE13WC6+QNo4Hj7XOdvpwH1mCTqs14Yz4F2n8n1cqi2e5bXUqTTzmBgCIWPPqJFc5YoLSu+4="}',
        });
        console.log('PhotoEditorSDK for Web is ready!');

        editor.on(UIEvent.CLOSE, () => {
            this.props.setEditor(false);
            document.getElementsByTagName('html')[0].classList.remove('overflow-none');
            editor.dispose();
        });

        editor.on(UIEvent.EXPORT, (data) => {

            let eventMock = {
                target: {
                    files: [this.dataURLtoFile(data.src, 'testname')]
                }
            };
            this.props.uploadImage(eventMock, this.props.type);
            editor.close();
        });

    }

dataURLtoFile = (dataurl, filename) => {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
}

render() {
    return (
        <div
            role="PhotoEditor SDK"
            id="editor"
            style={{ zIndex: '500', position: 'absolute', top: '0', left: '0', width: '100vw', height: '100vh' }}
        />
    );
}

}

PhotoEditorSDK.propTypes = {
    imageUrl:    PropTypes.object,
    uploadImage: PropTypes.func,
    type:        PropTypes.string,
    setEditor:   PropTypes.func
};
