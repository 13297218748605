import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import { ProfileSettings } from './ProfileSettings';
import { VerifiedUsersAdmin } from './VerifiedUsersAdmin';
import { BannedUsersAdmin } from './BannedUsersAdmin';
import { PublicExplore } from './PublicExplore';
import { capsuleActions, userActions } from '../redux/actions';
import { ProfileMedia } from './ProfileMedia';
import { isObjectExists, getUserTokenFromStorage, convertCapsule } from '../../utils/Helpers';
import { CapsuleEdit } from '../profile/EditCapsuleView';
import { VCardEdit } from '../profile/EditVcardView';
import { ConfirmationView } from '../profile/ConfirmationView';
import { GroupEdit } from '../profile/EditGroupsView';
import { ModalView } from '../profile/ModalView';
import { SocialEdit } from '../profile/EditSocialView';
import { arrayMove } from 'react-sortable-hoc';
//import { isObjectExists} from '../../utils/Helpers';
//import cardAdded from '../../assets/images/CardAddedIcon@3x.png';

export class ProfileHome extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            profileView:          'main',
            mainCapsule:          convertCapsule(this.props.capsules.mainCapsule),
            confirmationType:     'profile',
            tempGroups:           isObjectExists(this.props, 'capsules.mainCapsule.groups') ? this.props.capsules.mainCapsule.groups : [],
            isGroupsOpen:         false,
            tempExploreGroups:    isObjectExists(this.props, 'capsules.mainCapsule.exploreGroups') ? this.props.capsules.mainCapsule.exploreGroups : [],
            isBackgroundUrlImage: false,
            isAdminUser:          false,
            deleteMeValue:        false,
            isPhoto2:             isObjectExists(this.props, 'capsules.mainCapsule.items.contact.image.profile.url') && this.props.capsules.mainCapsule.items.contact.image.profile.url !== '' && isObjectExists(this.props, 'capsules.mainCapsule.items.contact.image.profile.fileId')
                                    && this.props.capsules.mainCapsule.items.contact.image.profile.fileId !== '' && this.props.capsules.mainCapsule.items.contact.image.profile.fileId !== undefined ? true : false,
            isPhoto3:             isObjectExists(this.props, 'capsules.mainCapsule.items.contact.image.profile.url') && this.props.capsules.mainCapsule.items.contact.image.profile.url !== '' && isObjectExists(this.props, 'capsules.mainCapsule.items.contact.image.profile.backgroundImageURL')
                                    && this.props.capsules.mainCapsule.items.contact.image.profile.backgroundImageURL !== '' && this.props.capsules.mainCapsule.items.contact.image.profile.backgroundImageURL !== undefined ? true : false
        };

        this.disableDeleteTextSwitch = 'Switch To Deleted Accounts';
        this.bannedTextSwitch = 'Switch To Banned Accounts';

    }

    componentDidMount() {
        this.props.dispatch(userActions.getFiles(this.props.user.token));
        this.props.dispatch(userActions.getGoogleAuthLink());
        if (this.props.user.admin === true) {
            this.setState({isAdminUser: true});
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if ((prevProps.user.newFileId !== this.props.user.newFileId) && this.props.user.picture && this.props.user.fileUploaded) {
            this.props.dispatch(userActions.getFile(this.getId(Object.keys(this.props.user.newFileId), this.props.user.newFileId)));
        }

        if (!prevProps.user.gotFile && this.props.user.gotFile === true) {
            this.addToSection(this.props.user.requestedFile);
        }

        if (!prevProps.capsules.gotMainCapsule  && this.props.capsules.gotMainCapsule) {
            this.props.dispatch(capsuleActions.getFavourites());
            this.setState({isConfirmation: true});

        }

        if (prevState.isConfirmation && !this.state.isConfirmation) {
            this.props.dispatch(userActions.getCapsules());
            this.props.dispatch(capsuleActions.getCapsuleGroups());
            this.props.dispatch(userActions.getUserCapsuleCount());
        }

    }

    getId = (keys, obj) => {
        let id;
        for (let i in keys) {
            if (obj[keys[i]].customIdentifier) {
                id = obj[keys[i]].customIdentifier;
            }
        }
        return id;
    }

    save = () => {
        if (!this.props.capsules.mainCapsuleInProgress) {
            this.props.dispatch(capsuleActions.updateMainCapsule(this.state.mainCapsule, this.state.mainCapsule.capsule.id, false, false, true));
            //this.setState({isConfirmation: true});
        }
    }

    uploadPicture = (e) => {
        const fileToUpload = e.target.files;

        this.props.dispatch(userActions.uploadFile(fileToUpload, this.props.user.token, 'picture'));
    }

    back = () => {
        this.setState({profileView: 'main'});
    }

    getIfVideo = () => {
        let result = '250x200';

        /*if (isObjectExists(image, 'metadata.mimeType') && -1 !== image.metadata.mimeType.indexOf('image/')) {
            result = this.getPreviewResizerParameters();
        }*/

        return result;
    }

    addToSocial = () => {
        let newCapsule = {...this.state.mainCapsule};
        var socialProfile = newCapsule.social.socialProfiles;
        socialProfile.push({
            label: 'Website',
            value: {
                service:   'Website',
                toolbar:   true,
                urlString: ''
            }
        });
        newCapsule.social.socialProfiles = socialProfile;
        this.setState({mainCapsule: newCapsule, itemAdded: true});

    }

    removeFromSocial = (i) => {
        var socialProfile = this.state.mainCapsule.social.socialProfiles;
        let newCapsule = this.state.mainCapsule;
        socialProfile.splice(i, 1);
        newCapsule.social.socialProfiles = socialProfile;
        this.setState({mainCapsule: newCapsule});
    }

    addField = (fieldName) => {
        let newCapsule = {...this.state.mainCapsule};
        var socialProfile = newCapsule.social.socialProfiles;

        if (fieldName === 'urlAddress') {
            socialProfile.push({
                label: 'Home',
                value: {
                    service:   'Website',
                    toolbar:   true,
                    urlString: ''
                }
            });

        } else if (fieldName === 'locationAddress') {
            newCapsule.vCard[fieldName].push({
                label: 'Home',
                value: {
                    street:     '',
                    city:       '',
                    state:      '',
                    postalCode: '',
                    country:    ''
                }});
        } else if (fieldName === 'dates') {
            newCapsule.vCard.dates.push({label: 'anniversary', value: ''});
        } else {
            newCapsule.vCard[fieldName].push({label: 'home', value: ''});
        }

        this.setState({mainCapsule: newCapsule, itemAdded: true});
    }

    removeField = (fieldName, i) => {
        let newCapsule = {...this.state.mainCapsule};

        newCapsule.vCard[fieldName].splice(i, 1);

        this.setState({mainCapsule: newCapsule});

    }

    removeUserImages = (type, typeOfWebsiteBackground) => {
        let newCapsule = {...this.state.mainCapsule};
        //all removal for capsule image ends up here regardless imaage,website,background website inc thumbnail
        if (type === 'profile' && typeOfWebsiteBackground === 'urlImage') {
            //keep website url and remove background image
            let url = this.state.mainCapsule.social.image[type].url;
            let compactView = this.state.mainCapsule.social.image[type].metadata.compactView;
            newCapsule.social.image[type] = {
                url:      url,
                fileId:   '',
                filename: '',
                metadata: {
                    mimeType:    'text/html',
                    hasPreview:  false,
                    storageUUID: '',
                    storageType: '',
                    compactView: compactView
                }};
            this.setState({isPhoto2: false});
        } else if (type === 'profile' && typeOfWebsiteBackground === 'urlBackgroundImage') {
            //keep website url and remove background image url
            let url = this.state.mainCapsule.social.image[type].url;
            let compactView = this.state.mainCapsule.social.image[type].metadata.compactView;
            newCapsule.social.image[type] = {
                url:                url,
                backgroundImageURL: '',
                metadata:           {
                    mimeType:    'text/html',
                    compactView: compactView
                }
            };
            this.setState({isPhoto3: false});
        } else {
            newCapsule.social.image[type] = '';
        }

        this.setState({mainCapsule: newCapsule, save: true});
    }

    getSrcUrl = (type) => {
        if (type === 'profile' || type === 'thumbnail') {
            if (isObjectExists(this.state, 'mainCapsule.social.image.'+ type + '.url') && this.state.mainCapsule.social.image[type].url !== '') {
                if (isObjectExists(this.state, 'mainCapsule.social.image.'+ type + '.backgroundImageURL') && this.state.mainCapsule.social.image[type].backgroundImageURL !== '') {
                    return {url: this.state.mainCapsule.social.image[type].url, type: 'url', backgroundUrl: this.state.mainCapsule.social.image[type].backgroundImageURL, backgroundType: 'backgroundUrl'};
                } else {
                    return {url: this.state.mainCapsule.social.image[type].url, type: 'url'};
                }
            } else if (isObjectExists(this.state, 'mainCapsule.social.image.' + type + '.fileId') && this.state.mainCapsule.social.image[type].fileId !== '') {
                return {url:  process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + this.state.mainCapsule.social.image[type].fileId + '/' +
                this.getIfVideo(this.state.mainCapsule.social.image[type]) + '?token=' + getUserTokenFromStorage(), type: 'file'};
            } else {
                return '';
            }
        }
    }

    addToSection = (fileObject) => {

        let objectToAdd = {
            fileId:   fileObject.id,
            metadata: {
                mimeType:    fileObject.mimeType,
                storageType: fileObject.storageType,
                storageUUID: fileObject.storageUUID
            }

        };

        if (fileObject.name) objectToAdd.filename = fileObject.name;
        this.props.dispatch(userActions.getFirebaseStatus(fileObject.storageUUID));
        this.props.listenStatus(fileObject.storageUUID);
        if (this.state.profileView === 'main') {
            this.addToImages(objectToAdd, 'profile');
        } else if (this.state.profileView === 'ecard') {
            this.addToImages(objectToAdd, 'thumbnail');
        }
        this.props.setCheckingProgressOf('capsuleMedia');

    }

    onCapsuleChange = (e, category, i, service) => {
        let newCapsule = {...this.state.mainCapsule};

        if (category === 'vCard' && i) {
            switch (service) {
                case 'phoneType':
                    newCapsule[category].phoneNumber[i].label = e.target.value;
                    break;
                case 'phoneNumber':
                    newCapsule[category].phoneNumber[i].value = e.target.value;
                    break;
                case 'emailType':
                    newCapsule[category].emailAddress[i].label = e.target.value;
                    break;
                case 'emailAddress':
                    newCapsule[category].emailAddress[i].value = e.target.value;
                    break;
                case 'urlType':
                    newCapsule.social.socialProfiles[i].label = e.target.value;
                    break;
                case 'urlAddress':
                    newCapsule[category].urlAddress[i].value = e.target.value;
                    break;
                case 'addressType':
                    newCapsule[category].locationAddress[i].label = e.target.value;
                    break;
                case 'dateType':
                    newCapsule[category].dates[i].label = e.target.value;
                    break;
                case 'dates':

                    //newCapsule[category].dates[i].value = moment(e).format();
                    break;
                default:
                    newCapsule[category].locationAddress[i].value[e.target.name] = e.target.value;
            }

        } else if (category === 'social' && !isNaN(i) && service && service !== 'url') {
            newCapsule[category].socialProfiles[i].label = e.target.value;
            newCapsule[category].socialProfiles[i].value.urlString = this.getSocialBaseUrl(e.target.value, newCapsule[category].socialProfiles[i].value.urlString);
            newCapsule[category].socialProfiles[i].value.service = e.target.value;
        } else if (category === 'social' && !isNaN(i)) {
            newCapsule[category].socialProfiles[i].label = service === 'url' ? 'Home' : e.target.value;
            newCapsule[category].socialProfiles[i].value.urlString = this.getSocialBaseUrl(e.target.value, newCapsule[category].socialProfiles[i].value.urlString);
            newCapsule[category].socialProfiles[i].value.service = service === 'url' ? 'Home' : e.target.value;
            newCapsule = this.changeSocialUrl(e.target.value, newCapsule, i, service);
        } else if (category === 'profileUrl') {
            if (this.props.isFileImage(e).mimeType) {
                //if website url is an image url link
                newCapsule.social.image.profile = {fileId: '', filename: '', backgroundImageURL: '', url: e, metadata: {mimeType: this.props.isFileImage(e).mimeType, storageType: '', storageUUID: '', hasPreview: false, compactView: true}};
                this.setState({isPhoto2: false, isPhoto3: false});
            } else if (isObjectExists(this.state, 'mainCapsule.social.image.profile.url') && this.state.mainCapsule.social.image.profile.url !== '' && isObjectExists(this.state, 'mainCapsule.social.image.profile.fileId') && this.state.mainCapsule.social.image.profile.fileId !== '') {
                // if cover image was previously saved, keep it
                let compactView = isObjectExists(this.state, 'mainCapsule.social.image.profile.metadata.compactView') && this.state.mainCapsule.social.image.profile.metadata.compactView !== '' ? this.state.mainCapsule.social.image.profile.metadata.compactView : true;
                newCapsule.social.image.profile = {
                    url:      e,
                    metadata: {
                        compactView: compactView,
                    }};
            } else if (isObjectExists(this.state, 'mainCapsule.social.image.profile.url') && this.state.mainCapsule.social.image.profile.url !== '' && isObjectExists(this.state, 'mainCapsule.social.image.profile.backgroundImageURL') && this.state.mainCapsule.social.image.profile.backgroundImageURL !== '') {
                // if cover image URL was previously saved, keep it
                let compactView = isObjectExists(this.state, 'mainCapsule.social.image.profile.metadata.compactView') && this.state.mainCapsule.social.image.profile.metadata.compactView !== '' ? this.state.mainCapsule.social.image.profile.metadata.compactView : true;
                newCapsule.social.image.profile = {
                    url:      e,
                    metadata: {
                        compactView: compactView,
                    }};
            } else {
                // no URL background image or url existed, set website link
                newCapsule.social.image.profile = {
                    fileId:   '',
                    filename: '',
                    url:      e,
                    metadata: {
                        mimeType:    'text/html',
                        compactView: true,
                        hasPreview:  false,
                        storageUUID: '',
                        storageType: ''
                    }};
            }
        } else if (category === 'profileBackgroundUrl') {
            if (this.props.isFileImage(e).mimeType) {
                newCapsule.social.image.profile = {
                    fileId:             '',
                    filename:           '',
                    url:                isObjectExists(this.state, 'mainCapsule.social.image.profile.url') && this.state.mainCapsule.social.image.profile.url !== '' ? this.state.mainCapsule.social.image.profile.url : '',
                    backgroundImageURL: e,
                    metadata:           {
                        mimeType:    this.props.isFileImage(e).mimeType,
                        storageType: '',
                        storageUUID: '',
                        compactView: isObjectExists(this.state, 'mainCapsule.social.image.profile.metadata.compactView') && this.state.mainCapsule.social.image.profile.metadata.compactView !== '' ? this.state.mainCapsule.social.image.profile.metadata.compactView : false
                    }};
                this.setState({isPhoto3: true});
            }
        } else if (category === 'thumbnailUrl') {

            newCapsule.social.image.thumbnail = {fileId: '', url: e, source: e};
        } else if (category === 'photos') {
            newCapsule.media.images.push({url: e});
        } else if (category === 'videos') {
            newCapsule.media.videos.push({url: e});
        } else if (category === 'audio') {
            newCapsule.media.audio.push({url: e});
        } else if (category === 'documents') {
            if (i) {
                newCapsule.media.documents[i].url = e;
            } else {
                newCapsule.media.documents.push({url: e});
            }

        } else if (category === 'mediaName') {
            newCapsule.media[e.target.name][i].filename = e.target.value;
        } else if (i) {
            newCapsule[category][e.target.name][i] = e.target.value;
        } else {
            newCapsule[category][e.target.name] = e.target.value;

        }
        this.setState({mainCapsule: newCapsule, isCapsuleChanged: true});
    }

    setIsPhoto2 = (value) => {
        if (this.state.isPhoto2 !== value) {
            this.setState({isPhoto2: value});
        }
    }

    setIsPhoto3 = (value) => {
        if (this.state.isPhoto3 !== value) {
            this.setState({isPhoto3: value});
        }
    }

    setIsBackgroundUrlImage = (value) => {
        if (this.state.isBackgroundUrlImage !== value) {
            this.setState({isBackgroundUrlImage: value});
        }
    }

    addToImages = (image, type) => {
        let modifiedCapsule = this.state.mainCapsule;
        let images = this.state.mainCapsule.media.images || [];
        if (typeof(type) !== 'undefined') {
            if (type === 'profile' && !this.state.isBackgroundUrlImage) {
                //adding regular capsule image - remove website url, website background url image if existed, replace data with capsule image info
                image.url = '';
                image.backgroundImageURL = '';
                image.metadata.compactView = true;
                modifiedCapsule.social.image[type]={fileId: image.fileId, metadata: image.metadata, filename: image.filename, url: '', backgroundImageURL: image.backgroundImageURL};
                //this.setState({isPhoto: true, isPhoto2: false, isPhoto3: false});
                this.setState({isPhoto2: false, isPhoto3: false});
            } else if (type === 'profile' && this.state.isBackgroundUrlImage) {
                //adding uploaded background Image for website capsule so keep website url, remove background Image URL if existed and replace uploaded image data
                image.url = this.state.mainCapsule.social.image[type].url;
                image.backgroundImageURL = '';
                image.metadata.compactView = isObjectExists(this.state, 'mainCapsule.social.image.profile.metadata.compactView') && this.state.mainCapsule.social.image.profile.metadata.compactView !== '' ? this.state.mainCapsule.social.image.profile.metadata.compactView : true;
                modifiedCapsule.social.image[type]={fileId: image.fileId, metadata: image.metadata, filename: image.filename, url: image.url};
                modifiedCapsule.social.image[type]={fileId: image.fileId, metadata: image.metadata, filename: image.filename, url: image.url, backgroundImageURL: image.backgroundImageURL};
                this.setState({isPhoto2: true, isPhoto3: false});
            } else {
                modifiedCapsule.social.image[type]={fileId: image.fileId, metadata: image.metadata, filename: image.filename};
            }

            images.push({fileId: image.fileId, metadata: image.metadata, filename: image.filename});
        } else {
            images.push(image);
        }

        modifiedCapsule.media.images = images;
        this.setState({mainCapsule: modifiedCapsule, save: true});

    }

    openGroups = (isExploreGroups) => {
        isExploreGroups ? this.setState({isGroupsOpen: !this.state.isGroupsOpen, isExploreGroups: true}) : this.setState({isGroupsOpen: !this.state.isGroupsOpen});
    }

    resetGroups = () => {
        let newCapsule = {...this.state.mainCapsule};
        let tempGroups = newCapsule.capsule.groups;
        let tempExploreGroups = newCapsule.capsule.exploreGroups;

        this.setState({tempExploreGroups, tempGroups, isGroupsOpen: !this.state.isGroupsOpen, isExploreGroups: false});
    }

    onSaveGroups = () => {
        let newCapsule = {...this.state.mainCapsule};
        let tempGroups = [...this.state.tempGroups];
        let tempExploreGroups = [...this.state.tempExploreGroups];

        newCapsule.capsule.exploreGroups = tempExploreGroups;
        newCapsule.capsule.groups = tempGroups;
        this.setState({mainCapsule: newCapsule, isGroupsOpen: false, isCapsuleChanged: true, isExploreGroups: false});
    }

    createGroup = (name) => {
        if (name !== '') {
            this.props.dispatch(capsuleActions.createCapsuleGroup(name));
            this.setState({isGroupsOpen: false});
        }
    }

    onAddGroup = (id, isExplore) => {
        let tempGroups = isExplore ? [...this.state.tempExploreGroups] : [...this.state.tempGroups];
        tempGroups.push(id);
        isExplore ? this.setState({tempExploreGroups: tempGroups}) : this.setState({tempGroups});
    }

    onRemoveGroup = (id, isExplore, isFromEdit) => {

        let tempGroups = isExplore === true ? [...this.state.tempExploreGroups] : [...this.state.tempGroups];
        let index = 0;
        let newCapsule = {...this.state.mainCapsule};

        for (let i in tempGroups) {
            if (tempGroups[i] === id) {
                index = i;
            }
        }

        tempGroups.splice(parseInt(index), 1);

        if (isFromEdit && isExplore) {
            newCapsule.capsule.exploreGroups = tempGroups;
            this.setState({mainCapsule: newCapsule, tempExploreGroups: tempGroups});
        } else if (isFromEdit) {
            newCapsule.capsule.groups = tempGroups;
            this.setState({mainCapsule: newCapsule, tempGroups});
        } else {
            isExplore === true ? this.setState({tempExploreGroups: tempGroups}) : this.setState({tempGroups});
        }
    }

    toggleMenu = (menu) => {
        this.setState({[menu]: !this.state[menu]});
    }

    getSocialBaseUrl = (socialType) => {
        switch (socialType) {
            case 'facebook':
                return 'https://www.facebook.com/';
            case 'twitter':
                return 'https://www.twitter.com/';
            case 'spotify':
                return 'https://www.spotify.com/';
            case 'youtube':
                return 'https://www.youtube.com/';
            case 'instagram':
                return 'https://www.instagram.com/';
            default:
                return '';
        }
    }

    changeSocialUrl = (value, newCapsule, i, service) => {
        let modifiedCapsule = newCapsule;

        if (-1 < value.indexOf('facebook.com') && modifiedCapsule['social'].socialProfiles[i].value.service !== 'facebook') {
            modifiedCapsule['social'].socialProfiles[i].value.service = 'facebook';
            modifiedCapsule['social'].socialProfiles[i].label = 'facebook';
        } else if (-1 < value.indexOf('twitter.com') && modifiedCapsule['social'].socialProfiles[i].value.service !== 'twitter') {
            modifiedCapsule['social'].socialProfiles[i].value.service = 'twitter';
            modifiedCapsule['social'].socialProfiles[i].label = 'twitter';
        } else if (-1 < value.indexOf('instagram.com') && modifiedCapsule['social'].socialProfiles[i].value.service !== 'instagram') {
            modifiedCapsule['social'].socialProfiles[i].value.service = 'instagram';
            modifiedCapsule['social'].socialProfiles[i].label = 'instagram';
        } else if (-1 < value.indexOf('youtube.com') && modifiedCapsule['social'].socialProfiles[i].value.service !== 'youtube') {
            modifiedCapsule['social'].socialProfiles[i].value.service = 'youtube';
            modifiedCapsule['social'].socialProfiles[i].label = 'youtube';
        } else if (-1 < value.indexOf('spotify.com') && modifiedCapsule['social'].socialProfiles[i].value.service !== 'spotify') {
            modifiedCapsule['social'].socialProfiles[i].value.service = 'spotify';
            modifiedCapsule['social'].socialProfiles[i].label = 'spotify';
        } else {
            if (service === 'url') {
                modifiedCapsule['social'].socialProfiles[i].value.service = 'Home';
                modifiedCapsule['social'].socialProfiles[i].label = 'Home';
            }  else {
                modifiedCapsule['social'].socialProfiles[i].value.service = 'website';
                modifiedCapsule['social'].socialProfiles[i].label = 'website';
            }

        }

        modifiedCapsule['social'].socialProfiles[i].value.urlString = value;

        return modifiedCapsule;
    }

    getGoogleText = () => {
        if (this.props.user.expiredGoogleCredentials === false) {
            return 'Google contacts Synced';
        }

        return 'Sync Google Contacts';
    }

    renderMain = () => {
        return (
            <Container className="m-auto">

                <Row>
                    <Col className="left"><span className="navigation-button m-auto pb-5 hover" onClick={() => this.props.backToGroups()}>Back</span></Col>
                    <Col className="right"><span className="navigation-button m-auto pb-5 hover" onClick={() => this.save()}>Save</span></Col>

                </Row>
                <CapsuleEdit
                    dispatch={this.props.dispatch}
                    newCapsuleData={this.state.mainCapsule.capsule}
                    onCapsuleChange={this.onCapsuleChange}
                    user={this.props.user}
                    thumbnail={this.getSrcUrl('profile')}
                    uploadPicture={this.uploadPicture}
                    metadata={isObjectExists(this.state, 'mainCapsule.social.image.profile.metadata') ? this.state.mainCapsule.social.image.profile.metadata : null}
                    //getStatusOfMedia={this.props.getStatusOfMedia}
                    setCheckingProgressOf={this.props.setCheckingProgressOf}
                    checkingProgressOf={this.props.checkingProgressOf}
                    listenStatus={this.props.listenStatus}
                    showEditor={this.state.showEditor}
                    isProfile={true}
                    checkIfUploading={this.props.checkIfUploading}
                    isFileImage={this.props.isFileImage}
                    setIsBackgroundUrlImage={this.setIsBackgroundUrlImage}
                    isPhoto2={this.state.isPhoto2}
                    setIsPhoto2={this.setIsPhoto2}
                    isPhoto3={this.state.isPhoto3}
                    setIsPhoto3={this.setIsPhoto3}
                    removeUserImages={this.removeUserImages}

                />

                <Row className="pb-5">
                    <Col className="text-center navigation-button">Contacts</Col>
                    <Col className="text-center navigation-button" onClick={() => this.setState({profileView: 'social'})}>Social</Col>
                    <Col className="text-center navigation-button" onClick={() => this.setState({profileView: 'ecard'})}>Profile e-card</Col>
                </Row>
                <Row>
                    <Col className="text-center navigation-button" onClick={() => this.setState({profileView: 'media'})}>Media</Col>
                    <Col className="text-center navigation-button" onClick={() => this.setState({profileView: 'groups'})}>Groups</Col>
                    <Col className="text-center navigation-button" onClick={() => this.setState({profileView: 'settings'})}>Settings</Col>
                </Row>
                <Row className="mt-5">
                    {this.state.isAdminUser &&
                        <React.Fragment>
                            <Col className="text-center navigation-button" onClick={() => this.setState({profileView: 'verifiedUsers'})}>Set Accounts Public</Col>
                            <Col className="text-center navigation-button" onClick={() => this.setState({profileView: 'bannedUsers'})}>Ban/Deleted Accounts</Col>
                            <Col className="text-center navigation-button" onClick={() => this.setState({profileView: 'togglePublic'})}>Turn Music On/Off</Col>
                        </React.Fragment>
                    }
                </Row>
                <Row className="mt-5">
                    <Col className="text-center navigation-button" onClick={() => this.setState({sendgridModal: true})}>Set up SendGrid</Col>
                    <Col className="text-center navigation-button" onClick={() => window.location.href=this.props.user.authUrl}>{this.getGoogleText()}</Col>
                    <Col className="text-center navigation-button"></Col>
                </Row>
            </Container>
        );
    }

    removeFromSocial = (i) => {
        var socialProfile = this.state.mainCapsule.social.socialProfiles;
        let newCapsule = this.state.mainCapsule;
        socialProfile.splice(i, 1);
        newCapsule.social.socialProfiles = socialProfile;
        this.setState({mainCapsule: newCapsule});
    }

    addToSocial = () => {
        let newCapsule = {...this.state.mainCapsule};
        var socialProfile = newCapsule.social.socialProfiles;
        socialProfile.push({
            label: 'Website',
            value: {
                service:   'Website',
                toolbar:   true,
                urlString: ''
            }
        });
        newCapsule.social.socialProfiles = socialProfile;
        this.setState({mainCapsule: newCapsule, itemAdded: true});

    }

    onSortEnd = ({oldIndex, newIndex}) => {
        let newCapsule = this.state.mainCapsule;
        newCapsule.social.socialProfiles = arrayMove(this.state.mainCapsule.social.socialProfiles, oldIndex, newIndex);
        this.setState({
            mainCapsule: newCapsule
        });

    };

    toggleToolbar = (i) => {
        let capsuleObject = this.state.mainCapsule;
        let order = capsuleObject.social.socialProfiles;
        order[i].value.toolbar = !order[i].value.toolbar;
        capsuleObject.social.socialProfile = order;
        this.setState({mainCapsule: capsuleObject});
    }

    setUpdatedUserBanned = (value) => {
        this.props.dispatch(userActions.setUpdatedUserBanned(value));
    }

    setBannedValueDispatch = (userId, canPublishValue) => {
        this.props.dispatch(userActions.setBannedValue(userId, canPublishValue));
    }

    setGotVerifiedUsers = (value) => {
        this.props.dispatch(userActions.setGotVerifiedUsers(value));
    }

    setUpdatedUserCanPublish = (value) => {
        this.props.dispatch(userActions.setUpdatedUserCanPublish(value));
    }

    getVerifiedUsers = (verified) => {
        this.props.dispatch(userActions.getVerifiedUsers(verified));
    }

    setCanPublishValueDispatch = (userId, canPublishValue) => {
        this.props.dispatch(userActions.setCanPublishValue(userId, canPublishValue));
    }

    setExploreGroupValue = (value) => {
        this.props.dispatch(capsuleActions.setMusicValue(value));
    }

    getContent = () => {
        switch (this.state.profileView) {
            case 'main':
                return this.renderMain();
            case 'contacts':
                return (<div>contacts</div>);
            case 'social':
                return (
                    <Container className="m-auto">
                        <Row>
                            <Col><span className="navigation-option hover" onClick={() => this.setState({profileView: 'main'})}>Back</span></Col>
                            <Col className="right"><span className="navigation-option right hover" onClick={() => this.save()}>Save</span></Col>
                        </Row>
                        <SocialEdit
                            socialData={this.state.mainCapsule.social}
                            isReorder={this.state.isReorder}
                            removeFromSocial={this.removeFromSocial}
                            addToSocial={this.addToSocial}
                            changeView={this.changeView}
                            onCapsuleChange={this.onCapsuleChange}
                            onSortEnd={this.onSortEnd}
                            toggleToolbar={this.toggleToolbar}
                            isSectionOpen={true}
                            toggleMenu={this.toggleMenu}
                        />
                    </Container>);
            case 'ecard':
                return (
                    <Container className="m-auto">
                        <Row>
                            <Col><span className="navigation-option hover" onClick={() => this.setState({profileView: 'main'})}>Back</span></Col>
                            <Col className="right"><span className="navigation-option right hover" onClick={() => this.save()}>Save</span></Col>
                        </Row>
                        <VCardEdit
                            vCardData={this.state.mainCapsule.vCard}
                            changeView={this.changeView}
                            socialData={this.state.mainCapsule.social}
                            onCapsuleChange={this.onCapsuleChange}
                            addToSocial={this.addToSocial}
                            user={this.props.user}
                            metadata={isObjectExists(this.state, 'mainCapsule.social.image.thumbnail.metadata') ? this.state.mainCapsule.social.image.thumbnail.metadata : null}
                            uploadPicture={this.uploadPicture}
                            isSectionOpen={true}
                            toggleMenu={this.toggleMenu}
                            thumbnail={this.getSrcUrl('thumbnail')}
                            //getStatusOfMedia={this.props.getStatusOfMedia}
                            setCheckingProgressOf={this.props.setCheckingProgressOf}
                            checkingProgressOf={this.props.checkingProgressOf}
                            addField={this.addField}
                            removeField={this.removeField}
                            removeUserImages={this.removeUserImages}
                            checkIfUploading={this.props.checkIfUploading}
                        />
                    </Container>  );
            case 'media':
                return <ProfileMedia
                    user={this.props.user}
                    renderHeader={this.props.renderHeader}
                    dispatch={this.props.dispatch}
                    logout={this.props.logout}
                    back={this.back}
                    capsules={this.props.capsules}
                />;
            case 'groups':
                return (
                    <Container className="m-auto">
                        <Row>
                            <Col><span className="navigation-option hover" onClick={() => this.setState({profileView: 'main'})}>Back</span></Col>
                            <Col className="right"><span className="navigation-option right hover" onClick={() => this.save()}>Save</span></Col>
                        </Row>
                        <ModalView isOpen={this.state.isGroupsOpen}
                            toggle={() => this.setState({isGroupsOpen: !this.state.isGroupsOpen})}
                            onContinue={this.onSaveGroups}
                            onRemoveGroup={this.onRemoveGroup}
                            onAddGroup={this.onAddGroup}
                            onCancel={this.resetGroups}
                            groups={this.props.capsules.groups}
                            modalType="groups"
                            onCreate={this.createGroup}
                            groupsOfCapsule={this.state.tempGroups}
                            exploreGroupsOfCapsule={this.state.tempExploreGroups}
                            exploreGroups={this.props.capsules.exploreGroups}
                            isExploreGroups={this.state.isExploreGroups}
                        />

                        <GroupEdit
                            user={this.props.user}
                            isSectionOpen={true}
                            toggleMenu={this.toggleMenu}
                            openGroups={this.openGroups}
                            groups={this.props.capsules.groups}
                            onRemoveGroup={this.onRemoveGroup}
                            groupsOfCapsule={this.state.mainCapsule.capsule.groups}
                            exploreGroupsOfCapsule={this.state.tempExploreGroups}
                            exploreGroups={this.props.capsules.exploreGroups}
                            isPublic={this.state.mainCapsule.settings.public}
                            onCapsuleChange={this.onCapsuleChange}

                        />
                    </Container>
                );
            case 'settings':
                return <ProfileSettings
                    user={this.props.user}
                    dispatch={this.props.dispatch}
                    logout={this.props.logout}
                    backToGroups={this.props.backToGroups}
                    capsules={this.props.capsules}
                    renderHeader={this.props.renderHeader}
                />;
            case 'verifiedUsers':
                return (
                    <React.Fragment>
                        <Container className="m-auto">
                            <Row>
                                <Col><span className="navigation-option hover" onClick={() => this.setState({profileView: 'main'})}>Back</span></Col>
                            </Row>
                            <VerifiedUsersAdmin
                                back={this.back}
                                user={this.props.user}
                                dispatch={this.props.dispatch}
                                backToGroups={this.props.backToGroups}
                                renderHeader={this.props.renderHeader}
                                gotVerifiedUsers={this.props.user.gotVerifiedUsers}
                                setGotVerifiedUsers={this.setGotVerifiedUsers}
                                getVerifiedUsers={this.getVerifiedUsers}
                                verifiedUsers={this.props.user.verifiedUsers}
                                setCanPublishValueDispatch={this.setCanPublishValueDispatch}
                                publishUserId={this.props.user.publishUserId}
                                canPublish={this.props.user.canPublish}
                                updatedCanPublishValue={this.props.user.updatedCanPublishValue}
                                setUpdatedUserCanPublish={this.setUpdatedUserCanPublish}
                            />
                        </Container>
                    </React.Fragment>
                );
            case 'bannedUsers':
                return (
                    <React.Fragment>
                        <Container className="m-auto">
                            <Row>
                                <Col><span className="navigation-option hover" onClick={() => this.setState({profileView: 'main'})}>Back</span></Col>
                                {this.state.deleteMeValue === false ?
                                    <Col className="right"><span className="navigation-option right account-left hover lighterblue" onClick={() => this.setState({deleteMeValue: true})}>{this.disableDeleteTextSwitch}</span></Col>
                                    :
                                    <Col className="right"><span className="navigation-option right account-left hover lighterblue" onClick={() => this.setState({deleteMeValue: false})}>{this.bannedTextSwitch}</span></Col>
                                }
                            </Row>
                            <BannedUsersAdmin
                                back={this.back}
                                user={this.props.user}
                                dispatch={this.props.dispatch}
                                backToGroups={this.props.backToGroups}
                                renderHeader={this.props.renderHeader}
                                gotVerifiedUsers={this.props.user.gotVerifiedUsers}
                                setGotVerifiedUsers={this.setGotVerifiedUsers}
                                getVerifiedUsers={this.getVerifiedUsers}
                                verifiedUsers={this.props.user.verifiedUsers}
                                setBannedValueDispatch={this.setBannedValueDispatch}
                                bannedUserId={this.props.user.bannedUserId}
                                banned={this.props.user.banned}
                                updatedBannedValue={this.props.user.updatedBannedValue}
                                setUpdatedUserBanned={this.setUpdatedUserBanned}
                                deleteMeValue={this.state.deleteMeValue}
                            />
                        </Container>
                    </React.Fragment>
                );
            case 'togglePublic':
                return (
                    <React.Fragment>
                        <Container className="m-auto">
                            <Row>
                                <Col><span className="navigation-option hover" onClick={() => this.setState({profileView: 'main'})}>Back</span></Col>
                            </Row>
                            <PublicExplore
                                back={this.back}
                                dispatch={this.props.dispatch}
                                backToGroups={this.props.backToGroups}
                                renderHeader={this.props.renderHeader}
                                exploreGroups={this.props.capsules.exploreGroups}
                                setExploreGroupValue={this.setExploreGroupValue}
                                capsules={this.props.capsules}
                            />
                        </Container>
                    </React.Fragment>
                );
        }
    }

    render() {
        return (
            <React.Fragment>
                <ConfirmationView onContinue={() => this.setState({profileView: 'main', isConfirmation: false})} isOpen={this.state.isConfirmation}
                    toggle={() => this.setState({isConfirmation: !this.state.isConfirmation})} confirmationType={this.state.confirmationType} user={this.props.user}/>
                <ModalView isOpen={this.state.sendgridModal}
                    toggle={() => this.setState({sendgridModal: false})}
                    onContinue={() => this.setState({sendgridModal: false})}
                    onCancel={() => this.setState({sendgridModal: false})}
                    user={this.props.user}
                    dispatch={this.props.dispatch}
                    groups={this.props.capsules.groups}
                    capsules={this.props.capsules}
                    modalType="sendGrid"
                />
                {this.getContent()}
            </React.Fragment>
        );
    }
}

ProfileHome.propTypes = {
    user:                  PropTypes.object,
    renderHeader:          PropTypes.func,
    dispatch:              PropTypes.func,
    logout:                PropTypes.func,
    backToGroups:          PropTypes.func,
    capsules:              PropTypes.object,
    listenStatus:          PropTypes.func,
    //getStatusOfMedia:      PropTypes.func,
    setCheckingProgressOf: PropTypes.func,
    checkingProgressOf:    PropTypes.string,
    checkIfUploading:      PropTypes.func,
    isFileImage:           PropTypes.func
};
