import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col, Input, FormGroup, Label, Alert, Form } from 'reactstrap';
import { Helmet } from 'react-helmet';
import './Login.css';
import queryString from 'query-string';
import backGround from '../../assets/images/Background@3x.png';
import backArrow from '../../assets/images/BackArrow@2x.png';
import logo from '../../assets/images/MyULogoWhite@2x.png';
import signUpButton from '../../assets/images/SignUpButton@3x.png';
import checkmarkIcon from '../../assets/images/CheckmarkIcon@2x.png';
import signUpButtonGreen from '../../assets/images/SignUpButtonGreen.png';
import emailIcon from '../../assets/images/ConfirmEmailIcon.png';
import simpleEmailIcon from '../../assets/images/EmailIcon@2x.png';
import { Footer } from '../footer/Footer';
import verifyButton from '../../assets/images/VerifyButtonGreen.png';
import lockImage from '../../assets/images/lock.png';
import { userActions } from '../redux/actions';
import { isObjectExists } from '../../utils/Helpers';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { validateEmail, validatePhoneNumber } from '../../utils/Validators';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            viewToRender:            'main',
            email:                   '',
            phone:                   '',
            name:                    '',
            password:                '',
            confirmPassword:         '',
            pin:                     '',
            passwordMatch:           true,
            terms:                   false,
            emailSent:               false,
            emailResent:             false,
            gotUser:                 false,
            emailVerified:           false,
            passwordReset:           false,
            pwResetCode:             '',
            newPassword:             '',
            passwordEmail:           '',
            confirmNewPassword:      '',
            verifyingEmail:          false,
            passwordEmpty:           false,
            isPhoneValid:            true,
            isEmailValid:            true,
            noAccountError:          false,
            notVerifiedCantResetPW:  false,
            accountTakenError:       false,
            invalidCredentialsError: false,
            emailNotVerifiedError:   false,
            userDisabledError:       false,
            isSubmitted:             false,
            pinResent:               false
        };

        this.phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

        this.myuTitle = 'Welcome to My U';
        this.myuPartOne = 'MY';
        this.myuPartTwo = 'UNIVERSE';
        this.myuPartThree = 'Its your world.';
        this.agreeTo = 'I agree to ';
        this.terms = 'terms';
        this.and = ' and ';
        this.privacy = 'privacy';

        this.loginText = 'Login';
        this.forgotYourPassword = 'Forgot your Password?';
        this.back = 'Back';
        this.backToLogin = 'Back to Login';
        this.firstAndLastName = 'First and Last name';
        this.phoneNumber = 'Your Phone Number';
        this.emailAddress = 'Your Email Address';
        this.yourPassword = 'Your Password';
        this.confirmYourPassword = 'Confirm Your Password';
        this.newPassword = 'New Password';
        this.confirmNewPassword = 'Confirm New Password';
        this.confirmEmailAddress = 'Confirm Email Address';
        this.checkYourMail = 'Check your email to complete login';
        this.resendEmail = 'Resend email.';
        this.emailResent = 'Email Resent.';
        this.resendPinText = 'Resend PIN.';
        this.pinResent = 'PIN Resent.';
        this.confirmPhoneNumber = 'Confirm Phone Number';
        this.pleaseEnterPin = 'Please enter the PIN we have sent you';
        this.verifyPinText = 'Verify Pin';
        this.phoneNumberConfirmed = 'Phone Number Confirmed';
        this.pinCorrect = 'The PIN you entered is correct, now you can login';
        this.passwordReset = 'Password Reset';
        this.enterNewPassword = 'Enter your new password';
        this.pleaseEnterNewPassword = 'Please enter a new password';
        this.setPassword = 'Set password';
        this.newPasswordSet = 'New password set.';
        this.checkYourResetLink = 'Check your email for a more recent Password Reset link. If you previously clicked the most recent email link, you will need to have another Forgot Password verification email sent to you';

        this.emailNotVerifiedError = 'This email address is not verified. Please check your email for the most recent Signup verification link';
        this.passwordNoMatch = 'Passwords do not match';
        this.emailTakenError = 'This email address is already taken.';
        this.phoneTakenError = 'This phone number is already taken.';
        this.pinInvalid = 'PIN is invalid';
        this.invalidVerifyCode = 'Invalid Verify Code';
        this.invalidPhoneLogin = 'Invalid Phone Number or Password';
        this.invalidEmailLogin = 'Invalid Address or Password';
        this.userDisabledError = 'Account disabled';

        this.switchEmailSignup = 'Switch to Sign up with an Email Address';
        this.switchPhoneSigunup = 'Switch to Sign up with a Phone Number';
        this.switchResetPasswordEmail = 'Switch to Reset Password for an Email Address Login account';
        this.switchResetPasswordPhone = 'Switch to Reset Password for a Phone Number Login account';
        this.switchLoginEmail = 'Switch to use an Email Address to Login';
        this.switchLoginPhone = 'Switch to use a Phone Number to Login';
        this.enterValidPhone = 'Please enter a valid Phone Number';
        this.enterValidEmail = 'Please enter a valid Email Address';
        this.changeEmail = 'Change Email Address';
        this.enterNewEmail = 'Enter a new email address.';

    }

    componentDidMount() {
        this.props.changeFooterTo('hide');

        const values = queryString.parse(this.props.location.search);
        if (typeof (values.code) != 'undefined') {
            let code = values.code;
            if (-1 !== window.location.href.indexOf('resetPassword')) {
                this.setState({pwResetCode: code, passwordReset: true});
            } else if (-1 !== window.location.href.indexOf('verifyEmail')) {
                this.setState({verifyingEmail: true});
            }
            this.props.dispatch(userActions.verifyEmail(values.code));
        }

    }

    componentWillUnmount() {
        this.props.changeFooterTo('main');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.user.token && !this.state.gotUser && localStorage.getItem('MUUserLogin')) {
            this.props.dispatch(userActions.getMe(this.props.user.token));
            this.setState({ gotUser: true });
        }

        if ((this.props.user.withToken === true && localStorage.getItem('MUUserLogin') && !this.state.passwordReset && !this.state.verifyingEmail )) {
            this.props.navigateTo('explore');
        }

        if (this.props.user.userSignedUp && !this.state.emailSent && !this.state.withPhone && !this.props.user.pinVerified) {
            this.setState({ emailSent: true, viewToRender: 'confirmEmail' });
        }

        if (this.props.user.userSignedUp && !this.state.emailSent && this.state.withPhone && this.state.viewToRender !== 'verifyPin') {
            this.setState({ viewToRender: 'verifyPin', emailSent: true });
        }

        if (this.props.user.emailVerified && !this.state.emailVerified) {
            this.setState({ emailVerified: true, viewToRender: 'emailValidated' });
        }

        if (!prevProps.user.pinVerified && this.props.user.pinVerified === true) {
            let viewToRender = 'pinVerified';

            if (this.props.user.passwordReset) {
                viewToRender = 'passwordReset';
            }

            this.setState({viewToRender: viewToRender, emailSent: false, withPhone: false, phone: '', pin: '', passwordReset: false});
        }

        if (this.state.passwordReset && this.state.viewToRender !== 'passwordReset') {
            this.setState({viewToRender: 'passwordReset'});
        }

        if (prevState.viewToRender === 'login' && this.state.viewToRender !== 'login' || prevState.viewToRender !== 'login' && this.state.viewToRender === 'login') {

            this.setState({ email: '', name: '', password: '', newPassword: '', phone: '', confirmPassword: '' });

        }

        if ((this.state.newPassword !== '' || this.state.confirmNewPassword !== '') && this.state.passwordEmpty) {
            this.setState({passwordEmpty: false});
        }

        if (!this.state.noAccountError &&
            (!isObjectExists(prevProps, 'user.error.status') || (isObjectExists(prevProps, 'user.error.status') && prevProps.user.error.status !== 404)) &&
             isObjectExists(this.props, 'user.error.status') && this.props.user.error.status === 404) {
            this.props.dispatch(userActions.clearError());
            this.setState({noAccountError: true});
        }

        if (!this.state.notVerifiedCantResetPW &&
            (!isObjectExists(prevProps, 'user.error.status') || (isObjectExists(prevProps, 'user.error.status') && prevProps.user.error.status !== 403)) &&
             isObjectExists(this.props, 'user.error.status') && this.props.user.error.status === 403) {
            this.props.dispatch(userActions.clearError());
            this.setState({notVerifiedCantResetPW: true});
        }

        if (!this.state.accountTakenError &&
           (!isObjectExists(prevProps, 'user.error.messages.error.message') || (isObjectExists(prevProps, 'user.error.messages.error.message') && prevProps.user.error.status !== 'the provided email address is already taken')) &&
            isObjectExists(this.props, 'user.error.messages.error.message') && this.props.user.error.messages.error.message === 'the provided email address is already taken') {
            this.props.dispatch(userActions.clearError());
            this.setState({accountTakenError: true});
        }

        if (!this.state.accountTakenError &&
           (!isObjectExists(prevProps, 'user.error.messages.error.message') || (isObjectExists(prevProps, 'user.error.messages.error.message') && prevProps.user.error.status !== 'the provided phone number is already taken')) &&
            isObjectExists(this.props, 'user.error.messages.error.message') && this.props.user.error.messages.error.message === 'the provided phone number is already taken') {
            this.props.dispatch(userActions.clearError());
            this.setState({accountTakenError: true});
        }

        if (!this.state.invalidCredentialsError &&
           (!isObjectExists(prevProps, 'user.error.message') || (isObjectExists(prevProps, 'user.error.message') && prevProps.user.error.status !== 'Invalid credentials')) &&
            isObjectExists(this.props, 'user.error.message') && this.props.user.error.message === 'Invalid credentials') {
            this.props.dispatch(userActions.clearError());
            this.setState({invalidCredentialsError: true});
        }

        if (!this.state.emailNotVerifiedError &&
           (!isObjectExists(prevProps, 'user.error.message') || (isObjectExists(prevProps, 'user.error.message') && prevProps.user.error.status !== 'User not verified, cannot log in')) &&
            isObjectExists(this.props, 'user.error.message') && this.props.user.error.message === 'User not verified, cannot log in') {
            this.props.dispatch(userActions.clearError());
            this.setState({emailNotVerifiedError: true});
        }

        if (!this.state.userDisabledError &&
            (!isObjectExists(prevProps, 'user.error.message') || (isObjectExists(prevProps, 'user.error.message') && prevProps.user.error.message !== 'User disabled')) &&
             isObjectExists(this.props, 'user.error.message') && this.props.user.error.message === 'User disabled') {
            this.props.dispatch(userActions.clearError());
            this.setState({userDisabledError: true});
        }

    }

    backTo = (value, fromPin) => {

        this.setState({
            viewToRender:            value,
            email:                   '',
            phone:                   '',
            name:                    '',
            password:                '',
            confirmPassword:         '',
            pin:                     '',
            passwordMatch:           true,
            terms:                   false,
            emailSent:               false,
            emailResent:             false,
            gotUser:                 false,
            emailVerified:           false,
            passwordReset:           false,
            pwResetCode:             '',
            newPassword:             '',
            passwordEmail:           '',
            confirmNewPassword:      '',
            verifyingEmail:          false,
            passwordEmpty:           false,
            isPhoneValid:            true,
            isEmailValid:            true,
            noAccountError:          false,
            notVerifiedCantResetPW:  false,
            accountTakenError:       false,
            invalidCredentialsError: false,
            emailNotVerifiedError:   false,
            isSubmitted:             false,
            pinResent:               false,
            withPhone:               fromPin || false
        });

        if (value === 'main') {
            this.props.navigateTo('login');
        }
    }

    onChange = (e, value, obj, fullValue) => {

        if (obj) {

            this.setState({
                phone:                   fullValue,
                accountTakenError:       false,
                invalidCredentialsError: false,
                emailNotVerifiedError:   false,
                isSubmitted:             false,
                noAccountError:          false,
                notVerifiedCantResetPW:  false,
                isPhoneValid:            validatePhoneNumber(e, value, obj, fullValue)
            });
        } else if (e.target) {
            const fieldValue = e.target && e.target.type === 'checkbox' ? e.target.checked : e.target.value;
            let pwMatch = this.state.passwordMatch;

            let newState = {
                [e.target.name]:         fieldValue,
                accountTakenError:       false,
                passwordMatch:           pwMatch,
                invalidCredentialsError: false,
                emailNotVerifiedError:   false,
                noAccountError:          false,
                notVerifiedCantResetPW:  false
            };

            if (e.target.name === 'password' || e.target.name === 'confirmPassword') {
                newState.passwordMatch = true;
            }

            if (e.target.name === 'email' || e.target.name === 'passwordEmail') {
                newState.isSubmitted = false;
                newState.isEmailValid = validateEmail(fieldValue);
            }

            this.setState({...this.state, ...newState});
        }

    }

    switchWithPhone = (value) => {
        this.setState({
            withPhone:               value,
            email:                   '',
            name:                    '',
            password:                '',
            phone:                   '',
            confirmPassword:         '',
            terms:                   false,
            isPhoneValid:            true,
            isEmailValid:            true,
            passwordMatch:           true,
            noAccountError:          false,
            notVerifiedCantResetPW:  false,
            accountTakenError:       false,
            invalidCredentialsError: false,
            emailNotVerifiedError:   false,
            userDisabledError:       false,
            isSubmitted:             false,
            pinResent:               false
        });
    }

    verifyPin = () => {
        if (this.state.pin !== '') {
            this.props.dispatch(userActions.verifyPin(this.props.user.signedUpId.uuid, this.state.pin));
        }
    }

    resendPin = () => {
        this.props.dispatch(userActions.signUp(this.props.user.token, this.state.email, this.state.password, this.state.name, 'Title', this.state.phone));
    }

    signUp = () => {
        const { password, confirmPassword } = this.state;

        if (this.state.isEmailValid && this.state.isPhoneValid) {
            if (password !== confirmPassword) {
                this.setState({
                    passwordMatch:           false,
                    noAccountError:          false,
                    accountTakenError:       false,
                    invalidCredentialsError: false,
                    emailNotVerifiedError:   false,
                    userDisabledError:       false,
                    notVerifiedCantResetPW:  false
                });
            } else if (this.state.terms) {
                if (this.state.withPhone) {
                    this.props.dispatch(userActions.signUp(this.props.user.token, this.state.email, this.state.password, this.state.name, 'Title', this.state.phone));
                } else {
                    this.props.dispatch(userActions.signUp(this.props.user.token, this.state.email, this.state.password, this.state.name, 'Title'));
                }
                this.setState({
                    noAccountError:          false,
                    accountTakenError:       false,
                    invalidCredentialsError: false,
                    emailNotVerifiedError:   false,
                    userDisabledError:       false,
                    notVerifiedCantResetPW:  false
                });
            }
        } else {
            this.setState({isSubmitted: true});
        }
    }

    login = () => {
        if (this.state.isEmailValid && this.state.isPhoneValid) {
            if (this.state.withPhone) {
                this.props.dispatch(userActions.login(this.state.phone, this.state.password));
            } else {
                this.props.dispatch(userActions.login(this.state.email, this.state.password));
            }
        } else {
            this.setState({isSubmitted: true});
        }
    }

    changePassword = () => {
        const { newPassword, confirmNewPassword } = this.state;
        if (newPassword !== '' && confirmNewPassword !== '') {
            if (newPassword !== confirmNewPassword) {
                this.setState({passwordMatch: false});
            } else {
                this.props.dispatch(userActions.setPassword(this.state.newPassword, this.props.user.verifiedResponse.token));
                this.setState({passwordMatch: true});
            }
        } else {
            this.setState({passwordEmpty: true});
        }
    }

    resetPassword = () => {
        if (this.state.isEmailValid && this.state.isPhoneValid) {
            if (this.state.withPhone) {
                this.props.dispatch(userActions.resetPassword(this.state.phone, true));
            } else {
                this.props.dispatch(userActions.resetPassword(this.state.passwordEmail));
            }
        } else {
            this.setState({isSubmitted: true});
        }

    }

    checkIfReturn = (e, func) => {
        var charCode = (typeof event.which == 'number') ? event.which : event.keyCode;
        if (charCode == 13) {
            func();

        }
    }

    renderMain = () => {
        return (
            <React.Fragment>

                <Row>
                    <Col><img onClick={() => this.props.navigateTo('explore')} src={backArrow} className="back" /></Col>
                </Row>
                <Row className="logo-center">
                    <img src={logo} className="logo" />
                </Row>
                <Row className="logo-container">
                    <Col>

                        {/*<img src={backgroundEffect} className="logo-effect" />*/}
                        <div className="top-text">{this.myuPartOne}</div>
                        <div className="top-text">{this.myuPartTwo}</div>
                        <div className="bottom-text">{this.myuPartThree}</div>
                    </Col>
                </Row>
                <Row className="text-center signup-spacing">
                    <Col >
                        <img src={signUpButton} className="sign-up" onClick={() => this.setState({ viewToRender: 'signUp' })} />
                    </Col>
                </Row>
                <Row className="text-center mt-3">
                    <Col>
                        <div><span className="login navigation-button mt-5" onClick={() => this.setState({ viewToRender: 'login' })}>{this.loginText}</span></div>
                    </Col>
                </Row>
            </React.Fragment>

        );
    }

    renderSignUpButton = () => {
        const { name, email, password, confirmPassword, terms, phone, withPhone } = this.state;
        let signUpButton = '';
        if (name.length == 0 || (!withPhone && email.length == 0) || password.length == 0 || confirmPassword.length == 0 || terms == false || (withPhone && phone.length == 0)) {
            signUpButton = <img src={signUpButtonGreen} className="sign-up-disabled" />;
        } else {
            signUpButton = <img src={signUpButtonGreen} className="sign-up" onClick={() => this.signUp()} />;
        }

        return signUpButton;
    }

    renderSignUp = () => {
        return (
            <React.Fragment>
                <Row className="logo-container sign-up-container">
                    <Col>
                        <img src={logo} className="logo signup" />
                        {/*<img src={backgroundEffect} className="logo-effect" />*/}

                        <div className="bottom-text">{this.myuTitle}</div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Alert className="text-center" color="danger" isOpen={(this.state.isPhoneValid === false || this.state.isEmailValid === false) && this.state.isSubmitted}>
                        <span>{this.state.withPhone ? this.enterValidPhone : this.enterValidEmail}</span>
                    </Alert>
                    <Alert className="text-center" color="danger" isOpen={!this.state.passwordMatch}>
                        <Row>
                            <span>{this.passwordNoMatch}</span>
                        </Row>
                    </Alert>
                    <Alert className="text-center" color="danger" isOpen={this.state.accountTakenError}>
                        <Row>
                            <span>{this.state.withPhone ? this.phoneTakenError : this.emailTakenError}</span>
                        </Row>
                    </Alert>
                    <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                        <input
                            value={this.state.name}
                            onChange={(e) => this.onChange(e)}
                            name="name"
                            id="name"
                            className="login-input"
                            autoComplete="new-password"
                            placeholder={this.firstAndLastName}
                            onKeyPress={(e) => this.checkIfReturn(e, () => this.signUp())}
                        />
                    </Col>

                    {this.state.withPhone ?
                        <React.Fragment>
                            <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                                <IntlTelInput
                                    preferredCountries={['us', 'hu']}
                                    onPhoneNumberChange={(e, value, obj, fullValue) => this.onChange(e, value, obj, fullValue)}
                                    inputClassName={!this.state.isPhoneValid && this.state.isSubmitted ? 'login-input phone-input invalid-input' : 'login-input phone-input'}
                                    containerClassName="intl-tel-input w-100"
                                    fieldId="phone"
                                    fieldName="phone"
                                    placeholder={this.phoneNumber}
                                    separateDialCode={true}
                                    onSelectFlag={(e, value, obj, fullValue) => validatePhoneNumber(e, fullValue, value, obj)}
                                />
                            </Col>

                        </React.Fragment>
                        :
                        <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                            <input
                                value={this.state.email}
                                onChange={(e) => this.onChange(e)}
                                name="email"
                                id="email"
                                autoComplete="new-password"
                                className={!this.state.isEmailValid && this.state.isSubmitted ? 'login-input invalid-input' : 'login-input'}
                                placeholder={this.emailAddress}
                                onKeyPress={(e) => this.checkIfReturn(e, () => this.signUp())}
                            />
                        </Col>
                    }

                    <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                        <input
                            value={this.state.password}
                            onChange={(e) => this.onChange(e)}
                            name="password"
                            id="password"
                            type="password"
                            autoComplete="new-password"
                            className="login-input"
                            placeholder={this.yourPassword}
                            onKeyPress={(e) => this.checkIfReturn(e, () => this.signUp())}
                        />
                    </Col>
                    <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                        <input
                            value={this.state.confirmPassword}
                            onChange={(e) => this.onChange(e)}
                            name="confirmPassword"
                            id="confirmPassword"
                            type="password"
                            autoComplete="new-password"
                            className="login-input"
                            placeholder={this.confirmYourPassword}
                            onKeyPress={(e) => this.checkIfReturn(e, () => this.signUp())}
                        />
                    </Col>
                </Row>

                <Row className="text-center">
                    <Col>
                        {this.renderSignUpButton()}
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        <FormGroup className="terms-container" check>
                            <Label check>
                                <Input type="checkbox" value={this.state.terms} name="terms" onChange={(e) => this.onChange(e)} checked={this.state.terms} />
                                <span className="">{this.agreeTo}<a className="navigation-button terms" target="_blank" href="terms.html">{this.terms}</a><span>{this.and}</span><a className="navigation-button terms" target="_blank" href="privacy.html">{this.privacy}</a>. </span>
                                <span>By clicking `Agree`, you confirm that you have read and agree to our Terms of Service and Privacy Policies. You also opt-in to receive a text message containing a PIN to verify your account. Message and data rates may apply.</span>
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                {this.state.withPhone ?
                    <Row className="text-center mt-2">
                        <Col>
                            <span className="navigation-button" onClick={() => this.switchWithPhone(false)}>{this.switchEmailSignup}</span>
                        </Col>
                    </Row>
                    :
                    <Row className="text-center mt-2">
                        <Col>
                            <span className="navigation-button" onClick={() => this.switchWithPhone(true)}>{this.switchPhoneSigunup}</span>
                        </Col>
                    </Row>
                }
                <Row className="text-center mt-5">
                    <Col >
                        <span className="navigation-button" onClick={() => this.backTo('main')}>{this.back}</span>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }

    renderConfirmEmail = () => {
        return (
            <React.Fragment>
                <Row className="logo-container email-container">
                    <Col>
                        <img src={emailIcon} className="logo email-confirm" />
                        {/*<img src={backgroundEffect} className="logo-effect" />*/}

                        <div className="confirm-text">{this.confirmEmailAddress}</div>
                        <div className="login">{this.checkYourMail}</div>
                    </Col>
                </Row>
                <Row className="sign-up-input-container">
                    <Col className="sign-up-input" sm="12" md="12" lg="12">
                        <input
                            disabled
                            value={this.state.email}
                            onChange={(e) => this.onChange(e)}
                            name="email"
                            onKeyPress={(e) => this.checkIfReturn(e, () => { this.setState({ emailResent: true }); this.signUp(); })}
                            id="email"
                            className={!this.state.isEmailValid && this.state.isSubmitted ? 'login-input invalid-input' : 'login-input'}
                        />
                    </Col>

                </Row>
                <Row className="text-center">
                    <Col>
                        {this.state.emailResent === false ?
                            <span className="login" onClick={() => { this.setState({ emailResent: true }); this.signUp(); }}>{this.resendEmail}</span>
                            :
                            <span className="login">{this.emailResent}</span>
                        }
                    </Col>
                </Row>

            </React.Fragment>
        );
    }

    renderVerifyPin = () => {
        return (
            <React.Fragment>
                <Row className="logo-container email-container">
                    <Col>
                        <img src={emailIcon} className="logo email-confirm" />
                        {/*<img src={backgroundEffect} className="logo-effect" />*/}

                        <div className="confirm-text">{this.confirmPhoneNumber}</div>
                        <div className="login">{this.pleaseEnterPin}</div>
                    </Col>
                </Row>
                <Row className="sign-up-input-container justify-content-center">
                    <Col className="sign-up-input" xs="10"sm="10" md="10" lg="10">
                        <input
                            value={this.state.pin}
                            onChange={(e) => this.onChange(e)}
                            name="pin"
                            onKeyPress={(e) => this.checkIfReturn(e, () => { this.verifyPin(); })}
                            id="pin"
                            className="login-input"
                        />
                    </Col>

                </Row>
                <Row className="text-center">
                    <Col>
                        {this.state.pinResent === false ?
                            <span className="login navigation-button" onClick={() => { this.setState({ pinResent: true }); this.resendPin(); }}>{this.resendPinText}</span>
                            :
                            <span>{this.pinResent}</span>
                        }
                    </Col>
                </Row>
                <Alert className="text-center" color="danger" isOpen={this.props.user.verifyingPin === 'error'}>
                    <span>{this.pinInvalid}</span>
                </Alert>

                <Row className="text-center">
                    <Col>
                        <span className="login navigation-button" onClick={() =>  this.verifyPin()}>{this.verifyPinText}</span>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        <span className="navigation-button hover" onClick={() => this.backTo('main')}>{this.back}</span>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }

    renderPinVerified = () => {
        return (
            <React.Fragment>
                <Row className="logo-container email-container">
                    <Col>
                        <img src={emailIcon} className="logo email-confirm" />
                        {/*<img src={backgroundEffect} className="logo-effect" />*/}

                        <div className="confirm-text">{this.phoneNumberConfirmed}</div>
                        <div className="login">{this.pinCorrect}</div>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        <span className="hover navigation-button" onClick={() => this.backTo('login', true)}>{this.backToLogin}</span>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }

    renderPasswordReset = () => {
        if (!this.props.user.verifyError || (this.props.user.verifyError && this.props.user.verifyError.status !== 401)) {
            return (
                <React.Fragment>
                    <Row className="logo-container email-container">
                        <Col>
                            <img src={lockImage} className="logo email-confirm" />
                            {/*<img src={backgroundEffect} className="logo-effect" />*/}

                            <div className="confirm-text">{this.passwordReset}</div>
                            <div className="">{this.enterNewPassword}</div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                            <input
                                value={this.state.newPassword}
                                onChange={(e) => this.onChange(e)}
                                name="newPassword"
                                id="newPassword"
                                type="password"
                                className="login-input"
                                placeholder={this.newPassword}
                                autoComplete="new-password"
                                onKeyPress={(e) => this.checkIfReturn(e, () => this.changePassword())}
                            />
                        </Col>
                        <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                            <input
                                value={this.state.confirmNewPassword}
                                onChange={(e) => this.onChange(e)}
                                name="confirmNewPassword"
                                id="confirmNewPassword"
                                type="password"
                                className="login-input"
                                autoComplete="new-password"
                                placeholder={this.confirmNewPassword}
                                onKeyPress={(e) => this.checkIfReturn(e, () => this.changePassword())}
                            />
                        </Col>
                        <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                            <Alert className="text-center" color="danger" isOpen={!this.state.passwordMatch}>
                                <span>{this.passwordNoMatch}</span>
                            </Alert>
                        </Col>
                        <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                            <Alert className="text-center" color="danger" isOpen={this.state.passwordEmpty}>
                                <span>{this.pleaseEnterNewPassword}</span>
                            </Alert>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            {!this.props.user.passwordSet ?
                                <span className="login navigation-button" onClick={() => this.changePassword() }>{this.setPassword}</span>
                                :
                                <React.Fragment>
                                    <div><span className="">{this.newPasswordSet}</span></div>
                                    <div><span className="hover navigation-button" onClick={() => this.backTo('main')}>{this.backToLogin}</span></div>
                                </React.Fragment>
                            }
                        </Col>
                    </Row>

                </React.Fragment>
            );
        } else {
            return (
                <Row className="logo-container email-container">
                    <Col>
                        <div className="confirm-text">{this.invalidVerifyCode}</div>
                        <div>{this.checkYourResetLink}</div>

                        <Row className="email-container">
                            <Col className="sign-up-input" sm="12" md="12" lg="12">
                                <input
                                    value={this.state.passwordEmail}
                                    onChange={(e) => this.onChange(e)}
                                    name="passwordEmail"
                                    id="passwordEmail"
                                    className={!this.state.isEmailValid && this.state.isSubmitted ? 'login-input invalid-input' : 'login-input'}
                                    onKeyPress={(e) => this.checkIfReturn(e, () => this.resetPassword())}
                                />
                            </Col>

                        </Row>
                        <Row className="text-center">
                            <Col>
                                {this.state.emailResent === false ?
                                    <span className="login navigation-button" onClick={() => { this.setState({ emailResent: true }); this.resetPassword(); }}>{this.resendEmail}</span>
                                    :
                                    <span className="login">{this.emailResent}</span>
                                }
                            </Col>
                        </Row>
                        <div className="pt-2"><span className="hover navigation-button" onClick={() => this.backTo('main')}>{this.backToLogin}</span></div>
                    </Col>
                </Row>);
        }

    }

    renderChangeEmail = () => {
        return (
            <React.Fragment>
                <Row className="logo-container email-container">
                    <Col>
                        <img src={logo} className="logo change-mail" />
                        {/*<img src={backgroundEffect} className="logo-effect" />*/}

                        <div className="confirm-text">{this.changeEmail}</div>
                        <div className="login">{this.enterNewEmail}</div>
                    </Col>
                </Row>
                <Row className="email-container justify-content-center">
                    <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                        <input
                            value={this.state.email}
                            onChange={(e) => this.onChange(e)}
                            name="email"
                            id="email"
                            placeholder={this.emailAddress}
                            onKeyPress={(e) => this.checkIfReturn(e, () => {this.signUp(); this.setState({ viewToRender: 'confirmEmail' });})}
                            className={!this.state.isEmailValid && this.state.isSubmitted ? 'login-input invalid-input' : 'login-input'}
                        />
                    </Col>

                </Row>

                <Row className="text-center">
                    <Col>
                        <img src={verifyButton} className="sign-up" onClick={() => { this.signUp(); this.setState({ viewToRender: 'confirmEmail' }); }} />
                    </Col>
                </Row>

            </React.Fragment>
        );
    }

    renderPasswordResetMain = () => {
        if (!this.props.user.passwordReset) {
            return <React.Fragment>
                <Row className="logo-container email-container">
                    <Col>
                        <img src={lockImage} className="logo email-confirm" />
                        {/*<img src={backgroundEffect} className="logo-effect" />*/}

                        <div className="confirm-text">Reset password</div>
                        <div className="pt-5 pb-5">{this.state.withPhone ? 'Enter your phone number.' : 'Enter your email address.'}</div>
                        <Alert className="text-center" color="danger" isOpen={this.state.noAccountError}>
                            <span>{this.state.withPhone ? 'There is no account registered with this Phone Number' : 'There is no account registered with this Email Address'}</span>
                        </Alert>
                        <Alert className="text-center" color="danger" isOpen={this.state.notVerifiedCantResetPW}>
                            <span>{this.state.withPhone ? 'Account has not been verified. Please re-sign up and verify the account with the verification code. The same phone number can be used.' : 'Account has not been verified. Please check your email for the verification email or re-sign up. The same email address can be used to re-sign up.'}</span>
                        </Alert>
                        <Alert className="text-center" color="danger" isOpen={(this.state.isPhoneValid === false || this.state.isEmailValid === false) && this.state.isSubmitted}>
                            <span>{this.state.withPhone ? this.enterValidPhone : this.enterValidEmail}</span>
                        </Alert>
                    </Col>
                </Row>

                <Row className="email-container justify-content-center">
                    {this.state.withPhone ?
                        <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                            <IntlTelInput
                                preferredCountries={['us', 'hu']}
                                onPhoneNumberChange={(e, value, obj, fullValue) => this.onChange(e, value, obj, fullValue)}
                                inputClassName={!this.state.isPhoneValid && this.state.isSubmitted ? 'login-input phone-input invalid-input' : 'login-input phone-input'}
                                containerClassName="intl-tel-input w-100"
                                fieldId="phone"
                                fieldName="phone"
                                placeholder={this.phoneNumber}
                                separateDialCode={true}
                                onSelectFlag={(e, value, obj, fullValue) => validatePhoneNumber(e, fullValue, value, obj)}
                            />
                        </Col>

                        :

                        <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                            <input
                                value={this.state.passwordEmail}
                                onChange={(e) => this.onChange(e)}
                                name="passwordEmail"
                                id="passwordEmail"
                                className={!this.state.isEmailValid && this.state.isSubmitted ? 'login-input invalid-input' : 'login-input'}
                                placeholder={this.emailAddress}
                                onKeyPress={(e) => this.checkIfReturn(e, () => this.resetPassword())}
                            />
                        </Col>
                    }
                </Row>

                <Row >
                    <Col className="text-center">
                        {this.props.user.passwordReset ?
                            <span className="">Check your inbox to continue</span>
                            :
                            <span className="hover navigation-button" onClick={() => this.resetPassword()}>Reset your password</span>
                        }
                    </Col>
                </Row>
                {this.state.withPhone ?
                    <Row className="text-center mt-3">
                        <Col>
                            <span className="navigation-button" onClick={() => this.switchWithPhone(false)}>{this.switchResetPasswordEmail}</span>
                        </Col>
                    </Row>
                    :
                    <Row className="text-center mt-3">
                        <Col>
                            <span className="navigation-button" onClick={() => this.switchWithPhone(true)}>{this.switchResetPasswordPhone}</span>
                        </Col>
                    </Row>
                }
                <Row className="text-center mt-5">
                    <Col>
                        <span className="navigation-button" onClick={() => this.backTo('login')}>{this.back}</span>
                    </Col>
                </Row>

            </React.Fragment>;
        } else if (this.props.user.passwordReset && this.state.withPhone) {
            return this.renderVerifyPin();
        } else {

            return (

                <React.Fragment>
                    <Row className="logo-container email-container">
                        <Col>
                            <img src={emailIcon} className="logo email-confirm" />
                            {/*<img src={backgroundEffect} className="logo-effect" />*/}

                            <div className="confirm-text">Confirm Password Reset</div>
                            <div className="login">Check your email to complete Password Reset</div>
                        </Col>
                    </Row>
                    <Row className="sign-up-input-container justify-content-center">
                        <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                            <input
                                disabled
                                value={this.state.passwordEmail}
                                onChange={(e) => this.onChange(e)}
                                name="email"
                                onKeyPress={(e) => this.checkIfReturn(e, () => { this.setState({ emailResent: true }); this.resetPassword(); })}
                                id="email"
                                className={!this.state.isEmailValid && this.state.isSubmitted ? 'login-input invalid-input' : 'login-input'}
                            />
                        </Col>

                    </Row>
                    <Row className="text-center">
                        <Col>
                            {this.state.emailResent === false ?
                                <span className="login navigation-button" onClick={() => { this.setState({ emailResent: true }); this.resetPassword(); }}>{this.resendEmail}</span>
                                :
                                <span className="login">{this.emailResent}</span>
                            }
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }
    }

    renderLogin = () => {
        return (
            <React.Fragment>
                <Row className="logo-container sign-up-container">
                    <Col>
                        <img src={logo} className="logo signup" />
                        {/*<img src={backgroundEffect} className="logo-effect" />*/}

                        <div className="bottom-text">{this.myuTitle}</div>

                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                        <Alert className="text-center" color="danger" isOpen={this.state.invalidCredentialsError}>
                            <span>{this.state.withPhone ? this.invalidPhoneLogin : this.invalidEmailLogin}</span>
                        </Alert>
                        <Alert className="text-center" color="danger" isOpen={(this.state.isPhoneValid === false || this.state.isEmailValid === false) && this.state.isSubmitted}>
                            <span>{this.state.withPhone ? this.enterValidPhone : this.enterValidEmail}</span>
                        </Alert>
                        <Alert className="text-center" color="danger" isOpen={this.state.emailNotVerifiedError}>
                            <span>{this.emailNotVerifiedError}</span>
                        </Alert>
                        <Alert className="text-center" color="danger" isOpen={this.state.userDisabledError}>
                            <span>{this.userDisabledError}</span>
                        </Alert>
                    </Col>
                </Row>

                <Form>
                    <Row className="justify-content-center">

                        {this.state.withPhone ?
                            <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                                <IntlTelInput
                                    preferredCountries={['us', 'hu']}
                                    onPhoneNumberChange={(e, value, obj, fullValue) => this.onChange(e, value, obj, fullValue)}
                                    inputClassName={!this.state.isPhoneValid && this.state.isSubmitted ? 'login-input phone-input invalid-input' : 'login-input phone-input'}
                                    containerClassName="intl-tel-input w-100"
                                    fieldId="phone"
                                    fieldName="phone"
                                    placeholder={this.phoneNumber}
                                    separateDialCode={true}
                                    onSelectFlag={(e, value, obj, fullValue) => validatePhoneNumber(e, fullValue, value, obj)}
                                />
                            </Col>
                            :
                            <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                                <input
                                    value={this.state.email}
                                    onChange={(e) => this.onChange(e)}
                                    name={'email'}
                                    onKeyPress={(e) => this.checkIfReturn(e, () => this.login())}
                                    id="email"
                                    className={!this.state.isEmailValid && this.state.isSubmitted ? 'login-input invalid-input' : 'login-input'}
                                    placeholder={this.emailAddress}

                                />

                            </Col>
                        }

                    </Row>
                    <Row className="justify-content-center">
                        <Col className="sign-up-input" xs="10" sm="10" md="10" lg="10">
                            <input
                                value={this.state.password}
                                onChange={(e) => this.onChange(e)}
                                name="password"
                                onKeyPress={(e) => this.checkIfReturn(e, () => this.login())}
                                id="password"
                                type="password"
                                className="login-input"
                                placeholder={this.yourPassword}
                            />
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col>
                            <button type="button" className="btn btn-sm btn-light login-button" onClick={() => this.login()}>{this.loginText}</button>

                        </Col>
                    </Row>
                </Form>
                <React.Fragment>
                    <Row className="text-center mt-3">
                        <Col>
                            <span className="hover navigation-button" onClick={() => this.setState({viewToRender: 'passwordResetMain', isEmailValid: true, isPhoneValid: true})}>{this.forgotYourPassword}</span>
                        </Col>
                    </Row>
                    {this.state.withPhone ?
                        <Row className="text-center mt-3">
                            <Col>
                                <span className="hover navigation-button" onClick={() => this.switchWithPhone(false)}>{this.switchLoginEmail}</span>
                            </Col>
                        </Row>
                        :
                        <Row className="text-center mt-3">
                            <Col>
                                <span className="hover navigation-button" onClick={() => this.switchWithPhone(true)}>{this.switchLoginPhone}</span>
                            </Col>
                        </Row>
                    }
                </React.Fragment>

                <Row className="text-center mt-5">
                    <Col>
                        <span className="text-center hover navigation-button" onClick={() => this.backTo('main')}>{this.back}</span>
                    </Col>
                </Row>

            </React.Fragment>
        );
    }

    renderView = () => {
        switch (this.state.viewToRender) {
            case 'main':
                return this.renderMain();
            case 'signUp':
                return this.renderSignUp();
            case 'confirmEmail':
                return this.renderConfirmEmail();
            case 'changeEmail':
                return this.renderChangeEmail();
            case 'login':
                return this.renderLogin();
            case 'emailValidated':
                return this.renderEmailValidated();
            case 'passwordResetMain':
                return this.renderPasswordResetMain();
            case 'passwordReset':
                return this.renderPasswordReset();
            case 'verifyPin':
                return this.renderVerifyPin();
            case 'pinVerified':
                return this.renderPinVerified();
        }
    }

    renderEmailValidated = () => {
        if (!this.props.user.verifyError || (this.props.user.verifyError && this.props.user.verifyError.status !== 401)) {
            return (
                <React.Fragment>
                    <Row className="logo-container success">
                        <Col></Col>
                        <Col>
                            <img src={checkmarkIcon} className="logo email-confirm" />
                            {/*<img src={backgroundEffect} className="logo-effect" />*/}

                        </Col>
                        <Col></Col>
                    </Row>
                    <Row className="text-center success-container">
                        <Col sm="12" md="12" lg="12">
                            <img src={simpleEmailIcon} className="logo email-confirm" />
                        </Col>
                        <Col sm="12" md="12" lg="12">
                            <div className="confirm-text">Success!</div>
                        </Col>
                        <Col sm="12" md="12" lg="12">
                            <div className="">Your email address is verified.</div>
                        </Col>
                        <Col sm="12" md="12" lg="12">
                            <div className="">If you signed up/reset password in the app, reopen the app.</div>
                        </Col>
                        <Col sm="12" md="12" lg="12">
                            <div className="">If you signed up/reset password on the web, click</div>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col className="next success">
                            <span className="navigation-button" onClick={() => this.setState({viewToRender: 'login', verifyingEmail: false })}>Web Login</span>
                        </Col>
                    </Row>

                </React.Fragment>);
        } else {
            return (
                <Row className="logo-container email-container">
                    <Col>
                        {}
                        <div className="confirm-text">Invalid Verify Code</div>
                        <div>Check your email for a more recent Sign-up link</div>
                        <div className="pt-2"><span className="hover navigation-button" onClick={() => this.backTo('main')}>{this.backToLogin}</span></div>
                    </Col>
                </Row>);
        }
    }

    render() {
        return (
            <Container>
                <Helmet>
                    <style>
                        {
                            'body { ' +
                            'background-image: url(' + backGround + ');' +
                            'background-repeat: no-repeat;' +
                            'background-size: cover;}' +
                            'body .background { display: none; }'
                        }
                    </style>

                </Helmet>
                <Container className="container-width">
                    {this.renderView()}
                </Container>
                {this.state.viewToRender === 'confirmEmail' &&
                    <Footer footerToShow={'changeEmail'} changeView={() => this.setState({ viewToRender: 'changeEmail'})} />
                }
            </Container>

        );

    }
}

Login.propTypes = {
    dispatch:       PropTypes.func,
    capsule:        PropTypes.string,
    capsules:       PropTypes.object,
    changeFooterTo: PropTypes.func,
    user:           PropTypes.object,
    match:          PropTypes.object,
    history:        PropTypes.object,
    navigateTo:     PropTypes.func,
    location:       PropTypes.object
};

function mapStateToProps(state) {
    const { user } = state;
    return {
        user
    };
}

const connectedLoginPage = connect(mapStateToProps)(Login);
export { connectedLoginPage as Login };
