import React from 'react';
import PropTypes from 'prop-types';
import { Modal} from 'reactstrap';
import './VideoPlayer.css';

export class VideoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        
        };
    }

    render() {
        return <Modal className={this.props.status && this.props.status !== 'ready' ? 'video-processing' : 'video-playback'} isOpen={this.props.isOpen} toggle={this.props.toggle} centered backdrop>
            {this.props.video}
        </Modal>;
    }

}

VideoModal.propTypes = {
    isOpen: PropTypes.bool,
    video:  PropTypes.element,
    toggle: PropTypes.func,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};