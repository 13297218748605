export const configService = {
    getChatbotConfig,
    getWrapConfig
};

async function getChatbotConfig() {
    const requestOptions = {
        method: 'GET',
    };

    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/capsules/chat', requestOptions);
    var config = await handleResponse(response);

    return config;
}

async function getWrapConfig(wrapName) {
    const requestOptions = {
        method: 'GET',
    };

    //const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/wrap/pages', requestOptions);
    const response = await fetch(process.env.REACT_APP_API_BASE_URI + '/wrap/pages?filter={"where":{"wrapName":"'+wrapName+'"}}', requestOptions);

    var config = await handleResponse(response);

    return config;
}

function handleResponse(response) {
    if (!response.ok) {
        return response.json().then(text => {
            var data;
            try {
                data = JSON.parse(text);
            } catch (e) {
                data = text;
            }
            const error = {
                status:     response.status,
                statusText: response.statusText,
                messages:   data
            };

            return Promise.reject(error);
        });
    } else {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.json().then(data => {
                return data;
            });
        } else {
            return response.text().then(data => {
                return data;
            });
        }

    }
}
