import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { capsuleActions } from '../redux/actions';
import {ModalView} from '../profile/ModalView';
import redButtonIcon from '../../assets/images/Red-button-icon.png';

export class RevokeGroupShares extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            getSharedUsers:       false,
            gotSharedUsers:       false,
            revokedUserShare:     false,
            isRevokeShareOpen:    false,
            groupSharedUsers:     [],
            groupAnonSharedUsers: []

        };
    }

    componentDidMount() {
        this.setRevokedUserShare(false);
        this.setGotGroupSharedUsers(false);
    }

    componentDidUpdate() {

        if (this.state.getSharedUsers === false && this.props.capsules.gotGroupSharedUsers === false) {
            this.getSharedUsers(this.props.groupId);
            this.setState({getSharedUsers: true});
        }

        if (this.props.capsules.gotGroupSharedUsers === true && this.state.gotSharedUsers === false) {
            this.listSharedUsers();
            this.setState({gotSharedUsers: true});
        }

        if (this.props.capsules.revokedUserGroupShare === true && this.state.revokedUserShare === false) {
            this.setRevoked(this.props.capsules.revokedGroupId, this.props.capsules.revokedUserGroup2Id);
            this.setRevokedUserShare(false);
            this.setState({revokedUserShare: true});
        }

        if (this.props.capsules.revokedUserGroupShare === false && this.state.revokedUserShare === true) {
            this.setState({revokedUserShare: false});
        }
    }

    listSharedUsers = () => {

        let result = [];
        let resultAnon = [];
        let count = 0;
        let countRecSharedUsers = 0;
        let sharedUsers = this.props.capsules.groupSharedUsers;

        for (let i in sharedUsers) {
            if (sharedUsers[i].email) {
                result.push({
                    user:    sharedUsers[i].email,
                    id:      sharedUsers[i].id,
                    revoked: false
                });
                countRecSharedUsers++;
            } else if (sharedUsers[i].userPhoneForVerification) {
                result.push({
                    user:    sharedUsers[i].userPhoneForVerification,
                    id:      sharedUsers[i].id,
                    revoked: false
                });
                countRecSharedUsers++;
            } else {
                resultAnon.push({
                    anonymousUser: 'Anonymous',
                    anonymousId:   sharedUsers[i].id,
                    revoked:       false
                });
            }
            count++;
        }
        this.setState({groupSharedUsers: result, groupAnonSharedUsers: resultAnon, countRecSharedUsers: countRecSharedUsers, countSharedUsers: count});
    }

    getSharedUsers = (groupId) => {
        this.props.dispatch(capsuleActions.getGroupSharedUsers(groupId));
    }

    getSharedGroupUsers = () => {

        let sharedUsers = this.getSharedUsersList();
        let noUsers = sharedUsers === 'NoUsers' ? true : false;
        if (!noUsers) {
            return (
                <Container className="group-list">
                    <div className="group-edit-container">
                        <Row className="">
                            <Col className="group-list-divider letter-line group-text-spacing black group-spacing"><span>{this.props.groupName}</span> </Col>
                        </Row>
                        <Row className="group-element">
                            <Col className="group-text-spacing mt-1"><span>Click grey circle to remove share</span> </Col>
                        </Row>
                        <Row className="left-value-verified">
                            <Col>
                                {this.renderSharedUsers(sharedUsers[0])}
                                {this.renderSharedUsers(sharedUsers[1])}
                            </Col>
                        </Row>
                    </div>
                </Container>
            );
        } else if (this.state.gotSharedUsers === true) {
            return (
                <Container className="group-list">
                    <Row className="group-element">
                        <Col className="group-text-center group-spacing"><span>No shared users</span> </Col>
                    </Row>
                </Container>
            );
        }
    }

    renderSharedUsers = (sharedUsers) => {
        return (
            <React.Fragment>

                {Object.keys(sharedUsers).map((i) =>
                    <Row key={i} className="group-element">
                        <Col className="left-value-verified1 lightgrey">
                            {sharedUsers[i].user ? sharedUsers[i].user : sharedUsers[i].anonymousId}
                        </Col>
                        <Col className={!sharedUsers[i].revoked ? 'right-value-verified1' : 'right-value-verified1'}>
                            {
                                !sharedUsers[i].revoked ?
                                    <span onClick={() => this.setRevokeShare(sharedUsers[i].id ? sharedUsers[i].id : sharedUsers[i].anonymousId)} className="circle-filled group-circle divider-circle1 hover">
                                    </span>
                                    :
                                    <span>
                                        <img src={redButtonIcon} className="divider-circle2" />
                                    </span>
                            }
                        </Col>
                    </Row>
                )}
            </React.Fragment>
        );
    }

    setRevokeShare = (id) => {
        this.setState({isRevokeShareOpen: !this.state.isRevokeShareOpen, revokeUserId: id, revokeGroupId: this.props.groupId});
    }

    clearRevokeShare = () => {
        this.setState({isRevokeShareOpen: !this.state.isRevokeShareOpen, revokeUserId: '', revokeuserIdId: ''});
    }

    setRevokedUserShare = (value) => {
        this.props.dispatch(capsuleActions.setRevokedUserGroupShare(value));
    }

    setGotGroupSharedUsers = (value) => {
        this.props.dispatch(capsuleActions.setGotGroupSharedUsers(value));
    }

    setRevoked = (revokedCapsuleId, revokedUserGroup2Id) => {
        let sharedUsers = this.state.groupSharedUsers;
        let anonSharedUsers = this.state.groupAnonSharedUsers;

        for (let i in sharedUsers) {
            if (sharedUsers[i].id === revokedUserGroup2Id) {
                sharedUsers[i].revoked = true;
                break;
            }
        }
        for (let i in anonSharedUsers) {
            if (anonSharedUsers[i].anonymousId === revokedUserGroup2Id) {
                anonSharedUsers[i].revoked = true;
                break;
            }
        }

        this.setState({groupSharedUsers: sharedUsers, groupAnonSharedUsers: anonSharedUsers});
    }

    getSharedUsersList = () => {

        const sharedUsersList = this.state.groupSharedUsers;
        const sharedAnonList = this.state.groupAnonSharedUsers;
        if (0 < sharedUsersList.length || 0 < sharedAnonList.length) {
            return [sharedUsersList, sharedAnonList];
        } else {
            return 'NoUsers';
        }
    }

    revokeShare = (groupId, user2Id) => {
        this.props.dispatch(capsuleActions.revokeUserGroupShare(groupId, user2Id));
    }

    render() {

        return (
            <Container className="container-width">
                <ModalView isOpen={this.state.isRevokeShareOpen}
                    toggle={() => this.setState({isRevokeShareOpen: !this.state.isRevokeShareOpen})}
                    onContinue={() => this.revokeShare(this.state.revokeGroupId, this.state.revokeUserId)}
                    onCancel={() => this.clearRevokeShare()}
                    title="Please confirm share removal"
                    modalType="groupShareRevoke"
                />
                {this.getSharedGroupUsers()}
            </Container>
        );
    }

}

RevokeGroupShares.propTypes = {
    user:         PropTypes.object,
    renderHeader: PropTypes.func,
    dispatch:     PropTypes.func,
    backToGroups: PropTypes.func,
    back:         PropTypes.func,
    capsules:     PropTypes.object,
    groupId:      PropTypes.string,
    groupName:    PropTypes.string
};

export default RevokeGroupShares;
