import React from 'react';
import PropTypes from 'prop-types';
import { Container, Card, CardBody, CardTitle, CardSubtitle,  Row, Col, Alert } from 'reactstrap';
import emailIcon from '../../assets/images/EmailIcon@2x.png';
import passwordIcon from '../../assets/images/lock.png';
import phoneNumberIcon from '../../assets/images/PhoneNumberIcon@3x.png';
import { capsuleActions, userActions } from '../redux/actions';
import {ModalView} from '../profile/ModalView';
import { isObjectExists } from '../../utils/Helpers';
import { validateEmail, validatePhoneNumber } from '../../utils/Validators';
import IntlTelInput from 'react-intl-tel-input';
import CSVReader from 'react-csv-reader';

export class ProfileSettings extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            newPassword:                '',
            newPasswordConfirm:         '',
            newEmail:                   '',
            newPhone:                   '',
            passwordMatch:              true,
            passwordChange:             false,
            phoneChange:                false,
            emailChange:                false,
            emailSent:                  false,
            sameEmailError:             false,
            emailTakenError:            false,
            emailValid:                 true,
            phoneValid:                 true,
            isSubmitted:                false,
            pin:                        '',
            pinVerified:                false,
            pinVerifiedError:           false,
            deleteAccountReques:        false,
            suspendAccountRequest:      false,
            cancelDeleteAccountRequest: false,
            confirmSuspendAccount:      false,
            confirmDeleteAccount:       false
        };

        this.title = 'Profile Information';
        this.pwChangeSuccess = 'Password Successfully Changed';
        this.subTitle = 'Profile, passwords, and more.';
        this.accountEmail = 'Account Email';
        this.changeEmailText = 'Change Email';
        this.passwordNoMatch = 'Passwords don\'t match';
        this.back = 'Back';
        this.phoneNumberText = 'Phone Number';
        this.addPhoneNumberText = 'Add Phone Number';
        this.changePhoneNumberText = 'Change Phone Number';
        this.passwordText = 'Account password';
        this.changePasswordText = 'Change Password';
        this.helpText = 'Help';
        this.logoutText = 'Logout';
        this.submit = 'Submit';
        this.cancel = 'Cancel';
        this.termAndConditionsText = 'Terms & Condition';
        this.privacyPolicyText = 'Privacy Policy';
        this.sameEmailErrorText = 'Please enter a different Email Address.';
        this.samePhoneErrorText = 'Please enter a different Phone Number.';
        this.enterValidEmail = 'Please enter a valid Email Address';
        this.emailTakenErrorText = 'This email address is already taken.';
        this.phoneTakenError = 'This phone number is already taken.';
        this.enterValidPhone = 'Please enter a valid Phone Number';
        this.addEmailText = 'Add Email Address';
        this.deleteMyAccountText = 'Delete My Account';
        this.cancelDeleteMyAccountText = 'Cancel Delete My Account';
        this.suspendMyAccountText = 'Disable My Account';
    }

    componentDidMount() {
        this.props.dispatch(userActions.setAccountDeleteStatus(false));
        this.props.dispatch(userActions.setAccountCancelDeleteStatus(false));
        this.props.dispatch(userActions.setAccountSuspendStatus(false));
    }

    componentDidUpdate(prevProps) {

        if (prevProps.user.deletedAccount === false && this.props.user.deletedAccount === true) {

            this.props.logout();
            this.props.backToGroups();
            this.setState({deleteAccountRequest: false});
        }

        if (prevProps.user.suspendedAccount === false && this.props.user.suspendedAccount === true) {
            this.props.logout();
            this.props.backToGroups();
            this.setState({suspendAccountRequest: false});
        }

        if (!prevProps.user.updateEmailSent && this.props.user.updateEmailSent) {
            this.setState({emailSent: true, emailChange: false});
        }

        if (!prevProps.user.updatePhoneSent && this.props.user.updatePhoneSent) {
            this.setState({phoneSent: true, phoneChange: false});
        }

        if (!prevProps.user.pinVerified && this.props.user.pinVerified) {
            this.setState({pinVerified: true});
            this.props.dispatch(userActions.getMe());
        }

        if (!this.state.sameEmailError &&
            (!isObjectExists(prevProps, 'user.error.messages.error.message') || (isObjectExists(prevProps, 'user.error.messages.error.message') && prevProps.user.error.status !== 'The new email address is the same as the current address')) &&
             isObjectExists(this.props, 'user.error.messages.error.message') && this.props.user.error.messages.error.message === 'The new email address is the same as the current address') {
            this.props.dispatch(userActions.clearError());
            this.setState({sameEmailError: true});
        }

        if (!this.state.samePhoneError &&
            (!isObjectExists(prevProps, 'user.error.messages.error.message') || (isObjectExists(prevProps, 'user.error.messages.error.message') && prevProps.user.error.status !== 'The new phone number is the same as the current one')) &&
             isObjectExists(this.props, 'user.error.messages.error.message') && this.props.user.error.messages.error.message === 'The new phone number is the same as the current one') {
            this.props.dispatch(userActions.clearError());
            this.setState({samePhoneError: true});
        }

        if (!this.state.emailTakenError &&
            (!isObjectExists(prevProps, 'user.error.messages.error.message') || (isObjectExists(prevProps, 'user.error.messages.error.message') && prevProps.user.error.status !== 'The provided email address is already taken!')) &&
             isObjectExists(this.props, 'user.error.messages.error.message') && this.props.user.error.messages.error.message === 'The provided email address is already taken!') {
            this.props.dispatch(userActions.clearError());
            this.setState({emailTakenError: true});
        }

        if (!this.state.phoneTakenError &&
            (!isObjectExists(prevProps, 'user.error.messages.error.message') || (isObjectExists(prevProps, 'user.error.messages.error.message') && prevProps.user.error.status !== 'The provided phone number is already taken!')) &&
             isObjectExists(this.props, 'user.error.messages.error.message') && this.props.user.error.messages.error.message === 'The provided phone number is already taken!') {
            this.props.dispatch(userActions.clearError());
            this.setState({phoneTakenError: true});
        }

        if (!this.state.pinVerifiedError && this.props.user.verifyingPin === 'error' && prevProps.user.verifyingPin !== 'error') {
            this.props.dispatch(userActions.clearError());
            this.setState({pinVerifiedError: true});
        }

    }

    deleteMyAccount = () => {
        this.setState({confirmDeleteAccount: true});
    }

    confirmedDeleteMyAccount = () => {
        this.props.dispatch(userActions.deleteMyAccount());
        this.setState({deleteAccountRequest: true});
    }

    cancelDeleteMyAccount = () => {
        this.props.dispatch(userActions.cancelDeleteMyAccount());
        this.setState({cancelDeleteAccountRequest: true});
    }

    suspendMyAccount = () => {
        this.setState({confirmSuspendAccount: true});
    }

    confirmedSuspendMyAccount = () => {
        this.props.dispatch(userActions.suspendMyAccount());
        this.setState({suspendAccountRequest: true});
    }

    changePassword = () => {
        const { newPassword, newPasswordConfirm } = this.state;
        if (newPassword !== '' && newPasswordConfirm !== '') {
            if (newPassword !== newPasswordConfirm) {
                this.setState({passwordMatch: false});
            } else {
                this.props.dispatch(userActions.setPassword(this.state.newPassword));
                this.setState({passwordChange: false});
            }
        }
    }

    onChange = (e, value, obj, fullValue) => {
        let emailValid = true;
        let phoneValid = true;
        if (obj) {
            this.setState({
                newPhone:        fullValue,
                sameEmailError:  false,
                emailTakenError: false,
                phoneTakenError: false,
                isSubmitted:     false,
                phoneValid:      validatePhoneNumber(e, value, obj, fullValue)
            });

        } else {
            if (e.target.name === 'newEmail') {
                emailValid = validateEmail(e.target.value);
            }

            this.setState({
                [e.target.name]: e.target.value,
                passwordMatch:   true,
                emailValid,
                phoneValid,
                sameEmailError:  false,
                emailTakenError: false,
                phoneTakenError: false
            });
        }

    }

    changePhone = () => {
        if (this.state.phoneValid) {
            this.props.dispatch(userActions.updatePhoneNumber(this.state.newPhone));
        }
        this.setState({isSubmitted: true});
    }

    changeEmail = () => {
        if (this.state.emailValid) {
            this.props.dispatch(userActions.updateEmail(this.state.newEmail));
        }
        this.setState({isSubmitted: true});
    }

    verifyPin = () => {
        if (this.state.pin !== '') {
            this.props.dispatch(userActions.verifyPin(this.props.user.id, this.state.pin));
        }
    }

    changePasswordState = () => {
        this.setState({passwordChange: !this.state.passwordChange});
    }

    changeEmailState = () => {
        this.setState({emailChange: !this.state.emailChange});
    }

    changePhoneState = () => {
        this.setState({phoneChange: !this.state.phoneChange});
    }

    checkIfReturn = (e, type) => {
        if (e.keyCode == 13) {
            switch (type) {
                case 'email':
                    this.changeEmail();
                    break;
                case 'phone':
                    this.changePhone();
                    break;
                case 'password':
                    this.changePassword();
                    break;
            }
            this.setState({isSubmitted: true});
        }
    }

    renderEmail = () => {
        return (
            <Row className="profile-row">
                <Col xs="3" sm="3" md="3" lg="3" className="my-auto"><img className="profile-image" src={emailIcon} /></Col>
                {this.props.user.email ?
                    <Col xs="9" sm="9" md="9" lg="9">
                        <div>{this.accountEmail}</div>
                        <div>{this.props.user.email}</div>
                        <div >
                            <span className="navigation-button" onClick={() => this.changeEmailState()}>{this.changeEmailText}</span>
                        </div>
                    </Col>
                    :
                    <Col xs="9" sm="9" md="9" lg="9">
                        <div>{this.accountEmail}</div>
                        <div>
                            <span className="hover navigation-button" onClick={() => this.changeEmailState()}>{this.addEmailText}</span>
                        </div>
                    </Col>
                }
            </Row>

        );
    }

    renderEditEmail = () => {
        return (
            <React.Fragment>
                <Row className="profile-row">
                    <Col xs="3" sm="3" md="3" lg="3" className="my-auto"><img className="profile-image" src={emailIcon} /></Col>
                    <Col className="sign-up-input" xs="9" sm="9" md="9" lg="9">
                        <input
                            value={this.state.newEmail}
                            onChange={(e) => this.onChange(e)}
                            name="newEmail"
                            id="newEmail"
                            onKeyDown={(e) => this.checkIfReturn(e, 'email')}
                            type="email"
                            className={!this.state.emailValid && this.state.isSubmitted ? 'capsule-input invalid-input color-white' : 'capsule-input color-white'}
                            placeholder='New Email'
                        />
                        {this.state.sameEmailError && <span className="error-msg">{this.sameEmailErrorText}</span>}
                        {this.state.emailTakenError && <span className="error-msg">{this.emailTakenErrorText}</span>}
                        {!this.state.emailValid && this.state.isSubmitted && <span className="error-msg">{this.enterValidEmail}</span>}
                    </Col>
                    <Col xs="3" sm="3" md="3" lg="3"></Col>
                    <Col className="sign-up-input" xs="4" sm="4" md="4" lg="4">
                        <div className="hover navigation-button" onClick={() => this.changeEmail()}>
                            {this.submit}
                        </div>
                    </Col>
                    <Col className="sign-up-input" xs="5" sm="5" md="5" lg="5">
                        <div className="hover navigation-button" onClick={() => this.changeEmailState()}>
                            {this.cancel}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    renderPhone = () => {
        return (
            <React.Fragment>
                { this.props.user.userPhoneForVerification ?
                    <Row className="profile-row">
                        <Col xs="3" sm="3" md="3" lg="3" className="my-auto"><img className="profile-image" src={phoneNumberIcon} /></Col>
                        <Col xs="9" sm="9" md="9" lg="9">
                            <div>{this.phoneNumberText}</div>
                            <div>{this.props.user.userPhoneForVerification}</div>
                            <div className="hover navigation-button" onClick={() => this.changePhoneState()}>
                                {this.changePhoneNumberText}
                            </div>
                        </Col>
                    </Row>
                    :
                    <Row className="profile-row">
                        <Col xs="3" sm="3" md="3" lg="3" className="my-auto"><img className="profile-image" src={phoneNumberIcon} /></Col>
                        <Col xs="9" sm="9" md="9" lg="9">
                            <div>{this.phoneNumberText}</div>
                            <div className="hover navigation-button" onClick={() => this.changePhoneState()}>
                                {this.addPhoneNumberText}
                            </div>
                        </Col>
                    </Row>
                }
            </React.Fragment>
        );
    }

    renderEditPhone = () => {
        return (
            <Row className="profile-row">
                <Col xs="3" sm="3" md="3" lg="3" className="my-auto"><img className="profile-image" src={phoneNumberIcon} /></Col>
                <Col xs="9" sm="9" md="9" lg="9">
                    <React.Fragment>

                        <IntlTelInput
                            preferredCountries={['us', 'hu']}
                            onPhoneNumberChange={(e, value, obj, fullValue) => this.onChange(e, value, obj, fullValue)}
                            inputClassName={!this.state.phoneValid && this.state.isSubmitted ? 'capsule-input invalid-input color-white' : 'capsule-input color-white'}
                            containerClassName="intl-tel-input w-100"
                            fieldId="newPhone"
                            fieldName="newPhone"
                            placeholder={this.phoneNumber}
                            separateDialCode={true}
                            onSelectFlag={(e, value, obj, fullValue) => {this.setState({phoneValid: validatePhoneNumber(e, fullValue, value, obj)});}}
                        />
                        {this.state.samePhoneError && <span className="error-msg">{this.samePhoneErrorText}</span>}
                        {this.state.phoneTakenError && <span className="error-msg">{this.phoneTakenError}</span>}
                        {!this.state.phoneValid && this.state.isSubmitted && <span className="error-msg">{this.enterValidPhone}</span>}

                    </React.Fragment>
                </Col>
                <Col xs="3" sm="3" md="3" lg="3"></Col>
                <Col className="sign-up-input" xs="5" sm="5" md="5" lg="5">
                    <div className="hover navigation-button" onClick={() => this.changePhone()}>
                        {this.submit}
                    </div>
                </Col>

                <Col className="sign-up-input" xs="4" sm="4" md="4" lg="4">
                    <div className="hover navigation-button" onClick={() => this.changePhoneState()}>
                        {this.cancel}
                    </div>
                </Col>
            </Row>
        );
    }

    renderPassword = () => {
        return (
            <Row className="profile-row">
                <Col xs="3" sm="3" md="3" lg="3" className="my-auto"><img className="profile-image" src={passwordIcon} /></Col>

                <Col xs="9" sm="9" md="9" lg="9">
                    <div>{this.passwordText}</div>
                    <div>**********</div>

                    <div className="hover navigation-button" onClick={() => this.changePasswordState()}>
                        {this.changePasswordText}
                    </div>
                </Col>
            </Row>
        );
    }

    renderEditPassword = () => {
        return (
            <React.Fragment>
                <Row className="profile-row">
                    <Col xs="3" sm="3" md="3" lg="3" className="my-auto"><img className="profile-image" src={passwordIcon} /></Col>
                    <Col className="sign-up-input" xs="9" sm="9" md="9" lg="9">
                        <input
                            value={this.state.newPassword}
                            onChange={(e) => this.onChange(e)}
                            name="newPassword"
                            id="newPassword"
                            onKeyDown={(e) => this.checkIfReturn(e, 'password')}
                            type="password"
                            className="capsule-input color-white"
                            placeholder='New Password'
                        />
                    </Col>
                    <Col xs="3" sm="3" md="3" lg="3"></Col>
                    <Col className="sign-up-input" xs="9" sm="9" md="9" lg="9">
                        <input
                            value={this.state.newPasswordConfirm}
                            onChange={(e) => this.onChange(e)}
                            onKeyDown={(e) => this.checkIfReturn(e, 'password')}
                            name="newPasswordConfirm"
                            id="newPasswordConfirm"
                            type="password"
                            className="capsule-input color-white"
                            placeholder='Confirm Your New Password'
                        />
                    </Col>
                    <Col xs="3" sm="3" md="3" lg="3"></Col>
                    <Col className="sign-up-input" xs="9" sm="9" md="9" lg="9">
                        <Alert className="text-center" color="danger" isOpen={!this.state.passwordMatch}>
                            <span>{this.passwordNoMatch}</span>
                        </Alert>
                    </Col>

                    <Col xs="3" sm="3" md="3" lg="3"></Col>
                    <Col className="sign-up-input" xs="5" sm="5" md="5" lg="5">
                        <div className="hover navigation-button" onClick={() => this.changePassword()}>
                            {this.submit}
                        </div>
                    </Col>
                    <Col className="sign-up-input" xs="4" sm="4" md="4" lg="4">
                        <div className="hover navigation-button" onClick={() => this.changePasswordState()}>
                            {this.back}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    getCsvReader = () => {
        if (this.props.user.id === '5bdcf4f6-68f2-425c-958c-733b95e81063' || this.props.user.id === '904d3aed-cabf-48fc-bb05-dfb5c9b8fc29' || this.props.user.id === '69918610-e372-4110-ac93-9f045ca9ed6a') {

            let parserOptions = {
                header:          true,
                dynamicTyping:   true,
                skipEmptyLines:  true,
                transformHeader: header =>
                    header
                        .toLowerCase()
                        .replace(/\W/g, '_')
            };

            if (!this.props.capsules.importStatus || this.state.canImport) {
                return (
                    <Row>
                        <Col>
                            <CSVReader
                                cssClass="csv-reader-input"
                                label="Upload CSV for contact migration test"
                                onFileLoaded={this.parseCsv}
                                onError={this.handleError}
                                parserOptions={parserOptions}
                                inputId="ParseCsv"
                                inputStyle={{color: 'red'}}
                            /></Col>
                    </Row>

                );
            } else if (this.props.capsules.importStatus === 'pending') {
                return (
                    <Row>
                        <Col>
                            <span>Importing your contacts...</span>
                        </Col>
                    </Row>);
            } else {
                return (
                    <Row>
                        <Col>
                            <span>Contacts imported.</span><span className="btn btn-link" onClick={() => this.setState({canImport: !this.state.canImport})}>Upload another CSV</span>
                        </Col>
                    </Row>);
            }
        }
    }

    parseCsv = (data, file) => {
        this.props.dispatch(capsuleActions.importContactsFromCsv(data, file.name.split('.csv')[0]));
        this.setState({canImport: false});
    }

    render() {

        return (
            <Container className="container-width">
                {this.props.renderHeader()}
                <ModalView isOpen={this.state.emailSent}
                    toggle={() => this.setState({emailSent: !this.state.emailSent})}
                    title="Verification sent"
                    modalType="newEmail"
                />
                <ModalView isOpen={this.state.phoneSent}
                    toggle={() => this.setState({phoneSent: !this.state.phoneSent})}
                    title="PIN sent"
                    modalType="newPhone"
                    onContinue={() => this.verifyPin()}
                    onChange={this.onChange}
                    defaultValue={this.state.pin}
                    isInvalid={this.state.pinError}
                    verified={this.state.pinVerified}
                />
                <ModalView isOpen={this.state.confirmSuspendAccount}
                    toggle={() => this.setState({confirmSuspendAccount: !this.state.confirmSuspendAccount})}
                    title="Disable Account Confirmation"
                    modalType="suspendAccount"
                    onContinue={() => this.confirmedSuspendMyAccount()}
                />
                <ModalView isOpen={this.state.confirmDeleteAccount}
                    toggle={() => this.setState({confirmDeleteAccount: !this.state.confirmDeleteAccount})}
                    title="Delete Account Confirmation"
                    modalType="deleteAccount"
                    onContinue={() => this.confirmedDeleteMyAccount()}
                />
                <div className="group-container">
                    <Card className="section-card">

                        <CardBody>
                            <CardTitle>
                                <Col>{this.title}</Col>
                                {this.props.user.passwordSet && <Col className="right" xs="8" sm="8" md="8" lg="8">{this.pwChangeSuccess}</Col>}
                            </CardTitle>
                            <CardSubtitle className="small">
                                <Col>{this.subTitle}</Col>
                            </CardSubtitle>
                            { this.state.emailChange ? this.renderEditEmail() : this.renderEmail()}
                            { this.state.phoneChange ? this.renderEditPhone() :  this.renderPhone()}
                            { this.state.passwordChange ? this.renderEditPassword() : this.renderPassword()}

                        </CardBody>
                    </Card>
                </div>
                <Row className="profile-row">
                    <Col>{this.helpText}</Col>
                </Row>
                <Row className="profile-row">
                    <Col><span className="navigation-button hover" onClick={() => {this.props.logout(); this.props.backToGroups();}}>{this.logoutText}</span></Col>
                </Row>
                <Row className="profile-row">
                    <Col><span className="navigation-button hover" onClick={() => this.deleteMyAccount()}>{this.deleteMyAccountText}</span></Col>
                </Row>
                <Row className="profile-row">
                    <Col><span className="navigation-button hover" onClick={() => this.cancelDeleteMyAccount()}>{this.cancelDeleteMyAccountText}</span></Col>
                </Row>
                <Row className="profile-row">
                    <Col><span className="navigation-button hover" onClick={() => this.suspendMyAccount()}>{this.suspendMyAccountText}</span></Col>
                </Row>
                <Row className="profile-row">
                    <Col> <a target="_blank" href="terms.html"><span>{this.termAndConditionsText}</span></a></Col>
                </Row>
                <Row className="profile-row">
                    <Col> <a target="_blank" href="privacy.html"><span>{this.privacyPolicyText}</span></a></Col>
                </Row>
                {this.getCsvReader()}
            </Container>);
    }

}

ProfileSettings.propTypes = {
    user:         PropTypes.object,
    renderHeader: PropTypes.func,
    dispatch:     PropTypes.func,
    logout:       PropTypes.func,
    backToGroups: PropTypes.func,
    capsules:     PropTypes.object

};
