import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Container, Col } from 'reactstrap';

export class ConnectView extends Component {

    constructor (props) {
        super(props);
        this.state = {
            formOpened: false
        };
    }

    render() {
        if ((this.props.showShareBack && !this.props.showShareBackForm && this.props.sharer) || this.props.isPreview) {
            return (
                <Container>
                    <Row className="group-element share-spacing">
                        <Col className="navigation-button" onClick={this.props.isEdit === false ? null : () => this.props.onMenuChange('shareView')}>Share Capsule</Col>
                    </Row>
                    <Row className="group-element">
                        <Col className="navigation-button" onClick={this.props.isEdit === false ? null : () => this.props.onMenuChange('shareBackView')}>Share Capsule & Allow Share Backs</Col>
                    </Row>
                    {this.props.hasChat &&
                    <Row className="group-element">
                        <Col className="navigation-button" onClick={() => this.props.onMenuChange('chatbotView')}>Chat</Col>
                    </Row>
                    }
                    <Row className="group-element share-spacing">
                        <Col className="navigation-button" onClick={() => this.props.openSharedUsersModal()}>Who can view this capsule in their Shared With Me?</Col>
                    </Row>
                    <Row className="group-element mb-1 share-back-notes share-back-spacing">
                        <Col>Info on Share Backs:</Col>
                    </Row>
                    <Row className="group-element mb-1 share-back-notes">
                        <Col>Shared Back capsules are in Shared With Me.</Col>
                    </Row>
                    <Row className="group-element mb-1 share-back-notes">
                        <Col>Shared back forms are in My Contact Capsules.</Col>
                    </Row>
                </Container>
            );
        } else if (!this.props.showShareBack && this.props.showShareBackForm && this.props.shareBackUserHasCapsules && !this.props.sharer) {
            return (
                <Container>
                    <Row className="share-spacing"></Row>
                    {this.props.capsuleToShow.public &&
                    <Row className="group-element mb-4">
                        <Col className="navigation-button" onClick={this.props.isEdit === false ? null : () => this.props.onMenuChange('shareView')}>Share Capsule</Col>
                    </Row>
                    }
                    <Row className="group-element mb-1">
                        <Col className="navigation-button" onClick={() => this.props.openForm()}>Send your Contact Info OR</Col>
                    </Row>
                    <Row>
                        <Col className="navigation-button mb-4"  onClick={() => this.props.openCapsuleList()}>Send Back one of your Capsules</Col>
                    </Row>
                    {this.props.hasChat &&
                    <Row className="group-element">
                        <Col className="navigation-button" onClick={() => this.props.onMenuChange('chatbotView')}>Chat</Col>
                    </Row>
                    }
                </Container>
            );
        } else if (!this.props.showShareBack && this.props.showShareBackForm && !this.props.shareBackUserHasCapsules && !this.props.sharer) {
            if (!this.props.capsuleToShow.public && !this.props.hasChat) {
                if (!this.state.formOpened && !this.props.shareBackModal) {
                    this.props.openForm();
                    this.setState({formOpened: !this.state.formOpened});
                }
                return (
                    <Container>
                        <Row className="group-element mb-1">
                            <Col className="navigation-button" onClick={() => this.props.openForm()}>Send your Contact Info</Col>
                        </Row>
                    </Container>
                );
            } else {

                return (
                    <Container>
                        <Row className="share-spacing"></Row>
                        {this.props.capsuleToShow.public &&
                        <Row className="group-element mb-4">
                            <Col className="navigation-button" onClick={this.props.isEdit === false ? null : () => this.props.onMenuChange('shareView')}>Share Capsule</Col>
                        </Row>
                        }
                        <Row className="group-element mb-4">
                            <Col className="navigation-button" onClick={() => this.props.openForm()}>Send your Contact Info</Col>
                        </Row>
                        {this.props.hasChat &&
                        <Row className="group-element">
                            <Col className="navigation-button" onClick={() => this.props.onMenuChange('chatbotView')}>Chat</Col>
                        </Row>
                        }
                    </Container>
                );
            }
        } else {
            return (
                <Container>
                    <Row className="share-spacing"></Row>
                    {this.props.capsuleToShow.public &&
                    <Row className="group-element">
                        <Col className="navigation-button" onClick={this.props.isEdit === false ? null : () => this.props.onMenuChange('shareView')}>Share Capsule</Col>
                    </Row>
                    }
                    {this.props.hasChat &&
                    <Row className="group-element">
                        <Col className="navigation-button" onClick={() => this.props.onMenuChange('chatbotView')}>Chat</Col>
                    </Row>
                    }
                </Container>
            );

        }

    }

}

ConnectView.propTypes = {
    isEdit:                   PropTypes.func,
    onMenuChange:             PropTypes.func,
    hasChat:                  PropTypes.bool,
    showShareBack:            PropTypes.bool,
    showShareBackForm:        PropTypes.bool,
    openForm:                 PropTypes.func,
    openCapsuleList:          PropTypes.func,
    shareBackUserHasCapsules: PropTypes.bool,
    sharer:                   PropTypes.bool,
    capsuleToShow:            PropTypes.object,
    shareBackModal:           PropTypes.bool,
    isPreview:                PropTypes.bool,
    openSharedUsersModal:     PropTypes.func
};
