/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Collapse, Form, FormGroup, Container } from 'reactstrap';
import { userActions } from '../redux/actions';
//import cameraIcon from '../../assets/images/CameraIcon1@3x.png';
import addPhotoIcon from '../../assets/images/AddPhotoVideoIcon@2x.png';
//import addPhotoToIcon from '../../assets/images/AddPhoto.png';
import Viewer from 'react-viewer';
import addIcon from '../../assets/images/AddIcon@3x.png';
import subtractIcon from '../../assets/images/Subtract@3x.png';
import cameraIcon from '../../assets/images/AddPhoto.png';
import 'react-viewer/dist/index.css';
import { isObjectExists, getUserTokenFromStorage } from '../../utils/Helpers';
import { getUrlAndLicence} from '../../utils/VieroUtils';
import VideoPlayer from '../video/VideoPlayer';
import {VideoModal} from '../video/VideoModal';
import backButton from '../../assets/images/Close@2x.png';
import bioIcon from '../../assets/images/BioIcon.png';
import photosIcon from '../../assets/images/PhotosIcon.png';
import audioIcon from '../../assets/images/AudioIcon.png';
import docsIcon from '../../assets/images/DocumentsIcon.png';
import notesIcon from '../../assets/images/NotesIcon.png';
import {ModalView} from './ModalView';
import mediaIcon from '../../assets/images/MediaIcon@3x.png';

export class MediaEdit extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            uploadedFiles: '',
            imageToShow:   '',
            visible:       false,
            fileUploaded:  false,
            mediaData:     this.props.mediaData,
            isSectionOpen: false,
            audio:         false,
            video:         false,
            photos:        false,
            documents:     false,
            fileToEdit:    null,
            showVideo:     {},
            //everUploadImage: false
        };

        this.inputPictureRef = React.createRef();
        this.inputVideoRef = React.createRef();
        this.inputDocumentRef = React.createRef();
        this.inputAudioRef = React.createRef();

        this.uploadingText = 'Uploading ';
        this.processingText = 'Processing ';
        this.removeText = 'Remove';
        this.thumbnailParams = '150x150,crop';
        this.defaultParam = '700x';

    }

    componentDidUpdate(prevProps) {
        if (prevProps.user.fileUploaded === false && this.props.user.fileUploaded === true) {
            this.setState({
                audio:     false,
                video:     false,
                photos:    false,
                documents: false,
            });
        }
    }

    onArtistChange = (e) => {
        this.props.onCapsuleChange(e, 'artist');
    }

    onMediaChange = (e, i) => {
        this.props.onCapsuleChange(e, 'media', i);
    }

    onUrlChange = (value, type) => {
        this.props.onCapsuleChange(value, type);
        this.openModal(type);
    }

    openUploadFileDialog = () => {
        this.openModal('photos');
        this.inputPictureRef.current.click();
        //if (!this.state.everUploadImage) {
        //    this.setState({everUploadImage: true});
        //}
    }

    openUploadVideoDialog = () => {
        this.openModal('video');
        this.inputVideoRef.current.click();
    }

    openUploadAudioDialog = () => {
        this.openModal('audio');
        this.inputAudioRef.current.click();
    }

    openUploadDocumentDialog = () => {
        this.openModal('documents');
        this.inputDocumentRef.current.click();
    }

    openBrowse = () => {
        this.setState({isBrowse: true});
    }

    openModal = (type) => {
        this.setState({[type]: !this.state[type]});
    }

    toggleMenu = (menu) => {
        this.setState({[menu]: !this.state[menu]});
    }

    toggleVideo = (i) => {
        let showVideo = {...this.state.showVideo};

        showVideo[i] = showVideo[i] ? !showVideo[i] : true;
        this.setState({showVideo});
    }

    uploadFile = (e, type) => {
        if (e.target.files.length == 0) return;

        const fileToUpload = e.target.files[0];
        let canUpload = false;

        if ((type === 'picture' || type === 'photo') && -1 < fileToUpload.type.indexOf('image/') ||
        type === 'video' && -1 < fileToUpload.type.indexOf('video/') ||
        type === 'audio' && -1 < fileToUpload.type.indexOf('audio/') ||
        type === 'document' && -1 < fileToUpload.type.indexOf('text/') || -1 < fileToUpload.type.indexOf('application/')) {
            canUpload = true;
        }

        if (canUpload) {
            this.props.dispatch(userActions.uploadFile(e.target.files, this.props.user.token, type));
            switch (type) {
                case 'video':
                    this.props.incrementUploadCount(true);
                    this.props.setCheckingProgressOf('mediaVideo');
                    break;
                case 'photo':
                    this.props.incrementUploadCount();
                    this.props.setCheckingProgressOf('mediaImage');
                    break;
                case 'document':
                    this.setState({fileUploaded: true, uploadedFiles: fileToUpload});
                    break;
            }
        }

        this.setState({fileToEdit: null});

    }

    removePhoto = (id) => {
        this.props.dispatch(userActions.deleteFile(id, this.props.user.token));

        /*var images = this.props.capsuleToEdit.items.image_and_video.images;
        var data = [];
        for (var i in images) {
            if (images[i].fileId !== id) {
                data.push(images[i]);
            }
        }*/
    }

    getDocumentsName = (file) => {
        const files = this.props.user.fileId;
        let id = file.fileId;
        if (file.url && file.url !== '') {
            return file.url;
        }
        let name = '';
        for (var i in files) {
            if (files[i].id === id) {
                name = files[i].name;
            }
        }
        if (name === '') {
            name = this.state.uploadedFiles.name;
        }
        return name;
    }

    renderUploadedFiles = () => {
        return (
            this.props.user.gotFiles &&
            Object.keys(this.props.user.fileId).map((i) => (
                <Col className="photos media-image" key={'photo-' + i} >
                    <img
                        className="media-image"
                        src={process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + this.props.user.fileId[i].id + '?token=' + getUserTokenFromStorage()}
                        onClick={() => { this.setState({ visible: !this.state.visible, imageToShow: process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + this.props.user.fileId[i].id + '?token=' + getUserTokenFromStorage() }); } }
                    />
                    <div className="hover mb-10" onClick={() => this.removePhoto(this.props.user.fileId[i].id)}>{this.removeText}</div>
                </Col>

            ))

        );
    }

    getSrcUrl = (values, elementId) => {
        if (values.fileId && values.fileId !== '') {
            return elementId ?
                process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + values.fileId +'/'+ this.props.getPreviewResizerParameters(elementId) + '?token=' + getUserTokenFromStorage()
                :
                process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + values.fileId + '?token=' + getUserTokenFromStorage();
        } else if (values.url && values.url !== '') {
            return values.url;
        }
    }

    getFileName = (id) => {
        let files = {...this.props.user.fileId};

        for (let i in files) {
            if (files[i].id === id) {
                return files[i].name;
            }
        }

        return '';
    }

    getImage = (imageObject, i) => {
        //let imagesCaseOf = 'Top of getImage';
        let image;
        let imageUrl = isObjectExists(imageObject, 'metadata.storageType') && imageObject.metadata.storageType === 'viero' ? getUrlAndLicence(imageObject.metadata.storageUUID, false, this.thumbnailParams, 'VieroToolkitUtilsTypeStillSingle').url : this.getSrcUrl(imageObject, 'photo-' + i);
        let viewUrl = isObjectExists(imageObject, 'metadata.storageType') && imageObject.metadata.storageType === 'viero' ? getUrlAndLicence(imageObject.metadata.storageUUID, false, this.props.getImageScalingParameter(), 'VieroToolkitUtilsTypeStillSingle').url : this.getSrcUrl(imageObject, 'view');

        let tempStorageUUID = imageObject.metadata && imageObject.metadata.storageUUID ? imageObject.metadata.storageUUID : '';

        this.props.checkIfUploading();
        if (isObjectExists(this.props, 'user.fireBaseStatus.' + tempStorageUUID) && this.props.user.fireBaseStatus[tempStorageUUID] !== 'ready') {
            let status = this.props.user.fireBaseStatus[tempStorageUUID];
            image = (<div>{status}...</div>);

            //imagesCaseOf = 'loading/processing';

        // this case won't display S3 so need condition for S3 - removed while working on viero, need to add non-viero storageType
        // second condition required for previously saved images
        // This logic also assumes all previously saved images displayImages[i] is able to be set to 'ready'
        // Also, If user has a lot of images stored, verify all are displayed in images section
        // prior to a user upload since status goes to true as soon as upload is started.
        // To fix, need saved configuration - update in future
        // Note: metadata.storageUUID is available before the fireBaseStatus[storageUUID] just prior to file being uploaded
        } else if ((isObjectExists(this.props, 'user.fireBaseStatus.' + tempStorageUUID) && this.props.user.fireBaseStatus[tempStorageUUID] === 'ready')
          || this.props.displayImages[i] === 'ready') {

            //imagesCaseOf = 'viero ready new image or previous saved images';
            // can delete later - now setting in didMount of CreateCardView
            //if (this.props.displayImages[i] !== 'ready') {
            //    this.props.updateDisplayImages(i);
            //}

            image =  (
                <React.Fragment>
                    <img
                        id={'photo-' + i}
                        className="media-images"
                        src={imageUrl}
                        onClick={() => { this.setState({ visible: !this.state.visible, imageToShow: viewUrl }); } }
                    />
                    <div className="hover mb-10" onClick={() => this.props.removeFromImages(imageObject)}>{this.removeText}</div>
                </React.Fragment>
            );
        }

        console.log('i=', i, 'gettingFireBase=', this.props.user.gettingFireBase);
        //console.log('EditMediaView =', imagesCaseOf, 'i=', i, 'storageUUID=', imageObject.metadata.storageUUID);

        console.log('i=', i);
        console.log('this.props.user.photoUploadInProgress:', this.props.user.photoUploadInProgress);
        console.log('this.state.everUploadImage:', this.state.everUploadImage);
        if (isObjectExists(this.props, 'user.fireBaseStatus.' + tempStorageUUID)) {
            console.log('firebaseStatus[storageUUId] exists:', tempStorageUUID);
            console.log('firebase status:', this.props.user.fireBaseStatus[tempStorageUUID]);
        } else {
            console.log('firebaseStatus[storageUUID] doesnt exist');
        }
        if (isObjectExists(imageObject, 'metadata.storageUUID')) {
            console.log('object exists storageUUID =', tempStorageUUID);
        } else {
            console.log('object does not exist');
        }

        // update - delete later if works because removed need for this
        // may not trigger because it will be set just prior when user selects a file to uploaded
        // keep here for now
        //if (this.props.user.photoUploadInProgress && !this.state.everUploadImage) {
        //    this.setState({everUploadImage: true});
        //}

        return image;
    }

    renderPhotos = () => {
        if (isObjectExists(this.state, 'mediaData.images')) {
            const photos = this.state.mediaData.images;
            return (
                Object.keys(photos).map((i) => (
                    <React.Fragment key={'photo-' + i}>
                        <Col className="vertical-align" xs="6" sm="6" md="3" lg="3"  >
                            {this.getImage(photos[i], i)}
                            {isObjectExists(photos[i], 'metadata.storageType') && photos[i].metadata.storageType === 'S3' &&  <div ><a href={this.getSrcUrl(photos[i])} download target="_blank" rel="noopener noreferrer">Download</a></div>}
                        </Col>

                    </React.Fragment>

                ))

            );
        } else {
            return null;
        }
    }

    getVideo = (videoObject, i, preview) => {
        let video = null;
        let status = this.uploadingText;
        if ((isObjectExists(videoObject, 'metadata.storageType') && videoObject.metadata.storageType === 'viero'  && this.props.getStatusOfMedia(videoObject.metadata.storageUUID, 'mediaVideo') === false)
        || (isObjectExists(videoObject, 'metadata.storageUUID') && this.props.user.gettingFireBase === true && this.props.user.fireBaseStatus && this.props.user.fireBaseStatus[videoObject.metadata.storageUUID] !== 'ready' && this.props.user.fireBaseStatus[videoObject.metadata.storageUUID] !== undefined)) {
            status = this.props.user.fireBaseStatus[videoObject.metadata.storageUUID] === 'processing' ? this.processingText : this.uploadingText;
            video = (<div>{status}video...</div>);
        } else {
            video =  (
                isObjectExists(videoObject, 'metadata.storageType') && videoObject.metadata.storageType === 'viero' ?
                    !preview ?
                        <VideoPlayer
                            url={getUrlAndLicence(videoObject.metadata.storageUUID, false, '', 'VieroToolkitUtilsTypeMotion').url}
                            keySystems={getUrlAndLicence(videoObject.metadata.storageUUID, false, '', 'VieroToolkitUtilsTypeMotion').keySystems}
                            videoClass="media-videos"
                            isViero={true}
                            listenStatus={this.props.listenStatus}
                            id={videoObject.metadata.storageUUID}
                            status={this.props.user.fireBaseStatus}
                        />

                        :
                        <React.Fragment>
                            <img className="media-images" onClick={() => this.toggleVideo(i)} src={getUrlAndLicence(videoObject.metadata.storageUUID, true, this.thumbnailParams, 'VieroToolkitUtilsTypeMotion').url} />
                            <div className="navigation-button" onClick={() => this.props.onRemove(i)}>{this.removeText}</div>
                        </React.Fragment>
                    :
                    !preview ?
                        <VideoPlayer
                            url={this.getSrcUrl(videoObject)}
                            id={'media-video-' + i}
                            videoClass="modal-playback"
                        />

                        :
                        <React.Fragment>
                            <img src={mediaIcon} className="media-images hover play-button" onClick={() => this.toggleVideo(i)} />
                            <div className="navigation-button" onClick={() => this.props.onRemove(i)}>{this.removeText}</div>
                        </React.Fragment>

            );
        }
        return video;
    }

    renderVideos = () => {
        if (isObjectExists(this.state, 'mediaData.videos')) {
            const videos = this.state.mediaData.videos;
            return (
                Object.keys(videos).map((i) => (
                    <React.Fragment key={'video-' + i}>
                        <VideoModal isOpen={this.state.showVideo[i]}
                            toggle={() => this.toggleVideo(i)} video={this.getVideo(videos[i], i)}
                            status={this.props.user.fireBaseStatus && videos[i].metadata && videos[i].metadata.storageUUID ? this.props.user.fireBaseStatus[videos[i].metadata.storageUUID] : false }/>
                        <Col xs="6" sm="6" md="3" lg="3" className="vertical-align text-center">
                            {videos[i].url && videos[i].url !== '' && <div ><a href={videos[i].url} target="_blank" rel="noopener noreferrer">URL</a></div>}
                            <div>{this.getVideo(videos[i], i, true)}</div>
                            {<div ><a href={this.getSrcUrl(videos[i])} target="_blank" rel="noopener noreferrer" download={true}>Download</a></div>}
                        </Col>
                    </React.Fragment>

                ))

            );
        } else {
            return null;
        }
    }

    renderAudio = () => {
        if (isObjectExists(this.state, 'mediaData.audio')) {
            const audio = this.state.mediaData.audio;
            return (
                this.props.user.gotFiles &&
            Object.keys(audio).map((i) => (
                <Row key={'audio-' + i} >
                    <Col lg="3" md="3" sm="3" xs="4">
                        <img className="profile-audio-docs-icon" src={cameraIcon} />
                    </Col>
                    <Col lg="7" md="7" sm="7" xs="6" className="capsule-name">
                        <Form>
                            <FormGroup>
                                <input
                                    value={audio[i].filename ? audio[i].filename : audio[i].url}
                                    name="audio"
                                    id="audio"
                                    onChange={(e) => this.props.onCapsuleChange(e, 'mediaName', i)}
                                    className="capsule-input"
                                    placeholder='File Name'
                                />
                                <span onClick={() => {this.openModal('audio'); this.props.setMediaToEdit(i);}}>
                                    <input
                                        value={this.getFileName(audio[i].fileId) || 'Choose File'}
                                        disabled
                                        name="category"
                                        id="category"
                                        className="capsule-input select-file"
                                    />
                                </span>
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col className="my-auto" lg="2" md="2" sm="2" xs="2" onClick={() => this.props.removeFromAudio(i)}>
                        <img src={subtractIcon} className="manage-icons" />
                    </Col>

                </Row>
            ))

            );
        } else {
            return null;
        }
    }

    renderDocuments = () => {
        const docs = this.props.mediaData.documents;
        return (
            Object.keys(docs).map((i) => (
                <Row key={'docs-' + i}>

                    <Col lg="3" md="3" sm="3" xs="4">
                        <img className="profile-audio-docs-icon" src={cameraIcon} />
                    </Col>
                    <Col  lg="7" md="7" sm="7" xs="6" className="capsule-name">
                        <Form>
                            <FormGroup>
                                <input
                                    value={docs[i].filename ? docs[i].filename : docs[i].url}
                                    name="documents"
                                    id="documents"
                                    onChange={(e) => this.props.onCapsuleChange(e, 'mediaName', i)}
                                    className="capsule-input"
                                    placeholder='File Name'
                                />
                                <span onClick={() => {this.openModal('documents'); this.props.setMediaToEdit(i);}}>
                                    <input
                                        value={this.getFileName(docs[i].fileId) || 'Choose File'}
                                        disabled
                                        name="category"
                                        id="category"
                                        className="capsule-input select-file"
                                    />
                                </span>
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col  lg="2" md="2" sm="2" xs="2" onClick={() => this.props.removeFromDocuments(i)}>
                        <img src={subtractIcon} className="manage-icons" />
                    </Col>

                </Row>
            ))

        );
    }

    renderBrowse = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                    Your uploaded files
                    </Col>
                </Row>
                <Row>
                    {this.renderUploadedFiles()}

                    <Col>
                        <img className="media-images" src={addPhotoIcon}  onClick={() => this.openUploadFileDialog()} />
                    </Col>

                </Row>

                <Viewer
                    visible={this.state.visible}
                    onClose={() => { this.setState({ visible: false }); } }
                    images={[{src: this.state.imageToShow, alt: ''}]}
                    drag={false}
                    zoomable={false}
                    noNavbar={true}
                    noToolbar={true}
                    noClose={false}
                    onMaskClick={() => { this.setState({ visible: false }); } }
                />
            </React.Fragment>
        );
    }

    renderPhotosVideos = () => {
        return (
            <React.Fragment>
                <ModalView isOpen={this.state.photos}
                    toggle={() => this.openModal('photos')}
                    onUpload={() =>this.openUploadFileDialog()}
                    onContinue={this.onUrlChange}
                    type="photos"
                    title="Select Photo"
                    defaultValue={''}
                    modalType="media"
                />
                <ModalView isOpen={this.state.video}
                    toggle={() => this.openModal('video')}
                    onUpload={() =>this.openUploadVideoDialog()}
                    onContinue={this.onUrlChange}
                    type="videos"
                    title="Select Video"
                    defaultValue={''}
                    modalType="media"
                />
                <Row className="font-weight-bold mb-10">
                    <Col>Photos {(this.props.user.photoUploadInProgress || this.props.photoCount !== 0) && <span>- Uploading... - {this.props.photoCount} of {this.props.maxPhotoCount} complete</span>}</Col>
                </Row>
                <Row className="pb-15">
                    {this.renderPhotos()}

                    <Col className="left">
                        <img className="media-images" src={addPhotoIcon}  onClick={() => this.openModal('photos')} />
                    </Col>
                    <input
                        ref={this.inputPictureRef}
                        onChange={(e) => this.uploadFile(e, 'photo')}
                        type="file"
                        accept=".jpg, .jpeg, .png, .gif, .heic"
                        style={{display: 'none'}}/>

                    <Viewer
                        visible={this.state.visible}
                        onClose={() => { this.setState({ visible: false }); } }
                        images={[{src: this.state.imageToShow, alt: ''}]}
                        drag={false}
                        zoomable={false}
                        noNavbar={true}
                        noToolbar={true}
                        noClose={false}
                        onMaskClick={() => { this.setState({ visible: false }); } }
                    />
                </Row>
                <Row className="font-weight-bold mb-10">
                    <Col>
                          Video {(this.props.user.videoUploadInProgress || this.props.videoCount !== 0) && <span>- Uploading... - {this.props.videoCount} of {this.props.maxVideoCount} complete</span>}
                    </Col>
                </Row>
                <Row className="pb-15">
                    {this.renderVideos()}
                    <Col>
                        <img className="media-images" src={addPhotoIcon}  onClick={() => this.openModal('video')} />
                    </Col>
                    <input
                        ref={this.inputVideoRef}
                        onChange={(e) => this.uploadFile(e, 'video')}
                        type="file"
                        accept="video/mp4,video/x-m4v,video/*, .m4v"
                        style={{display: 'none'}}/>
                </Row>

            </React.Fragment>
        );
    }

    renderAudioSection = () => {
        return (
            <React.Fragment>
                <ModalView isOpen={this.state.audio}
                    toggle={() => this.openModal('audio')}
                    onUpload={() =>this.openUploadAudioDialog()}
                    onContinue={this.onUrlChange}
                    type="audio"
                    title="Select Audio"
                    defaultValue={''}
                    modalType="media"
                />
                {this.renderAudio()}
                <Row className="pb-15">
                    <Col lg="3" md="3" sm="3" xs="4">
                        <img className="profile-audio-docs-icon hover" src={cameraIcon} />
                    </Col>
                    <Col lg="7" md="7" sm="7" xs="6" className="capsule-name">
                        <Form>
                            <FormGroup>
                                <input
                                    value={this.props.newAudioName}
                                    name="name"
                                    onChange={(e) => this.props.onNewNameChange(e.target.value, 'audio')}
                                    id="name"
                                    readOnly
                                    className="capsule-input"
                                    placeholder='File Name'
                                />
                                <Row onClick={() => this.openModal('audio')} className="type-here hover">Select Audio
                                    <input
                                        value='Choose File'
                                        disabled
                                        name="category"
                                        id="category"
                                        className="capsule-input select-file"
                                        placeholder='Select Audio'
                                    />
                                </Row>
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col lg="2" md="2" sm="2" xs="2"></Col>
                </Row>
                {this.props.user.audioUploadInProgress && <Row className="upload-inprogress"><Col>Audio upload in progress...</Col></Row> }
                <input
                    ref={this.inputAudioRef}
                    onChange={(e) => this.uploadFile(e, 'audio')}
                    type="file"
                    accept=".mp3, .wav, .m4a, .mda"
                    style={{display: 'none'}}/>

            </React.Fragment>
        );
    }

    renderDocumentsSection = () => {
        return (
            <React.Fragment>
                <ModalView isOpen={this.state.documents}
                    toggle={() => this.openModal('documents')}
                    onUpload={() =>this.openUploadDocumentDialog()}
                    onContinue={this.onUrlChange}
                    type="documents"
                    title="Select Document"
                    defaultValue={''}
                    modalType="media"
                />
                {this.renderDocuments()}
                <Row className="pb-15">
                    <Col lg="3" md="3" sm="3" xs="4">
                        <img className="profile-audio-docs-icon" src={cameraIcon}/>
                    </Col>
                    <Col  lg="7" md="7" sm="7" xs="6" className="capsule-name">
                        <Form>
                            <FormGroup>
                                <input
                                    value={this.props.newDocName}
                                    name="name"
                                    onChange={(e) => this.props.onNewNameChange(e.target.value, 'document')}
                                    readOnly
                                    id="name"
                                    className="capsule-input"
                                    placeholder='File Name'

                                />
                                <Row  onClick={() => this.openModal('documents')} className="type-here hover">Select Document
                                    <input
                                        value='Choose File'
                                        disabled
                                        readOnly

                                        name="category"
                                        id="category"
                                        className="capsule-input select-file"
                                        placeholder='Choose File'
                                    />
                                </Row>
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col lg="2" md="2" sm="2" xs="2"></Col>

                    <input
                        ref={this.inputDocumentRef}
                        onChange={(e) => this.uploadFile(e, 'document')}
                        type="file"
                        accept=".pdf, .doc, .txt, .docx, .xls, .xlsx, .rtf"
                        style={{display: 'none'}}/>
                </Row>
                {this.props.user.documentUploadInProgress && <Row className="upload-inprogress"><Col>Document upload in progress...</Col></Row> }
            </React.Fragment>
        );
    }

    renderNotesSection = () => {
        return (
            <React.Fragment>
                <Row><Col className="font-weight-bold">Notes</Col></Row>
                {Object.keys(this.props.mediaData.notes).map((i) => (
                    <Row key={i} className="pb-20">
                        <Col xs="10" sm="10" md="10" lg="10">
                            <input
                                value={this.props.mediaData.notes[i]}
                                onChange={(e) => this.onMediaChange(e, i)}
                                name="notes"
                                id="notes"
                                className="capsule-input"
                                placeholder='Notes'
                            />
                        </Col>

                        {i == this.props.mediaData.notes.length - 1 ?
                            <Col xs="2" lg="2" md="2" sm="2" className="pb-3" onClick={this.props.mediaData.notes[i] !== '' ? () => this.props.addRemoveNote() : null}>
                                <img src={addIcon} className="manage-icons" />
                            </Col>
                            :
                            <Col xs="2" lg="2" md="2" sm="2" onClick={() => this.props.addRemoveNote(i)}>
                                <img src={subtractIcon} className="manage-icons" />
                            </Col>
                        }
                    </Row>

                ))}

            </React.Fragment>
        );
    }

    renderBioSection = () => {
        return (
            <React.Fragment>
                <Row><Col className="font-weight-bold">Bio</Col></Row>
                <Row className="font-weight-bold pb-15">
                    <Col>
                        <input
                            value={this.props.artistData.bio}
                            onChange={(e) => this.onArtistChange(e)}
                            name="bio"
                            id="bio"
                            className="capsule-input"
                            placeholder='Bio'
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );

    }

    getSectionContent = () => {
        switch (this.props.section) {
            case 'photoVideo':
                return this.renderPhotosVideos();
            case 'audio':
                return this.renderAudioSection();
            case 'documents':
                return this.renderDocumentsSection();
            case 'bio':
                return this.renderBioSection();
            case 'notes':
                return this.renderNotesSection();
            default:
                return null;
        }
    }

    getSectionIcon = () => {
        switch (this.props.section) {
            case 'photoVideo':
                return photosIcon;
            case 'audio':
                return audioIcon;
            case 'documents':
                return docsIcon;
            case 'bio':
                return bioIcon;
            case 'notes':
                return notesIcon;
            default:
                return null;
        }
    }

    getSectionName = () => {
        switch (this.props.section) {
            case 'photoVideo':
                return 'Add Photos & Video';
            case 'audio':
                return 'Add Audio';
            case 'documents':
                return 'Add Documents';
            case 'bio':
                return 'Add Bio';
            case 'notes':
                return 'Add Notes';
            default:
                return null;
        }
    }

    render() {
        let sectionName = this.getSectionName();
        let sectionContent = this.getSectionContent();
        let sectionIcon = this.getSectionIcon();
        return (
            !this.props.isSectionOpen ?
                <Container className="profile-closed mt-10">
                    <Row onClick={() => this.props.toggleMenu(this.props.section)}>
                        <Col>
                            <div onClick={() => this.props.toggleMenu(this.props.section)}>
                                <img src={sectionIcon} className={'section-icon' + ' ' + this.props.section} /><span>{sectionName}</span>
                            </div>
                        </Col>
                        <Col xs="2" className="right"><img src={backButton} className="hover section-button"/></Col>
                    </Row>
                </Container>
                :
                <Collapse isOpen={this.props.isSectionOpen}>
                    <Container className="profile-card mt-10" >
                        <Row onClick={() => this.props.toggleMenu(this.props.section)} className="mb-2 hover">
                            <Col className="font-weight-bold color-black">
                                <img src={sectionIcon} className={'section-icon' + ' ' + this.props.section} /><span>{sectionName}</span>
                            </Col>
                            <Col xs="2" className="right">
                                <img src={backButton} className="hover button-up section-button"/>
                            </Col>

                        </Row>
                        {sectionContent}
                    </Container>
                </Collapse>
        );

        /*
        if (this.state.isBrowse) {
            return this.renderBrowse();
        } else {
            return this.renderMain();
        }
        */
    }

}

MediaEdit.propTypes = {
    dispatch:                    PropTypes.func,
    //changeView:                  PropTypes.func,
    user:                        PropTypes.object,
    capsuleToEdit:               PropTypes.object,
    onCapsuleChange:             PropTypes.func,
    isBrowse:                    PropTypes.bool,
    removeFromImages:            PropTypes.func,
    mediaData:                   PropTypes.object,
    artistData:                  PropTypes.object,
    addRemoveNote:               PropTypes.func,
    removeFromDocuments:         PropTypes.func,
    removeFromVideos:            PropTypes.func,
    removeFromAudio:             PropTypes.func,
    videoUploadInProgress:       PropTypes.bool,
    section:                     PropTypes.string,
    isSectionOpen:               PropTypes.bool,
    toggleMenu:                  PropTypes.func,
    onRemove:                    PropTypes.func,
    onNotesChange:               PropTypes.func,
    setMediaToEdit:              PropTypes.func,
    mediaToEdit:                 PropTypes.number,
    newAudioName:                PropTypes.string,
    newDocName:                  PropTypes.string,
    onNewNameChange:             PropTypes.func,
    getPreviewResizerParameters: PropTypes.func,
    incrementUploadCount:        PropTypes.func,
    videoCount:                  PropTypes.number,
    maxVideoCount:               PropTypes.number,
    photoCount:                  PropTypes.number,
    maxPhotoCount:               PropTypes.number,
    getStatusOfMedia:            PropTypes.func,
    setCheckingProgressOf:       PropTypes.func,
    listenStatus:                PropTypes.func,
    getImageScalingParameter:    PropTypes.func,
    displayImages:               PropTypes.array,
    updateDisplayImages:         PropTypes.func,
    checkIfUploading:            PropTypes.func
};
