import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './App';
import { history } from './client/components/redux/helpers/history';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './client/components/redux/helpers/store';

const rootElement = document.getElementById('root');
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

ReactDOM.render(
    <Provider store={store}>
        <Router basename={baseUrl} history={history}>
            <App />
        </Router>
    </Provider>,

    rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
