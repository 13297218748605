import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Container, Row, Col } from 'reactstrap';
import { sortAlphabeticallyUsers } from '../../utils/Helpers';
import redButtonIcon from '../../assets/images/Red-button-icon.png';
import blueButtonIcon from '../../assets/images/Blue-button-icon.png';
import greenButtonIcon from '../../assets/images/Green-button-icon.png';

export class BannedUsersAdmin extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            getBannedUsers:          false,
            gotBannedUsers:          false,
            BannedUsers:             [],
            countVerifiedUsers:      0,
            countBannedUsers:        0,
            updatedBannedValue:      false,
            countDisableDeleteUsers: 0,
            verified:                true,
            updateVerified:          true
        };

        this.deleteText = 'Delete Set';
        this.disabledText = 'Disabled Set';
        this.disableDeleteText = 'Accounts with Delete or Disabled Set';
        this.bannedText = 'Banned';
        this.notBannedText = 'Not Banned';
        this.updateStatusText = 'Click circle to change status';

    }

    componentDidMount() {
        this.props.setGotVerifiedUsers(false);
    }

    componentDidUpdate() {

        if (this.state.getBannedUsers === false && this.props.gotVerifiedUsers === false) {
            this.props.getVerifiedUsers(this.state.verified);
            this.setState({getBannedUsers: true});
        }

        if (this.props.gotVerifiedUsers === true && this.state.gotBannedUsers === false) {
            this.listBannedUsers();
            this.setState({gotBannedUsers: true});
        }

        if (this.props.updatedBannedValue === true && this.state.updatedBannedValue === false) {
            this.setUpdatedUserBanned(this.props.bannedUserId, this.props.banned);
            this.props.setUpdatedUserBanned(false);
            this.setState({updatedBannedValue: true});
        }

        if (this.props.updatedBannedValue === false && this.state.updatedBannedValue === true) {
            this.setState({updatedBannedValue: false});
        }

        if ((!this.state.updateVerified && this.state.verified) || (this.state.updateVerified && !this.state.verified)) {
            this.setState({verified: !this.state.verified, getBannedUsers: false, gotBannedUsers: false, updatedBannedValue: false});
            this.props.setGotVerifiedUsers(false);
            this.props.setUpdatedUserBanned(true);
        }

    }

    setUpdatedUserBanned = (userId, bannedValue) => {
        let bannedUsers = this.state.bannedUsers;
        let countBannedUsers = 0;

        for (let i in bannedUsers) {
            if (bannedUsers[i].id === userId) {
                bannedUsers[i].banned = bannedValue;
                break;
            }
        }

        for (let i in bannedUsers) {
            if (bannedUsers[i].banned === true) {
                countBannedUsers++;
            }
        }

        this.setState({bannedUsers: bannedUsers, countBannedUsers: countBannedUsers});
    }

    getName = (i) => {
        let user = this.props.verifiedUsers[i];
        let name = '';
        let firstName = user.name !== undefined && user.name !== null && user.name.givenName !== null && user.name.givenName !== undefined ? user.name.givenName : '';
        let lastName = user.name !== undefined && user.name !== null && user.name.familyName !== null && user.name.familyName !== undefined ? user.name.familyName : '';
        if (firstName !== '' && lastName !== '') {
            name = firstName + ' ' + lastName;
        } else if (firstName !== '' && lastName === '') {
            name = firstName;
        } else if (firstName  === '' && lastName !== '') {
            name = lastName;
        } else {
            name = 'No name';
        }

        return name;
    }

    listBannedUsers = () => {

        let result = [];
        let resultEmail = [];
        let resultPhone = [];
        let resultNeither = []; //this shouldn't occur but if does check db
        let countVerifiedUsers = 0;
        let countBannedUsers = 0;
        let countDisableDeleteUsers = 0;
        let bannedUsers = this.props.verifiedUsers;

        for (let i in bannedUsers) {
            if (bannedUsers[i].email) {
                resultEmail.push({
                    name:     this.getName(i),
                    user:     bannedUsers[i].email,
                    id:       bannedUsers[i].id,
                    banned:   bannedUsers[i].banned,
                    deleteMe: bannedUsers[i].deleteMe === null || bannedUsers[i].deleteMe === undefined || !bannedUsers[i].deleteMe ? '' : bannedUsers[i].deleteMe
                });
                countVerifiedUsers++;
                if (bannedUsers[i].banned === true) {
                    countBannedUsers++;
                }
                if (bannedUsers[i].deleteMe && bannedUsers[i].deleteMe !== '' && bannedUsers[i].deleteMe !== null && bannedUsers[i].deleteMe !== undefined) {
                    countDisableDeleteUsers++;
                }
            } else if (bannedUsers[i].phone) {
                resultPhone.push({
                    name:     this.getName(i),
                    user:     bannedUsers[i].phone,
                    id:       bannedUsers[i].id,
                    banned:   bannedUsers[i].banned,
                    deleteMe: bannedUsers[i].deleteMe === null || bannedUsers[i].deleteMe === undefined || !bannedUsers[i].deleteMe ? '' : bannedUsers[i].deleteMe
                });
                countVerifiedUsers++;
                if (bannedUsers[i].banned === true) {
                    countBannedUsers++;
                }
                if (bannedUsers[i].deleteMe && bannedUsers[i].deleteMe !== '' && bannedUsers[i].deleteMe !== null && bannedUsers[i].deleteMe !== undefined) {
                    countDisableDeleteUsers++;
                }
            }
            //} else {
            //    resultNeither.push({
            //        name:     this.getName(i),
            //        user:     bannedUsers[i].id,
            //        id:       bannedUsers[i].id,
            //        banned:   bannedUsers[i].banned,
            //        deleteMe: bannedUsers[i].deleteMe === null || bannedUsers[i].deleteMe === undefined || !bannedUsers[i].deleteMe ? '' : bannedUsers[i].deleteMe
            //    });
            //}
            //countVerifiedUsers++;
            //if (bannedUsers[i].banned === true) {
            //    countBannedUsers++;
            //}
            //if (bannedUsers[i].deleteMe && bannedUsers[i].deleteMe !== '' && bannedUsers[i].deleteMe !== null && bannedUsers[i].deleteMe !== undefined) {
            //    countDisableDeleteUsers++;
            //}
        }

        if (0 < resultEmail.length) {
            result = sortAlphabeticallyUsers(resultEmail);
        }

        if (0 < resultPhone.length) {
            resultPhone = sortAlphabeticallyUsers(resultPhone);
            for (let i in resultPhone) {
                result.push({
                    name:     resultPhone[i].name,
                    user:     resultPhone[i].user,
                    id:       resultPhone[i].id,
                    banned:   resultPhone[i].banned,
                    deleteMe: resultPhone[i].deleteMe
                });
            }
        }

        if (0 < resultNeither.length) {
            resultNeither = sortAlphabeticallyUsers(resultNeither);
            for (let i in resultNeither) {
                result.push({
                    name:     resultNeither[i].name,
                    user:     resultNeither[i].user,
                    id:       resultNeither[i].id,
                    banned:   resultNeither[i].banned,
                    deleteMe: resultNeither[i].deleteMe
                });
            }
        }

        this.setState({bannedUsers: result, countVerifiedUsers: countVerifiedUsers, countBannedUsers: countBannedUsers, countDisableDeleteUsers: countDisableDeleteUsers});
    }

    getBannedUsersList = () => {
        const bannedUsersList = this.state.bannedUsers;
        if (bannedUsersList && 0 < bannedUsersList.length) {
            return bannedUsersList;
        } else {
            return 'NoUsers';
        }
    }

    setBannedValue = (userId, bannedValue) => {
        this.props.setBannedValueDispatch(userId, bannedValue);
    }

    getBannedUsers = () => {
        let bannedUsers = this.getBannedUsersList();
        let noUsers = bannedUsers === 'NoUsers' ? true : false;
        if (!noUsers) {
            return (
                <Container className="publish-background">
                    <div className="verify-users-container stop-scroll">
                        {this.props.deleteMeValue === false ?
                            this.state.gotBannedUsers ?
                                <div>
                                    <Row className="group-element lightgrey left-banned-text">
                                        {this.state.verified ?
                                            <span className="hover" onClick={() => this.setState({updateVerified: !this.state.updateVerified})}>BANNED ACCOUNTS (Verified)
                                            </span>
                                            :
                                            <span className="hover" onClick={() => this.setState({updateVerified: !this.state.updateVerified})}>BANNED ACCOUNTS (Non-Verified)
                                            </span>
                                        }
                                    </Row>
                                    <Row className="group-element lightgrey">
                                        <Col><span>Not Banned: {this.state.countVerifiedUsers - this.state.countBannedUsers},  Banned: {this.state.countBannedUsers}</span></Col>
                                    </Row>
                                    <Row className="group-element left-banned-box-spacing">
                                        <Col className="group-list-divider letter-line color-black">
                                            <img className="divider-circle" src={greenButtonIcon}/>
                                            {this.notBannedText}
                                            <img className="divider-circle" src={redButtonIcon}/>
                                            {this.bannedText}
                                        </Col>
                                    </Row>
                                    <Row className="group-element">
                                        <Col><span className="status-italics">{this.updateStatusText}</span></Col>
                                    </Row>
                                </div>
                                :
                                null
                            :
                            <div>
                                <Row className="group-element lightgrey">
                                    <Col><span>{this.disableDeleteText}</span></Col>
                                </Row>
                                <Row className="group-element">
                                    <Col className="group-list-divider letter-line color-black">
                                        <img className="divider-circle" src={redButtonIcon}/>
                                        {this.deleteText}
                                        <img className="divider-circle" src={blueButtonIcon}/>
                                        {this.disabledText}
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div>
                    <div className="verify-users-container">
                        {this.renderBannedUsers(bannedUsers, this.props.deleteMeValue)}
                    </div>
                </Container>
            );
        }
    }

    renderBannedUsers = (bannedUsers, disableDelete) => {
        return (
            <React.Fragment>
                {Object.keys(bannedUsers).map((i) =>
                    <React.Fragment key={i}>
                        {disableDelete === false ?
                            <React.Fragment>
                                <Row className="left-value-verified">
                                    <Col className="lightgrey">
                                        {bannedUsers[i].name}
                                    </Col>
                                    <Col className='right-value-verified'>
                                        {
                                            !bannedUsers[i].banned ?
                                                <span onClick={() => this.setBannedValue(bannedUsers[i].id, !bannedUsers[i].banned)} className="circle-green hover">
                                                </span>
                                                :
                                                <span onClick={() => this.setBannedValue(bannedUsers[i].id, !bannedUsers[i].banned)} className="circle-red hover">
                                                </span>
                                        }
                                    </Col>
                                </Row>
                                <Row className="left-value-verified-secondary">
                                    <Col className="lightgrey">
                                        {bannedUsers[i].user}
                                    </Col>
                                </Row>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                {bannedUsers[i].deleteMe !== '' &&
                                    <React.Fragment>
                                        <Row className="left-value-verified">
                                            <Col className='lightgrey'>
                                                {bannedUsers[i].name}
                                            </Col>
                                            <Col className="right-value-verified">
                                                <span className={parseInt(bannedUsers[i].deleteMe) - moment().unix() < 3456000 ? 'circle-red' : 'circle-blue'}></span>
                                            </Col>
                                        </Row>
                                        <Row className="left-value-verified-line1">
                                            <Col className="lightgrey">
                                                {bannedUsers[i].user}
                                            </Col>
                                        </Row>
                                        <Row className="left-value-verified-secondary">
                                            <Col className="lightgrey">
                                                {Math.round((parseInt(bannedUsers[i].deleteMe) - moment().unix())/60) < 3456000 ? ((parseInt(bannedUsers[i].deleteMe) - moment().unix())/60/60/24).toFixed(2) + ' days remaining' : Math.round((parseInt(bannedUsers[i].deleteMe) - moment().unix())/60/60/24/365) + ' years remaining' }
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    render() {

        return (
            <Container className="container-width">
                {this.getBannedUsers()}
            </Container>
        );
    }

}

BannedUsersAdmin.propTypes = {
    user:                   PropTypes.object,
    renderHeader:           PropTypes.func,
    dispatch:               PropTypes.func,
    backToGroups:           PropTypes.func,
    setGotVerifiedUsers:    PropTypes.func,
    getVerifiedUsers:       PropTypes.func,
    gotVerifiedUsers:       PropTypes.bool,
    verifiedUsers:          PropTypes.array,
    setBannedValueDispatch: PropTypes.func,
    bannedUserId:           PropTypes.string,
    banned:                 PropTypes.bool,
    setUpdatedUserBanned:   PropTypes.func,
    updatedBannedValue:     PropTypes.bool,
    back:                   PropTypes.func,
    deleteMeValue:          PropTypes.bool

};
