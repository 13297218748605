import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { isObjectExists, getObjectValue, getCapsuleName } from '../../utils/Helpers';
import { getUrlAndLicence, getVideoThumbnail} from '../../utils/VieroUtils';
//import xIcon from '../../assets/images/x.png';

class HomeItemCarousel extends React.Component {
    static propTypes = {
        index:                                    PropTypes.number,
        socialProfiles:                           PropTypes.arrayOf(PropTypes.object),
        parentStateUpdater:                       PropTypes.func,
        slidesToShow:                             PropTypes.number,
        capsules:                                 PropTypes.array,
        user:                                     PropTypes.object,
        getTransformationClassBasedOnOrientation: PropTypes.func,
        isPreview:                                PropTypes.bool,
        startingIndex:                            PropTypes.number,
        getImageFromImageAndVideos:               PropTypes.func,
        onSelect:                                 PropTypes.func,
        group:                                    PropTypes.object,
        isGroup:                                  PropTypes.bool,
        groupName:                                PropTypes.string,
        getFavoriteButton:                        PropTypes.func,
        groupId:                                  PropTypes.string,
        getPublicProfileButton:                   PropTypes.func
    }

    static defaultProps = {
        index:          0,
        socialProfiles: [{}],
        user:           {},

    }

    constructor(props) {
        super(props);

        this.state = {
            index: this.props.isPreview ? 0 : props.index
        };

        this.thumbnailParam = '125x175,crop';

    }

    isOneItemCapsule = () => {
        let res = false;

        if (1 === this.props.capsules.length || this.props.isPreview === true) {
            res = true;
        }
        return res;
    }

    afterNavigationSlide = (index) => {
        this.setState({index: index});
        this.props.parentStateUpdater(index);
    }

    getClosestSizedImage = (images, size) => {
        let closestImage = {url: ''};
        let closestAbsoluteDistance;
        let isFirst = true;
        let i = 0;
        if (undefined !== size) {
            for (i in images) {
                if (isFirst && images[i].metadata && images[i].metadata.width) {
                    closestImage = images[i];
                    closestAbsoluteDistance = Math.abs(size - images[i].metadata.width);
                    isFirst = false;
                } else if (images[i].metadata && images[i].metadata.width) {
                    if (Math.abs(size - images[i].metadata.width) < closestAbsoluteDistance) {
                        closestImage = images[i];
                        closestAbsoluteDistance = Math.abs(size - images[i].metadata.width);
                    }
                }
            }
        }
        if (closestImage.url === '') {
            closestImage = images[0];

        }
        return closestImage.url;
    }

    getImageUrl = (imageObject, isBgUrl) => {
        let imageUrl;
        if (isBgUrl) {
            imageUrl = getUrlAndLicence(imageObject, false, this.thumbnailParam, 'VieroToolkitUtilsTypeStillSingle').url;
        } else if (isObjectExists(imageObject, 'metadata.storageType') && imageObject.metadata.storageType === 'viero') {
            //lds 12/8/2021 added check for imageObject because site started coming up then went black.
            //After researching, Keith Yokomoto capsule was most recent capsule in Most Recent and must have been re-saved in ios app because
            //mimeType was missing from the thumbnail image in db. Check with Keith and Vince.
            //8-3-2022 - ios app may have updated to fix the issue but this code prevents web from having the error
            if (imageObject && imageObject.metadata && imageObject.metadata.mimeType && imageObject.metadata.mimeType.indexOf('video/') === -1) {
                imageUrl = getUrlAndLicence(imageObject.metadata.storageUUID, false, this.thumbnailParam, 'VieroToolkitUtilsTypeStillSingle').url;
            } else {
                imageUrl = getVideoThumbnail(imageObject.metadata.storageUUID, 0, this.thumbnailParam);
            }

        } else {

            //if (isObjectExists(imageObject, 'fileId') && imageObject.fileId !== '') {
            //    imageUrl = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' +
            //        imageObject.fileId + '/w125-h175-cfill' + '?token=' + this.props.user.token;

            if (imageObject.backgroundImageURL && imageObject.backgroundImageURL !== undefined && imageObject.backgroundImageURL !== '') {
                imageUrl = imageObject.backgroundImageURL;
            } else {
                imageUrl = imageObject.url;
            }

        }

        return imageUrl;
    }

    getCarouselImage = (capsule) => {
        let background = '';
        if (isObjectExists(capsule, 'items.contact.image.profile.fileId') && capsule.items.contact.image.profile.fileId !== '') {
            let imageUrl = this.getImageUrl(capsule.items.contact.image.profile);

            background = {
                backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0) 38%,rgba(0,0,0,0.9) 100%), url("' + imageUrl + '")',

                backgroundRepeat:   'no-repeat',
                backgroundSize:     'auto 100%',
                backgroundPosition: 'center',
            };

            return (

                <div className="home-navigation">
                    <div className={'home-img'} style={background} >
                        {this.props.getFavoriteButton(capsule, true)}
                        {this.props.getPublicProfileButton(capsule)}
                    </div>
                    <div className="text-center px-3 capsule-name"><span>{getCapsuleName(capsule)}</span></div>
                </div>

            );
            //lds - check this later - either attila or I added. Possibly it's no longer needed with latest addition of background feature but verify.
            //} else if (isObjectExists(capsule, 'items.contact.image.profile.url') && isObjectExists(capsule, 'items.contact.image.profile.backgroundImageURL') && capsule.items.contact.image.profile.url !== '' && capsule.items.contact.image.profile.backgroundImageURL !== '') {
            //let imageUrl = this.getImageUrl(capsule.items.contact.image.profile.backgroundImageURL, true);
            //background = {
            //    backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0) 38%,rgba(0,0,0,0.9) 100%), url("' + imageUrl + '")',

            //    backgroundRepeat:   'no-repeat',
            //    backgroundSize:     'auto 100%',
            //    backgroundPosition: 'center',
            //};

            //return (

            //    <div className="home-navigation">
            //        <div className={'home-img'} style={background} >
            //            {this.props.getFavoriteButton(capsule, true)}
            //            {this.props.getPublicProfileButton(capsule)}
            //        </div>
            //        <div  className="text-center px-3 capsule-name"><span>{getCapsuleName(capsule)}</span></div>
            //    </div>

            //);

        } else if (isObjectExists(capsule, 'items.contact.image.profile.url') && capsule.items.contact.image.profile.url !== ''
          && isObjectExists(capsule, 'items.contact.image.profile.metadata.mimeType') && capsule.items.contact.image.profile.metadata.mimeType !== 'text/html') {

            let imageUrl = this.getImageUrl(capsule.items.contact.image.profile);

            background = {
                backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0) 38%,rgba(0,0,0,0.9) 100%), url("' + imageUrl + '")',

                backgroundRepeat:   'no-repeat',
                backgroundSize:     'auto 100%',
                backgroundPosition: 'center',
            };

            return (

                <div className="home-navigation">
                    <div className={'home-img'} style={background} >
                        {this.props.getFavoriteButton(capsule, true)}
                        {this.props.getPublicProfileButton(capsule)}
                    </div>
                    <div className="text-center px-3 capsule-name"><span>{getCapsuleName(capsule)}</span></div>
                </div>

            );

        } else if (isObjectExists(capsule, 'items.contact.image.profile.backgroundImageURL') && capsule.items.contact.image.profile.backgroundImageURL !== '') {

            let imageUrl = this.getImageUrl(capsule.items.contact.image.profile);

            background = {
                backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0) 38%,rgba(0,0,0,0.9) 100%), url("' + imageUrl + '")',

                backgroundRepeat:   'no-repeat',
                backgroundSize:     'auto 100%',
                backgroundPosition: 'center',
            };

            return (

                <div className="home-navigation">
                    <div className={'home-img'} style={background} >
                        {this.props.getFavoriteButton(capsule, true)}
                        {this.props.getPublicProfileButton(capsule)}
                    </div>
                    <div className="text-center px-3 capsule-name"><span>{getCapsuleName(capsule)}</span></div>
                </div>

            );

        } else if (isObjectExists(capsule, 'items.contact.image.thumbnail.fileId') || isObjectExists(capsule, 'items.contact.image.thumbnail.url')) {
            let imageUrl = this.getImageUrl(capsule.items.contact.image.thumbnail);

            background = {
                backgroundImage: 'url("' + imageUrl + '")',

                backgroundRepeat:   'no-repeat',
                backgroundSize:     'auto 100%',
                backgroundPosition: 'center',
            };

            return (

                <div className="home-navigation">
                    <div className={'home-img'} style={background} >
                        {this.props.getFavoriteButton(capsule, true)}
                        {this.props.getPublicProfileButton(capsule)}
                    </div>
                    <div className="text-center px-3 capsule-name"><span>{getCapsuleName(capsule)}</span></div>
                </div>

            );

        } else if (isObjectExists(capsule, 'items.image_and_video.images') && getObjectValue(capsule, 'items.image_and_video.images').length > 0) {
            let element = document.getElementById('navigation-active-option') || {width: 225, height: 275 };
            let imageUrl = this.props.getImageFromImageAndVideos(capsule, element);

            background = {
                backgroundImage: 'url("' + imageUrl + '")',

                backgroundRepeat:   'no-repeat',
                backgroundSize:     'auto 100%',
                backgroundPosition: 'center',
            };

            return (
                <div className="home-navigation">
                    {/*<img id="" className="x-icon" src={xIcon} />*/}
                    <div className={'home-img'} style={background} >
                        {this.props.getFavoriteButton(capsule, true)}
                        {this.props.getPublicProfileButton(capsule)}
                    </div>
                    <div className="text-center px-3 capsule-name"><span>{getCapsuleName(capsule)}</span></div>
                </div>
            );

        } else {
            return (
                <div className="home-navigation">
                    {/*<img id="" className="x-icon" src={xIcon} />*/}
                    <div className="home-img no-pic">
                        {this.props.getFavoriteButton(capsule, true)}
                        {this.props.getPublicProfileButton(capsule)}
                    </div>
                    <div  className="text-center px-3 capsule-name"><span>{getCapsuleName(capsule)}</span></div>
                </div>);
        }
    }

    addPlaceHolderSlide = () => {
        if (this.props.isPreview) {
            return <div></div>;
        } else {
            return null;
        }
    }

    getInitialSlide = () => {
        let slide;
        if (this.isOneItemCapsule()) {
            slide = 1;
        } else if (this.props.startingIndex) {
            slide = this.props.startingIndex;
        } else {
            slide = this.props.index;
        }

        return slide;
    }

    onClick = (i) => {
        if (this.props.group) {
            this.props.onSelect(i, this.props.capsules, this.props.groupName, this.props.group.id);
        } else {
            this.props.onSelect(i, this.props.capsules, this.props.groupName);
        }

    }

    render() {
        let settings = {
            arrows:         true,
            autoplay:       false,
            dots:           false,
            infinite:       false,
            draggable:      true,
            swipeToSlide:   true,
            //lazyLoad:      'onDemand',
            initialSlide:   0,
            slidesToShow:   7,
            slidesToScroll: 7,
            responsive:     [
                {
                    breakpoint: 1024,
                    settings:   {
                        slidesToShow:   4,
                        slidesToScroll: 4,
                        initialSlide:   0,
                        speed:          25

                    }
                },
                {
                    breakpoint: 600,
                    settings:   {
                        slidesToShow:   3,
                        slidesToScroll: 1,
                        initialSlide:   0,
                        speed:          25
                    }
                },
                {
                    breakpoint: 480,
                    settings:   {
                        slidesToShow:   3.5,
                        slidesToScroll: 1,
                        initialSlide:   0,
                        speed:          25
                    }
                }
            ]

        };
        return (
            <div className="home-navigation-wrapper">
                <Slider {...settings} ref={(ref) => this.slider = ref}>
                    {   //Map the navigation options in the order provided by backend

                        Object.keys(this.props.capsules).map((i) => (
                            <React.Fragment key={i}>
                                <div onClick={() => this.onClick(i)}>
                                    {this.getCarouselImage(this.props.capsules[i])
                                    }
                                </div>
                            </React.Fragment>
                        ))

                    }
                </Slider>
            </div>

        );
    }
}

export default HomeItemCarousel;
