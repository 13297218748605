import React from 'react';
import PropTypes from 'prop-types';
import './VideoPlayer.css';
import shaka from 'shaka-player';
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
//import enableInlineVideo from 'iphone-inline-video';

export default class VideoPlayer extends React.Component {
    static defaultProps = {
        parentStateUpdater: function() {}
    };

    constructor (props) {
        super(props);
        this.state = {
            listenerSet: false,
            videoReady:  this.props.isViero ? false : true,
            justMounted: true,
            handlerSet:  false
        };
        this.pleaseWaitTextOne = 'Please wait while we are processing your video';
        this.pleaseWaitTextTwo = 'Video will start when done.';

        this.muted = false;
    }

    componentDidMount() {
        let isIphone = /iPad|iPhone|iPod/.test(navigator.platform)
        || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        if (this.props.isViero === true && (!this.props.status || this.props.status[this.props.id] !== 'ready')) {
            this.props.listenStatus(this.props.id);
            /*var video = document.querySelector('video');
            enableInlineVideo(video);
            */
            this.setState({listenerSet: true});
        } else {
        // Install built-in polyfills to patch browser incompatibilities.

            // Check to see if the browser supports the basic APIs Shaka needs.
            if (shaka.Player.isBrowserSupported() && !isIphone) {
            // Everything looks good!
                this.initPlayer();
            } else {
            // This browser does not have the minimum set of APIs we need.
            // eslint-disable-next-line no-console
                console.error('Browser not supported!');
            }

            this.setState({videoReady: true, justMounted: false});
        }

    }

    componentDidUpdate()  {

        let isIphone = /iPad|iPhone|iPod/.test(navigator.platform)
        || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        if (this.props.isViero === true && this.props.status && this.props.status[this.props.id] && this.state.justMounted === true) {

            if (this.props.status[this.props.id] === 'ready') {
                this.state.listenerSet === true ? this.props.listenStatus(this.props.id, true) : null;
                if (!this.player && !isIphone) {
                    this.initPlayer();
                }

                this.setState({listenerSet: false, videoReady: true, justMounted: false});

            }
            this.setState({justMounted: false});
        }

        if (this.props.isViero === true && (!this.props.status || this.props.status[this.props.id] !== 'ready') && this.state.listenerSet === false) {

            this.props.listenStatus(this.props.id);
            this.setState({listenerSet: true});

        }

        if (this.props.isViero === true && this.props.status && this.props.status[this.props.id] === 'ready' && this.state.videoReady === false) {

            this.props.listenStatus(this.props.id, true);
            if (!this.player && !isIphone) {
                this.initPlayer();
            }

            this.setState({listenerSet: false, videoReady: true, justMounted: false});

        }

        if (this.videoNode && !this.state.handlerSet) {
            //this.videoNode.addEventListener('error', this.onErrorEvent);
            this.setState({handlerSet: true});
        }

    }

    /*onErrorEvent = (event) => {
        // Extract the shaka.util.Error object from the event.
        this.onError(event.detail);
    }

    onError = (error) => {
        // Log the error.
        console.error('Error code', error.code, 'object', error);
    }*/

    initPlayer = () => {
        //shaka.polyfill.installAll();
        this.player = new shaka.Player(this.videoNode);
        var props = this.props;
        // Listen for error events.

        window.document.getElementsByTagName('video')[0].addEventListener('fullscreenchange', function() {
            if (!document.fullscreenElement) {
                this.props.parentStateUpdater({isFullscreen: false, isMuted: this.muted});
                this.setState({counter: Math.random()});
            }
        }.bind(this));

        window.document.getElementsByTagName('video')[0].addEventListener('webkitpresentationmodechanged', function() {
            let muteState = this.muted;
            if (!this.webkitDisplayingFullscreen) {
                setTimeout(function() {
                    this.muted = muteState;
                    this.props.parentStateUpdater({isFullscreen: false, isMuted: muteState, iosExited: true});
                    this.setState({counter: Math.random()});
                }.bind(this), 600);
            }
        }.bind(this));

        if (this.props.keySystems) {
            this.player.configure({ drm: { servers: this.props.keySystems} });
        }

        this.player.load(this.props.url).then(function () {
            // eslint-disable-next-line no-console
            console.log('load');
            if (-1 === props.videoClass.indexOf('video-background') && -1 === props.videoClass.indexOf('profile-video')) {
                this.videoNode.requestFullscreen().catch(err => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                });
                //for iphones?
                this.videoNode.webkitEnterFullscreen();
            }
        // eslint-disable-next-line no-console
        }.bind(this)).catch(e => {console.error(e);});

        //this.player = shaka.Player;
        // Try to load a manifest.
        // This is an asynchronous process.
    }

    // destroy player on unmount
    componentWillUnmount() {
        if (this.props.listenStatus) {
            this.props.listenStatus(this.props.id, true);
        }
        this.setState({videoReady: false});
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render() {
        const override = css`
        display: absolute;
        text-align: center
        margin: 0 auto;
        border-color: blue;
    `;
        let isIphone = /iPad|iPhone|iPod/.test(navigator.platform)
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
        return (
            <div className="my-auto">
                {this.props.isViero && !this.state.videoReady && this.state.justMounted &&
                <div className='spinner'>
                    <ClipLoader
                        css={override}
                        sizeUnit={'px'}
                        size={75}
                        color={'#123abc'}
                        loading={true}
                    />
                </div>
                }
                <div className={this.props.isViero && !this.state.videoReady && !this.state.justMounted ? 'text-center text-secondary' : 'hidden'}>{this.pleaseWaitTextOne}</div>
                <div className={this.props.isViero && !this.state.videoReady && !this.state.justMounted ? 'text-center text-secondary' : 'hidden'}>{this.pleaseWaitTextTwo}</div>
                <div className={this.props.isViero && !this.state.videoReady ? 'hidden' : ''}>
                    {-1 === this.props.videoClass.indexOf('video-background') && -1 === this.props.videoClass.indexOf('profile-video') && <div className="close-video" onClick={this.props.toggle}>X</div>}
                    <video id={this.props.videoClass}
                        muted={this.props.isMuted}
                        playsInline
                        poster={this.props.poster}
                        preload="metadata"
                        loop={-1 === this.props.videoClass.indexOf('video-background') && -1 === this.props.videoClass.indexOf('profile-video') && this.props.videoClass !== '' ? false : true}
                        controls={(-1 === this.props.videoClass.indexOf('video-background') && -1 === this.props.videoClass.indexOf('profile-video')) || this.props.showControls === true ? true : false}
                        ref={ node => this.videoNode = node }
                        className={'hover video-plyr ' + this.props.videoClass}
                        autoPlay={true}
                    >
                        {isIphone && <source src={this.props.url} type="application/x-mpegURL" />}

                    </video>
                </div>
            </div>
        );
    }

}

VideoPlayer.propTypes = {
    dashOne:            PropTypes.bool,
    dashTwo:            PropTypes.bool,
    showControls:       PropTypes.bool,
    hls:                PropTypes.bool,
    keySystems:         PropTypes.object,
    url:                PropTypes.string,
    videoClass:         PropTypes.string,
    type:               PropTypes.string,
    appleTech:          PropTypes.bool,
    status:             PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    id:                 PropTypes.string,
    listenStatus:       PropTypes.func,
    isViero:            PropTypes.bool,
    toggle:             PropTypes.func,
    isMuted:            PropTypes.bool,
    parentStateUpdater: PropTypes.func,
    autoPlay:           PropTypes.bool,
    poster:             PropTypes.string
};
