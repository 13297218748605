import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import cameraIcon from '../../assets/images/CardPhoto@2x.png';
import webLinkIcon from '../../assets/images/WebLinkSet@2x.png';
import {ModalView} from './ModalView';
import { getUrlAndLicence} from '../../utils/VieroUtils';
import { isObjectExists } from '../../utils/Helpers';
import VideoPlayer  from '../video/VideoPlayer';
import { PhotoEditorSDK } from '../../utils/PhotoEditorSDK';
//import { userActions } from '../redux/actions';

export class CapsuleEdit extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            name:                    '',
            category:                '',
            profilePicture:          '',
            capsulePictureToPreview: '',
            capsulePicture:          '',
            isEdit:                  true,
            isPhoto:                 true,
            isSectionOpen:           true,
            showNoPublicModal:       false,
            imageToUpload:           {},
            showEditor:              false,
            getNewMainCapsule:       false,
            bypassPhotoEditor:       true,
            everUploadImage:         false

        };

        this.inputCapsulePicRef = React.createRef();
        this.thumbnailParam = '300x300';
        this.uploadingText = 'Uploading ';
        this.processingText = 'Processing ';
        this.removeUrlText = 'Remove Website URL';
        this.removeImageText = 'Remove Image';
        this.removeVideoText = 'Remove Video';
        this.removeURLImageText = 'Remove Website Cover Image';
        this.removeBackgroundURLImageText = 'Remove Cover Image URL';
        this.websiteSetAsCapsuleImage = 'Capsule Website URL';
        this.websiteBackgroundImageURLText = 'Website Cover Image URL';
    }

    componentDidUpdate() {

        if (this.props.user.mainSet && !this.state.getNewMainCapsule && !this.props.isProfile) {
            this.props.getMainCapsule();
            this.setState({getNewMainCapsule: true});
        }

        if (!this.props.user.mainSet && this.state.getNewMainCapsule) {
            this.setState({getNewMainCapsule: false});
        }

        if ((this.props.thumbnail.type === 'url') && this.state.isPhoto === true) {
            this.setState({isPhoto: false});
        }

        if (this.props.thumbnail.type === 'file' && this.state.isPhoto === false) {
            this.setState({isPhoto: true});
        }

        /* if (this.state.imageToUpload !== '' && prevState.imageToUpload === '') {
            this.setState({showEditor: true});
        }*/
    }

    onChange = (e) => {
        this.props.onCapsuleChange(e, 'capsule');
    }

    onUrlChange = (value) => {
        let url = value;
        if (!this.checkUrl(url)) {

            this.setState({isPhoto: true});
            this.props.setIsPhoto2(false);
            this.props.setIsPhoto3(false);

            this.props.removeUserImages('profile');
            this.openModal();
        } else {
            this.props.onCapsuleChange(url, 'profileUrl');
        }

    }

    onBackgroundUrlChange = (value) => {
        let url = value;
        if (!this.checkUrl(url) || this.props.isFileImage(url).mimeType === undefined) {
            this.props.setIsPhoto3(false);  //in case previous url link was set

            //assumes if user adds non-valid background URL, keep previously uploaded image if exists for usability.
            //this.props.removeUserImages('profile');
        } else {
            this.props.removeUserImages('profile', 'urlImage'); //remove uploaded image if any, also changes isPhoto2:false
            this.props.onCapsuleChange(url, 'profileBackgroundUrl'); //changes isPhoto3 to true
        }
        this.openModal();
    }

    checkUrl = (value) => {
        let url = value.toLowerCase();
        let result = true;
        if (url === '' || url === 'h' || url === 'ht' || url === 'htt' || url === 'http'
          || url === 'https' || url === 'https:' || url === 'https:/' || url === 'https://') {
            result = false;
        }
        return result;
    }

    openCapsulePicDialog = () => {
        this.openModal();
        this.inputCapsulePicRef.current.click();
        if (!this.state.everUploadImage) {
            this.setState({everUploadImage: true});  //everUploadImage stop capsule image in edit from bouncing while saved capsule image has not changed.
        }
    }

    openModal = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    toggleMenu = (menu) => {
        this.setState({[menu]: !this.state[menu]});
    }

    onUploadImage = (e) => {
        if (!this.state.bypassPhotoEditor) {
            let fileType = e.target.files[0].type;
            if (fileType.indexOf('video') === -1 && fileType.indexOf('heic') === -1 && fileType.indexOf('heif') === -1) {
                let fileAsImage = this.getFileAsImage(e);
                let imageToUpload = {image: fileAsImage, evt: e};
                this.setState({imageToUpload, showEditor: true});
            } else { //lds decide if can photoedit a urlImage, if yes then need to change lines 281 to allow/set this type
                this.props.uploadPicture(e, 'profile');
            }
        } else {
            this.props.uploadPicture(e, 'profile');
        }
    }

    getFileAsImage = (evt) => {
        var tgt = evt.target,
            files = tgt.files;
        let image = new Image();
        // FileReader support
        if (FileReader && files && files.length) {
            var fr = new FileReader();

            fr.onload = function () {
                image.src = fr.result;
            };
            fr.readAsDataURL(files[0]);
            return image;
        }

        // Not supported
        else {
        // fallback -- perhaps submit the input to an iframe and temporarily store
        // them on the server until the user's session ends.
        }
    }

    removeProfile = (typeOfWebsiteBackground) => {
        if (!this.state.isPhoto && typeOfWebsiteBackground && (typeOfWebsiteBackground === undefined || typeOfWebsiteBackground === null)) {
            this.setState({isPhoto: true});
        }
        this.props.removeUserImages('profile', typeOfWebsiteBackground);
    }

    getVideoObject = (metadata, url, preview) => {
        let src, keySystems, isViero, previewUrl;
        if (metadata.storageType === 'viero') {
            let vieroObject = getUrlAndLicence(metadata.storageUUID, false, '', 'VieroToolkitUtilsTypeMotion');
            previewUrl = vieroObject ? getUrlAndLicence(metadata.storageUUID, true, '', 'VieroToolkitUtilsTypeMotion').url : '';
            src = vieroObject.url;
            keySystems = vieroObject.keySystems;
            isViero = true;
        } else {
            src = url;
        }
        return isViero && preview ?
            <img className="media-images hover" src={previewUrl} />
            :
            <VideoPlayer url={src} isViero={isViero} keySystems={keySystems} videoClass="profile-video hover" type={isViero ? null : metadata.mimeType} ref={(ref) => this.videoPlayer = ref}/>;
    }

    getVieroUrl = (metadata) => {
        let url;
        if (metadata.storageType === 'viero') {
            url = getUrlAndLicence(metadata.storageUUID, false, this.thumbnailParam, 'VieroToolkitUtilsTypeStillSingle').url;
        }

        return url;
    }

    getPreview = (isPoster) => {
        let preview;
        let tempStorageUUID = this.props.metadata && this.props.metadata.storageUUID ? this.props.metadata.storageUUID : '';
        //console.log('getPreview functiom storageUUID=', tempStorageUUID);

        // lds - keep for viero side debugging for image issue after multiple uploads of same image
        //if (isObjectExists(this.props, 'user.fireBaseStatus.' + tempStorageUUID)) {
        //    console.log('viero status =', this.props.user.fireBaseStatus[tempStorageUUID]); //of received responses, this is a myu array
        //    console.log('and array=', this.props.user.fireBaseStatus);
        //} else if (this.props.user.fireBaseStatus) {
        //    console.log('viero status = no storageUUID assigned, array =', this.props.user.fireBaseStatus);
        //} else {
        //    console.log('viero status = no fireBaseUserStatus Array exists');
        //}

        this.props.checkIfUploading();
        // call this.props.getStatusOfMedia(this.props.metadata.storageUUID, 'capsuleMedia') to set flag for uploading, not sure what it's used for
        if (isObjectExists(this.props, 'user.fireBaseStatus.' + tempStorageUUID) && this.props.user.fireBaseStatus[tempStorageUUID] !== 'ready') {
            //status = this.props.user.fireBaseStatus && this.props.metadata && this.props.metadata.storageUUID && this.props.user.fireBaseStatus[this.props.metadata.storageUUID] === 'uploading' ? this.uploadingText : this.props.user.fireBaseStatus[this.props.metadata.storageUUID] === 'processing'? this.processingText : this.props.user.fireBaseStatus[this.props.metadata.storageUUID] === 'enqueued' ? 'enqueued' : 'undefined';
            let status = this.props.user.fireBaseStatus[tempStorageUUID];
            preview = (<div>{status}...</div>);
        } else if (this.props.metadata && this.props.metadata.mimeType && -1 < this.props.metadata.mimeType.indexOf('video/')) {
            preview = (
                isPoster ?
                    <div onClick={() => this.openModal()}>
                        {this.getVideoObject(this.props.metadata, this.props.thumbnail.url)}
                    </div>
                    :
                    <div onClick={() => this.openModal()}>
                        {this.getVideoObject(this.props.metadata, this.props.thumbnail.url, true)}
                    </div>
            );
        } else if (isObjectExists(this.props, 'user.fireBaseStatus.' + tempStorageUUID) && this.props.user.fireBaseStatus[tempStorageUUID] === 'ready') {
            // this is viero only so if need S3 profile image then need to rewrite this section. changed logic to viero only except for display options below

            //console.log('viero status, in ready, display image');
            preview = (
                <img id="capsule-image" className={this.props.thumbnail !== '' && (this.state.isPhoto || this.props.isPhoto2) ? this.props.isProfile ? 'hover profile-section-capsule-img radius img-fluid' : 'hover capsule-edit-photo radius img-fluid' : 'hover capsule-photo-icon'} onClick={() => this.openModal()} src={
                    this.props.thumbnail !== '' && (this.state.isPhoto || this.props.isPhoto2) ?
                        this.props.metadata && this.props.metadata.storageType === 'viero' ?
                            this.getVieroUrl(this.props.metadata)

                            :
                            this.props.thumbnail.url
                        :
                        this.props.thumbnail.type === 'url' ?
                            webLinkIcon
                            :
                            cameraIcon} />
            );
            //console.log('viero status, url=', this.getVieroUrl(this.props.metadata));
        } else if (!(isObjectExists(this.props, 'metadata.storageType')) || (isObjectExists(this.props, 'metadata.storageType') && this.props.metadata.storageType === '')) {
            //console.log('thumbnail=', this.props.thumbnail.type);
            preview = (
                <img id="capsule-image" className='hover capsule-photo-icon' onClick={() => this.openModal()} src={
                    this.props.thumbnail.type === 'url' ?
                        webLinkIcon
                        :
                        cameraIcon} />
            );
        } else if (!this.state.everUploadImage) {
            preview = (
                <img id="capsule-image" className={this.props.thumbnail !== '' && (this.state.isPhoto || this.props.isPhoto2) ? this.props.isProfile ? 'hover profile-section-capsule-img radius img-fluid' : 'hover capsule-edit-photo radius img-fluid' : 'hover capsule-photo-icon'} onClick={() => this.openModal()} src={
                    this.props.metadata && this.props.metadata.storageType === 'viero' ?
                        this.getVieroUrl(this.props.metadata)
                        :
                        this.props.thumbnail.url} />
            );
        }

        return preview;
    }

    getDefaultUrl = () => {
        return this.props.thumbnail && this.props.thumbnail.type && this.props.thumbnail.url && this.props.thumbnail.type === 'url' && this.props.thumbnail.url !== '' ?  this.props.thumbnail.url : 'https://';
    }

    getDefaultUrlBackground = () => {
        return this.props.thumbnail && this.props.thumbnail.backgroundType && this.props.thumbnail.backgroundType === 'backgroundUrl' && this.props.thumbnail.backgroundUrl !== '' ?  this.props.thumbnail.backgroundUrl : 'https://';
    }

    setEditor = (value) => {
        this.setState({showEditor: value});
    }

    render() {

        return (

            <Container className="mt-10" >
                <ModalView isOpen={this.state.isOpen}
                    toggle={() => this.openModal()}
                    onUpload={() =>this.openCapsulePicDialog()}
                    onContinue={this.onUrlChange}
                    onBackgroundUrlChange={this.onBackgroundUrlChange}
                    defaultValue={this.getDefaultUrl()}
                    defaultValueBackground={this.getDefaultUrlBackground()}
                    setIsBackgroundUrlImage={this.props.setIsBackgroundUrlImage}
                    isFileImage={this.props.isFileImage}
                    title="Capsule Background"
                    modalType="mediaProfile"
                />

                { this.state.showEditor && !this.state.bypassPhotoEditor &&
                            <Row><PhotoEditorSDK
                                imageUrl={this.state.imageToUpload.image} type={'profile'}
                                uploadImage={this.props.uploadPicture}
                                setEditor={() => this.setEditor()}
                            ></PhotoEditorSDK></Row>
                }
                {!this.props.isProfile && !this.props.isNewCapsule &&
                <Row className="text-center">
                    <Col className="pb-3">
                        <div>
                            {this.props.isMain ?
                                <div>This is your main capsule now</div>
                                :
                                <div onClick={() => this.props.setMainCapsule()} className="navigation-button">Click to make this your main capsule</div>
                            }
                        </div>
                    </Col>
                </Row>
                }
                <Row className="text-center">
                    <Col className="m-auto">
                        <div className="text-center">
                            {this.getPreview()}
                            <input ref={this.inputCapsulePicRef} accept=".jpg, .jpeg, .png, .mov, .mp4, .avi, .heic"  onChange={(e) => this.onUploadImage(e)} type="file" style={{display: 'none'}}/>
                            <Row></Row>
                            {this.props.thumbnail && <div onClick={() => {this.removeProfile();}} className="navigation-button mt-1 input-row-title1">
                                {this.state.isPhoto && !this.props.isPhoto2 && !this.props.isPhoto3 ? this.props.metadata && this.props.metadata.mimeType && -1 < this.props.metadata.mimeType.indexOf('video/') ? this.removeVideoText: this.removeImageText : !this.state.isPhoto || this.props.isPhoto2 || this.props.isPhoto3 ? this.removeUrlText : null}
                            </div>}
                            {this.props.thumbnail && <div onClick={() => this.removeProfile(this.props.isPhoto2 ? 'urlImage' : this.props.isPhoto3 ? 'urlBackgroundImage' : null)} className="navigation-button mb-10 input-row-title1">
                                {this.props.isPhoto2 ? this.removeURLImageText : this.props.isPhoto3 ? this.removeBackgroundURLImageText : null}
                            </div>}
                            {/*{this.props.thumbnail && this.props.isProfile && <div onClick={() => this.openModal()} className="navigation-button mb-10">
                                Change Capsule Image
                            </div>}*/}
                        </div>
                    </Col>
                </Row>

                {/*}<Row className="input-row">*/}
                <Row>
                    <Col lg="2" md="2" sm="2"></Col>
                    <Col lg="6" md="6" sm="6" className="m-auto">
                        <input
                            value={this.props.newCapsuleData.name}
                            onChange={(e) => this.onChange(e)}
                            name="name"
                            id={this.props.isCreate ? 'capName' : 'name'}
                            className="capsule-input"
                            placeholder='Add Capsule Name'
                        />
                    </Col>
                    <Col lg="2" md="2" sm="2"></Col>
                </Row>
                <Row>
                    <Col lg="2" md="2" sm="2"></Col>
                    {/*}{this.props.isProfile || this.props.isMain || this.props.user.mainSet ?*/}
                    {/* }{this.props.mainCapsuleID === this.props.newCapsuleData.id ?*/}
                    {/* }{this.isThisMainCapsule() ?  */}
                    {this.props.isMain || this.props.isProfile ?
                        <Col lg="6" md="6" sm="6" className="m-auto input-row-title">Main Capsule Name</Col>
                        :
                        <Col lg="6" md="6" sm="6" className="m-auto input-row-title">Capsule Name</Col>
                    }
                    <Col lg="2" md="2" sm="2"></Col>
                </Row>
                {/*{((!this.state.isPhoto || this.props.isPhoto2 || this.props.isPhoto3) && this.props.thumbnail && !this.props.isProfile) isProfile=Profile Setting Section&& */}
                {((!this.state.isPhoto || this.props.isPhoto2 || this.props.isPhoto3) && this.props.thumbnail) &&
                    <React.Fragment>
                        {/* Add logic to add line to show background image URL??? then need isPhoto3 */}
                        {/*<Row className="input-row">*/}
                        <Row>
                            <Col lg="2" md="2" sm="2"></Col>
                            <Col lg="6" md="6" sm="6" className="m-auto">
                                <span>
                                    <input
                                        value={this.props.thumbnail.url}
                                        name="category"
                                        id="category"
                                        readOnly
                                        className="capsule-input"

                                    />
                                </span>
                            </Col>
                            <Col lg="2" md="2" sm="2"></Col>
                        </Row>
                        <Row>
                            <Col lg="2" md="2" sm="2"></Col>
                            <Col lg="6" md="6" sm="6" className="m-auto input-row-title">{this.websiteSetAsCapsuleImage}</Col>
                            <Col lg="2" md="2" sm="2"></Col>
                        </Row>
                        {this.props.isPhoto3 &&
                        <React.Fragment>
                            <Row>
                                <Col lg="2" md="2" sm="2"></Col>
                                <Col lg="6" md="6" sm="6" className="m-auto">
                                    <span>
                                        <input
                                            value={this.props.thumbnail.backgroundUrl}
                                            name="category"
                                            id="category"
                                            readOnly
                                            className="capsule-input"
                                        />
                                    </span>
                                </Col>
                                <Col lg="2" md="2" sm="2"></Col>
                            </Row>
                            <Row>
                                <Col lg="2" md="2" sm="2"></Col>
                                <Col lg="6" md="6" sm="6" className="m-auto input-row-title">{this.websiteBackgroundImageURLText}</Col>
                                <Col lg="2" md="2" sm="2"></Col>
                            </Row>
                        </React.Fragment>
                        }
                    </React.Fragment>
                }

            </Container>
        );
    }
}

CapsuleEdit.propTypes = {
    dispatch:                PropTypes.func,
    user:                    PropTypes.object,
    onCapsuleChange:         PropTypes.func,
    newCapsuleData:          PropTypes.object,
    thumbnail:               PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    uploadPicture:           PropTypes.func,
    metadata:                PropTypes.object,
    toggleMenu:              PropTypes.func,
    isSectionOpen:           PropTypes.bool,
    //getStatusOfMedia:      PropTypes.func,
    setCheckingProgressOf:   PropTypes.func,
    checkingProgressOf:      PropTypes.string,
    removeUserImages:        PropTypes.func,
    isCreate:                PropTypes.bool,
    showEditor:              PropTypes.bool,
    isProfile:               PropTypes.bool,
    isMain:                  PropTypes.bool,
    setMainCapsule:          PropTypes.func,
    mainCapsule:             PropTypes.object,
    getMainCapsule:          PropTypes.func,
    isNewCapsule:            PropTypes.bool,
    checkIfUploading:        PropTypes.func,
    setIsBackgroundUrlImage: PropTypes.func,
    isFileImage:             PropTypes.func,
    isPhoto2:                PropTypes.bool,
    isPhoto3:                PropTypes.bool,
    setIsPhoto2:             PropTypes.func,
    setIsPhoto3:             PropTypes.func
};
