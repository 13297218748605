import React from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from '../video/VideoPlayer';
import { getUrlAndLicence, getVideoThumbnail } from '../../utils/VieroUtils';
import { configActions } from '../redux/actions';
import { Row, Col} from 'reactstrap';
//import { ClipLoader } from 'react-spinners';
//import FlipPage from 'react-flip-page';
import backButton from '../../assets/images/Close@2x.png';
import backButtonTransparent from '../../assets/images/Close@2xTransparent.png';
import './Wrap.css';
import Iframe from 'react-iframe';
import { css } from '@emotion/core';
import { isObjectExists } from '../../utils/Helpers';
import mutedIcon from '../../assets/images/MutedIcon.png';
import unmutedIcon from '../../assets/images/UnmutedIcon.png';
import languageIcon from '../../assets/images/languageIcon3.png';
import switchIcon from '../../assets/images/SwitchIcon.svg';
import { Helmet } from 'react-helmet';

// If dual language is enabled, field1, field2 and field3 must be set to manage page length differences
// as well as setting field4: true and can set field5: 'languageicon' or 'switchicon' (default is 'switchicon')

export class Wrapfull extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            pages:             [],
            gotoPageNumber:    0,
            currentPage:       0,
            rotateValue:       0,
            difference:        0,
            direction:         0,
            initialDirection:  2,
            mousePos:          0,
            isWebpage:         false,
            height:            '500',
            listeners:         false,
            scrollValue:       0,
            isMuted:           true,
            isEnglish:         true,
            isVideoFullscreen: false,
            ready:             false,
            pagesReady:        false,
            videoReady:        false,
            languageChanged:   true,
            maxWidth:          0,
            imagesMapped:      false,
            productInited:     false,
            scriptReady:       false,
            firstLoad:         true
        };

        this.mousePos = {x: 0, y: 0};
        this.override = css`s
        display: absolute;
        text-align: center
        margin: 0 auto;
        border-color: red;
    `;
    }

    componentDidMount() {

        let wrapNameInfo = window.location.href.toLowerCase().split('album/');
        let wrapName;

        if (wrapNameInfo.length === 2 && !this.props.isFromTemplate) {
            if (-1 === wrapNameInfo[1].indexOf('/')) {
                wrapName = wrapNameInfo[1].toLowerCase();
            } else if (wrapNameInfo[1].length - 1 === wrapNameInfo[1].indexOf('/')) {
                wrapName = wrapNameInfo[1].split('/')[0].toLowerCase();
            }
            this.props.dispatch(configActions.getWrapConfig(wrapName));
        } else if (!this.props.isFromTemplate) {
            this.props.navigateTo('explore');
        }

        if (!this.props.isFromTemplate && isObjectExists(this.props, 'config.wrap') && this.props.config.wrap.length === 0) {
            this.props.navigateTo('explore');
        }  // Later add logic to check the wrap content if length > 0 and send to explore if data is empty

        document.documentElement.style.position = 'fixed';
        document.documentElement.style.overflow = 'hidden';

        let checkForViero = setInterval(() => {
            if (this.props.isFromTemplate || (window.VieroToolkitUtils && isObjectExists(this.props, 'config.wrap') && this.props.config.wrap.length > 0)) {
                this.setState({ready: true});
                clearInterval(checkForViero);
            }
        }, 500);

        let checkIfVideoReady = setInterval(() => {
            if (document.getElementsByTagName('video')[0] && document.getElementsByTagName('video')[0].readyState == 4) {
                this.setState({videoReady: true});
                clearInterval(checkIfVideoReady);
            }
        }, 500);

        window.addEventListener('message', (e) => {
            if (e.data === 'toggleFullscreen') {
                this.toggleFullscreen();
            }

            if (e.data === 'toggleMute') {
                this.setState({isMuted: !this.state.isMuted});
            }
        });

        window.addEventListener('visibilitychange webkitvisibilitychange', () => {
            let myVideo = document.getElementsByTagName('video')[0];
            if (myVideo && myVideo.paused) {
                myVideo.play();
            } else if (myVideo && !myVideo.paused) {
                myVideo.play();
            }
        });

    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isFromTemplate && isObjectExists(this.props, 'config.wrap') && this.props.config.wrap.length === 0) {
            this.props.navigateTo('explore');
        } // Later add logic to check the wrap content if length > 0 and send to explore if data is empty

        if (this.state.pages.length === 0 && (this.props.config.wrap || this.props.isFromTemplate)) {
            this.setUpPageStates();
        }

        if (prevState.isEnglish !== this.state.isEnglish && this.state.ready === false) {
            this.setState({ready: true, languageChanged: false});
        }

        if (this.state.firstLoad && this.props.config.wrap && this.props.config.wrap[this.props.config.wrap.length - 1] && this.props.config.wrap[this.props.config.wrap.length-1].field6 && this.props.config.wrap[this.props.config.wrap.length-1].field6 === 'true') {
            this.triggerOpenAnimation();
        }

        let pageToFlip = this.state.initialDirection === 1 ? this.state.currentPage-1 : this.state.currentPage;

        if ((prevState.rotateValue && this.state.rotateValue !== prevState.rotateValue)) {
            document.getElementsByClassName('page-' + pageToFlip)[0].classList.add('notransition');
            document.getElementsByClassName('page-' + pageToFlip)[0].style.transform = 'perspective(3000px) rotateY(' + this.state.rotateValue + 'deg)';
        }

        if (this.state.ready && !this.state.listenersSet) {
            this.setNavigationListenersOn(document.getElementsByClassName('page-0')[0]);
            let wrapArea = document.getElementsByClassName('wrap')[0];

            window.addEventListener('resize', () => {
                this.calculateHeight(wrapArea);
            });
            this.setState({listenersSet: true});
            if (this.props.isFromTemplate) {
                this.setNavigationListenersOn(document.getElementsByClassName('page-1')[0]);
                this.setState({gotoPageNumber: 0});
            } else if (this.state.gotoPageNumber !== 0) {
                this.setNavigationListenersOn(document.getElementsByClassName('page-' + parseInt(this.state.gotoPageNumber))[0]);
                this.setState({gotoPageNumber: 0});
            }
        }
        //let vh = window.innerHeight;
    }

    getBuy = () => {
        if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
                if (!this.state.productInited) {
                    //this.ShopifyBuyInit();
                    this.setState({productInited: true});
                }
            } else {
                if (!this.state.scriptReady) {
                    this.loadScript();
                    this.setState({scriptReady: true});
                }
            }
        } else {
            if (!this.state.scriptReady) {
                this.loadScript();
                this.setState({scriptReady: true});
            }
        }
    }

    loadScript = () => {
        let script = document.createElement('script');
        let scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        script.onload = this.ShopifyBuyInit;

    }

    ShopifyBuyInit = () => {
        let ShopifyBuy = window.ShopifyBuy;
        var client = ShopifyBuy.buildClient({
            domain:                'm3dslife.myshopify.com',
            storefrontAccessToken: '0c50dff72f7a61ab8bb485a688248abd',
        });
        ShopifyBuy.UI.onReady(client).then(function (ui) {
            let products = [
                {id: '5640915419289', elementId: 'product-component-1603462032313'}, //pg 11 - plan b roller
                {id: '5712889938073', elementId: 'product-component-1602471845925'}, //pg 9
                {id: '5591385243801', elementId: 'product-component-1602471470488'}, //pg 7
                {id: '5611322835097', elementId: 'product-component-1601373123940'}, //pg 5
                {id: '5640915419289', elementId: 'product-component-1603461994300'}, //pg 3
                //{id: '5591421386905', elementId: 'product-component-1602472355021'}, //invalid code pg11
            ];
            for (let i in products) {
                ui.createComponent('product', {
                    id:          products[i].id,
                    node:        document.getElementById(products[i].elementId),
                    moneyFormat: '%24%7B%7Bamount%7D%7D',
                    options:     {
                        'product': {
                            'contents': {
                                'img':    false,
                                'price':  false,
                                'title':  false,
                                'button': true
                            },
                            'styles': {
                                'button': {
                                    '@media only screen and (width: 140px)': {
                                        'height':      '25px',
                                        'width':       'auto',
                                        'font-size':   '14px',
                                        'padding':     '16px',
                                        'line-height': '0px',
                                        /*'color':       '#85d1dd' /*light blue*/
                                    },
                                    '@media only screen and (width: 180px)': {
                                        'height':      '28px',
                                        'width':       'auto',
                                        'font-size':   '16px',
                                        'padding':     '20px',
                                        'line-height': '0px',
                                        /*'color':       '#FF33D7' /*pink*/
                                    },
                                    '@media only screen and (width: 190px)': {
                                        'height':      '32px',
                                        'width':       'auto',
                                        'font-size':   '18px',
                                        'padding':     '21px',
                                        'line-height': '0px',
                                        /*'color':       '#52307c' /*purple*/
                                    },
                                    '@media only screen and (width: 240px)': {
                                        'height':      '42px',
                                        'width':       'auto',
                                        'font-size':   '19px',
                                        'padding':     '25px',
                                        'line-height': '0px',
                                        /*'color':       '#FF0000' /*red*/
                                    },
                                    '@media only screen and (width: 260px)': {
                                        'height':      '44px',
                                        'width':       'auto',
                                        'font-size':   '21px',
                                        'padding':     '26px',
                                        'line-height': '0px',
                                        /*'color':       '#C0FF00' /*lime*/
                                    }

                                },
                                'product': {
                                    '@media (min-width: 601px)': {
                                        'max-width':     'calc(25% - 20px)',
                                        'margin-left':   '00',
                                        'margin-bottom': '50px'
                                    }
                                },
                                'img': {
                                    'height':        '280px',
                                    'width':         'auto',
                                    'margin-bottom': '0px'
                                }
                            },
                            'text': {
                                'button': 'Add to cart'
                            }
                        },
                        'productSet': {
                            'styles': {
                                'products': {
                                    '@media (min-width: 601px)': {
                                        'margin-left': '-20px'
                                    }
                                }
                            }
                        },
                        'modalProduct': {
                            'contents': {
                                'img':                false,
                                'imgWithCarousel':    true,
                                'button':             false,
                                'buttonWithQuantity': true
                            },
                            'styles': {
                                'product': {
                                    '@media (min-width: 601px)': {
                                        'max-width':     '100%',
                                        'margin-left':   '0px',
                                        'margin-bottom': '0px'
                                    }
                                }
                            },
                            'text': {
                                'button': 'Add to cart'
                            }
                        },
                        'cart': {
                            'text': {
                                'total':  'Subtotal',
                                'button': 'Checkout'
                            },
                            'popup': false
                        }
                    },
                });
            }
        }
        );
    }

    triggerOpenAnimation = () => {
        setTimeout(() => {
            let pageToAnimate = document.getElementsByClassName('page-0')[0].classList.value.indexOf('flipped') !== -1 ? 1 : 0;

            document.getElementsByClassName('page-' + pageToAnimate)[0].style.transform = 'perspective(3000px) rotateY(' + -50 + 'deg)';
            setTimeout(() => {
                document.getElementsByClassName('page-' + pageToAnimate)[0].style.transform = '';
            }, 750);
        }, 3000);

        this.setState({firstLoad: false});
    }

    toggleFullscreen = () => {
        var elem = document.getElementsByTagName('video')[0];
        if (elem && navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
            elem.webkitEnterFullscreen();
        } else if (elem && elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem && elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem && elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    }

    setUpPageStates = () => {

        let pages = [];
        if (this.props.isFromTemplate) {
            pages = [false, true, true, true, true, true, true, true, true, true, true, true, true];
            this.setState({pages});
            this.setState({currentPage: 1});
        } else {
            let wrapLength = this.props.config.wrap.length - 1;
            for (let i = 0; i<wrapLength; i++) {
                if (i === 0) {
                    if (this.state.isEnglish && (this.props.config.wrap[wrapLength].field1 === '1' || this.props.config.wrap[wrapLength].field1 === '3')) {
                        // if field1 = '1' or '3' then don't show english video
                        pages.push(false);
                        this.setState({gotoPageNumber: 1, currentPage: 1});
                    } else {
                        pages.push(true);
                    }
                } else {
                    pages.push(true);
                }
            }
            this.setState({pages});
        }
    }

    setNavigationListenersOn = (element) => {

        //let wrapArea = document.querySelectorAll('wrap-area')[0];

        element.addEventListener('mousemove', (event) => {
            event.preventDefault();
            this.flipPage(event.which, event.offsetX, event.offsetY);
        });

        element.addEventListener('touchmove', (event) => {
            event.preventDefault();
            this.flipPage(1, event.touches[0].clientX, event.touches[0].clientY);

        });
        this.calculateHeight(document.getElementsByClassName('wrap')[0]);

        element.addEventListener('mouseup', this.stopMovement);
        element.addEventListener('mouseleave', this.stopMovement);
        element.addEventListener('touchend', this.stopMovement);
        element.addEventListener('touchcancel', this.stopMovement);
    }

    calculateHeight = () => {
        let windowHeight = document.documentElement.clientHeight;
        if (windowHeight) {
            this.setState({height: windowHeight});
        }
    }

    doesItContainUrl = (config) => {
        let result = false;
        if ((this.state.isEnglish && config[config.length - 1].url) || (!this.state.isEnglish && config[config.length - 1].url1)) {
            result = true;
        }

        return result;
    }

    doesItContainPhoneNumber = (config) => {
        let result = false;
        if ((this.state.isEnglish && config[config.length - 1].PhoneNo) || (!this.state.isEnglish && config[config.length - 1].PhoneNo1)) {
            result = true;
        }

        return result;
    }

    shouldSwitchBeDisplayed = (config) => {
        let result = false;
        if (!this.props.isFromTemplate && config[config.length - 1].displaySwitch) {
            result = true;
        }

        return result;
    }

    shouldDisplaySliders = (config) => {
        let result = false;
        if (this.props.isFromTemplate || (this.state.isEnglish && config[1].storageUUID) || (!this.state.isEnglish && config[1].storageUUID1)) {
            result = true;
        }

        return result;
    }

    flipPage = (action, x, y) => {
        let deltaX = deltaX = this.mousePos.x - x;
        let deltaY = deltaY = this.mousePos.y - y;
        if (action && this.mousePos !== 0) {
            let rotateValue = this.state.rotateValue;
            let difference = this.state.difference;
            let initialDirection = this.state.initialDirection;
            let direction = this.state.direction;

            if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX > 0) {
                //left
                //direction = 1;
            } else if (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < 0) {
                //right
                //direction = 0;
            } else if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY > 0) {
                //up
                direction = 3;
            } else if (Math.abs(deltaY) > Math.abs(deltaX) && deltaY < 0) {
                //down
                direction = 4;
            }

            if (x > this.mousePos.x && (initialDirection === 0 || this.state.currentPage !== 0)) {

                if ((!(!this.props.isFromTemplate && this.skipVideoPage() && this.state.currentPage === 1))
                    && (!(this.props.isFromTemplate && this.state.currentPage <= 1))) {

                    //going left
                    if (this.state.rotateValue < 1) {
                        rotateValue = this.state.rotateValue + 1.05;
                    }
                    if (this.state.initialDirection === 2) {
                        initialDirection = 1;
                        rotateValue = -90;
                    }
                    direction = 1;

                }

            } else if (x < this.mousePos.x && (initialDirection === 0 || this.state.currentPage !== this.state.pages.length - 1)) {

                //going right
                if (this.state.rotateValue > -90) {
                    rotateValue = this.state.rotateValue - 1.05;
                }

                if (this.state.initialDirection === 2) {
                    initialDirection = 0;
                    rotateValue = 0;
                }
                direction = 0;

            } else if (direction === 3) {
                if (parent) {
                    parent.postMessage('up', '*');
                }

            } else if (direction === 4) {
                if (parent) {
                    parent.postMessage('down', '*');
                }
            }

            difference += (this.mousePos.x - x);
            this.setState({rotateValue, direction, difference, initialDirection});
        }

        this.mousePos = {x, y};
    }

    stopMovement = () => {

        let pageToFlip = this.state.initialDirection === 1 ? this.state.currentPage - 1 : this.state.currentPage;

        document.getElementsByClassName('page-' + pageToFlip)[0].classList.remove('notransition');
        document.getElementsByClassName('page-' + pageToFlip)[0].style.transform = '';
        if (this.state.direction === 0 && this.state.currentPage !== this.state.pages.length - 1 && this.state.initialDirection === 0) {
            this.nextPage();
        } else if (this.state.direction === 1 && this.state.currentPage !== 0 && this.state.initialDirection === 1) {
            this.previousPage();
        }
        this.mousePos = 0;
        this.setState({initialDirection: 2});
    }

    previousPage = () => {

        if (0 < this.state.currentPage) {
            //if ((this.props.isFromTemplate) || (!this.props.isFromTemplate && this.state.currentPage !== 0 && !this.skipVideoPage())
            //    || (!this.props.isFromTemplate && this.state.currentPage > 1 && this.skipVideoPage())) {

            if ((!(!this.props.isFromTemplate && this.skipVideoPage() && this.state.currentPage === 1))
                && (!(this.props.isFromTemplate && this.state.currentPage <= 1))) {

                let pages = [...this.state.pages];
                pages[this.state.currentPage - 1] = true;
                this.setNavigationListenersOn(document.getElementsByClassName('page-' + parseInt(this.state.currentPage - 1))[0]);
                this.setState({pages, currentPage: this.state.currentPage - 1});
            }

        }
    }

    gotoPage = (count) => {

        let pages = [...this.state.pages];

        for (let i = 0; i < count; i++) {
            pages[i] = false;  // set all pages from 0 to count-1 to false
        }
        for (let i = pages.length - 1; i >= count; i--) {
            pages[i] = true;  // set all pages from count to length-1 to true
        }
        this.setState({pages, currentPage: count});
    }

    skipVideoPage = () => {

        // field 1 = "0" - display both videos if avail, "1" don't display English video, "2" don't display !English video, "3" don't display either
        // field 2 = "2" - switch to English video even if field1 === 1 or 3
        // field3 = "2" - switch to non-English video even if field1 === 1 or 3

        let result = false;
        let length = this.props.config.wrap.length;
        let field2 = this.props.config.wrap[length - 1].field2;
        let field3 = this.props.config.wrap[length - 1].field3;
        switch (this.props.config.wrap[length - 1].field1) {
            case ('1'):
                //result = this.state.isEnglish && this.props.config.wrap[length-1].field2 !== '2' ? true: false;
                if (this.state.isEnglish) {
                    result = field2 !== '2' || (field2 === '2' && !this.props.config.wrap[0].storageUUID && !this.props.config.wrap[0].previewImage) ? true : false;
                }
                break;
            case ('2'):
                if (!this.state.isEnglish) {
                    result = field3 !== '2' || (field3 === '2' && !this.props.config.wrap[0].storageUUID1 && !this.props.config.wrap[0].previewImage1) ? true : false;
                }
                break;
            case ('3'):
                if (this.state.isEnglish) {
                    result = field2 !== '2' || (field2 === '2' && !this.props.config.wrap[0].storageUUID && !this.props.config.wrap[0].previewImage) ? true : false;
                } else {
                    result = field3 !== '2' || (field3 === '2' && !this.props.config.wrap[0].storageUUID1 && !this.props.config.wrap[0].previewImage1) ? true : false;
                }
        }

        return result;
    }

    dualCheckOK = () => {
        let result = false;
        if (this.shouldSwitchBeDisplayed(this.props.config.wrap)) {
            let switchCount = this.imagePageCountPerLanguage();
            //if ((this.props.isFromTemplate || this.state.isEnglish && switchCount[0] > this.state.currentPage) || (!this.state.isEnglish && switchCount[1] > this.state.currentPage)) {
            if ((this.state.isEnglish && switchCount[0] > this.state.currentPage) || (!this.state.isEnglish && switchCount[1] > this.state.currentPage)) {
                result = true;
            }
        } else {
            result = true;
        }

        return result;
    }

    nextPage = () => {

        let wrapLength = this.imagePageCountPerLanguage();
        let pages = [...this.state.pages];
        if (this.props.isFromTemplate) {
            wrapLength = pages.length-1;
        } else {
            wrapLength = this.state.isEnglish ? wrapLength[0] : wrapLength[1];
        }
        if (this.state.currentPage < wrapLength) {
            if (this.props.isFromTemplate || (!this.props.isFromTemplate && this.dualCheckOK())) {
                pages[this.state.currentPage] = false;
                if ((this.props.isFromTemplate) || (!this.props.isFromTemplate && this.state.isEnglish && this.props.config.wrap[this.state.currentPage + 1].storageUUID) || (!this.props.isFromTemplate && !this.state.isEnglish && this.props.config.wrap[this.state.currentPage + 1].storageUUID1)) {
                    this.setNavigationListenersOn(document.getElementsByClassName('page-' + parseInt(this.state.currentPage + 1))[0]);
                    this.setState({pages, currentPage: this.state.currentPage + 1});
                }
            }
        }
    }

    imagePageCountPerLanguage = () => {

        let i;
        let switchCount = 0;
        let switchCount1 = 0;
        if (this.props.isFromTemplate) {
            return [this.state.pages.length - 1, this.state.pages.length - 1];
        }
        let length = this.props.config.wrap.length - 1;
        for (i = 1; i < length; i++) {
            if (this.props.config.wrap[i].storageUUID !== '' && this.props.config.wrap[i].storageUUID) {
                switchCount++;
            }
            if (this.props.config.wrap[i].storageUUID1 !== '' && this.props.config.wrap[i].storageUUID1) {
                switchCount1++;
            }
        }

        return [switchCount, switchCount1];
    }

    changelanguage = () => {

        let gotoPageNumberAfterReset = 0;
        let length = this.props.config.wrap.length;
        let config = this.props.config.wrap;
        let switchingToEngLanguage = !this.state.isEnglish;
        let switchingToAltLanguage = this.state.isEnglish;
        let playEnglishVideoStorageID = config[0].storageUUID;
        let playAltLanguageVideoStorageID = config[0].storageUUID1;
        let englishImagesExistID = config[1].storageUUID;
        let altLanguageImagesExistID = config[1].storageUUID1;
        let switchCount = this.imagePageCountPerLanguage();
        let pagesPerEnglish = switchCount[0];
        let pagesPerAltLanguage = switchCount[1];

        let allowEngAndAltLanguageVideosToPlay = false; // need to take care of existence and when first starting up.
        let doNotPlayEnglishVideo = false;
        let doNotPlayAltLanguageVideo = false;
        let doNotPlayEnglishOrAltLanguageVideo = false;
        switch (config[length - 1].field1) {
            case '0':
                allowEngAndAltLanguageVideosToPlay = true; // need to take care of existence and when first starting up.
                break;
            case '1':
                doNotPlayEnglishVideo = true;
                break;
            case '2':
                doNotPlayAltLanguageVideo = true;
                break;
            case '3':
                doNotPlayEnglishOrAltLanguageVideo = true;
                break;
        }

        let switchToSameCurrentPageAsAltLanguage = false; // need to take care of existence and when first starting up beside logic below.
        let switchToEnglishVideoUnlessThenFirstImage = false;
        let switchToEnglishVideoRegardless = false;
        let switchToFirstEnglishImage = false;
        let switchToEnglishVideoUnlessThenCurrentPage = false;
        switch (config[length - 1].field2) {
            case '0':
                switchToSameCurrentPageAsAltLanguage = true;
                break;
            case '1':
                switchToEnglishVideoUnlessThenFirstImage = true;
                break;
            case '2':
                switchToEnglishVideoRegardless = true;
                break;
            case '3':
                switchToFirstEnglishImage = true;
                break;
            case '4':
                switchToEnglishVideoUnlessThenCurrentPage = true;
                break;
        }

        let switchToSameCurrentPageAsEngLanguage = false; // need to take care of existence and when first starting up beside logic below.
        let switchToAltLanguageVideoUnlessThenFirstImage = false;
        let switchToAltLanguageVideoRegardless = false;
        let switchToFirstAltLanguageImage = false;
        let switchToAltLanguageVideoUnlessThenCurrentPage = false;
        switch (config[length - 1].field3) {
            case '0':
                switchToSameCurrentPageAsEngLanguage = true;
                break;
            case '1':
                switchToAltLanguageVideoUnlessThenFirstImage = true;
                break;
            case '2':
                switchToAltLanguageVideoRegardless = true;
                break;
            case '3':
                switchToFirstAltLanguageImage = true;
                break;
            case '4':
                switchToAltLanguageVideoUnlessThenCurrentPage = true;
                break;
        }

        // field 1:
        // "0" - allow display of both videos if avail,
        // "1" don't display English video,
        // "2" don't display !English video,
        // "3" don't display either.

        // field 2:
        // "0" - switch to same number as currentPage if available 0r next closest.
        // "1" - switch to English video if field1 <> 1 or 3, if field1 = 1 or 3 then go to first tpage
        // "2" - switch to English video even if field1 === 1 or 3
        // "3" - switch to first English page, even if video exist, not current page or last page depending on condition.
        // "4" - switch to English video if field1 <> 1 or 3, if field1 = 1 or 3 then go to current page

        // field3:
        // "0" - switch to same number as currentPage if available 0r next closest.
        // "1" - switch to non-english video if field1 <> 2 or 3, if field1 = 2 or 3 then go to first page
        // "2" - switch to non-english video even if field1 = 2 or 3
        // "3" - switch to first non-english page, even if video exists, not current page or last page depending on condition
        // "4" - switch to non-english video if field1 <> 2 or 3, if field1 = 2 or 3 then go to current page

        if ((switchingToEngLanguage && ((doNotPlayEnglishVideo || doNotPlayEnglishOrAltLanguageVideo) && !switchToEnglishVideoRegardless) && !switchToEnglishVideoUnlessThenCurrentPage && pagesPerEnglish > 0)) {
            if (switchToSameCurrentPageAsAltLanguage) {
                if (this.state.currentPage > pagesPerEnglish && pagesPerEnglish > 1 && this.state.currentPage !== 0) {
                    gotoPageNumberAfterReset = pagesPerEnglish;
                    this.gotoPage(pagesPerEnglish);
                } else if (pagesPerEnglish > 1 && this.state.currentPage !== 0) {
                    gotoPageNumberAfterReset = this.state.currentPage;
                } else {
                    gotoPageNumberAfterReset = 1; //means Alt Lang current page is the video and English video is not to be played so need to go to page 1 instead
                    this.gotoPage(1);
                }
            } else {
                gotoPageNumberAfterReset = 1;
                this.gotoPage(1);
            }
        } else if ((switchingToAltLanguage && ((doNotPlayAltLanguageVideo || doNotPlayEnglishOrAltLanguageVideo) && !switchToAltLanguageVideoRegardless) && !switchToAltLanguageVideoUnlessThenCurrentPage && pagesPerAltLanguage > 0)) {
            if (switchToSameCurrentPageAsEngLanguage) {
                if (this.state.currentPage > pagesPerAltLanguage && pagesPerAltLanguage > 1 && this.state.currentPage !== 0) {
                    gotoPageNumberAfterReset = pagesPerAltLanguage;
                    this.gotoPage(pagesPerAltLanguage);
                } else if (pagesPerAltLanguage > 1 && this.state.currentPage !== 0) {
                    gotoPageNumberAfterReset = this.state.currentPage;
                } else {
                    gotoPageNumberAfterReset = 1;
                    this.gotoPage(1);
                }
            } else {
                gotoPageNumberAfterReset = 1;
                this.gotoPage(1);
            }
        } else if ((switchingToEngLanguage && playEnglishVideoStorageID && (switchToEnglishVideoRegardless || (switchToEnglishVideoUnlessThenFirstImage && (!doNotPlayEnglishVideo && !doNotPlayEnglishOrAltLanguageVideo))))
            || (switchingToAltLanguage && playAltLanguageVideoStorageID && (switchToAltLanguageVideoRegardless || (switchToAltLanguageVideoUnlessThenFirstImage && (!doNotPlayAltLanguageVideo && !doNotPlayEnglishOrAltLanguageVideo))))) {
            gotoPageNumberAfterReset = 0;
            this.gotoPage(0);
        } else if ((switchingToEngLanguage && switchToFirstEnglishImage && englishImagesExistID)
            || (switchingToAltLanguage && switchToFirstAltLanguageImage && altLanguageImagesExistID)) {
            gotoPageNumberAfterReset = 1;
            this.gotoPage(1);
        } else if ((switchingToEngLanguage && switchToFirstEnglishImage && !englishImagesExistID)
            || (switchingToAltLanguage && switchToFirstAltLanguageImage && !altLanguageImagesExistID)) {
            // forcing to image page but no images
            return;
        } else if ((switchingToAltLanguage && switchToSameCurrentPageAsEngLanguage && (allowEngAndAltLanguageVideosToPlay || !doNotPlayAltLanguageVideo))
            || (switchingToAltLanguage && (doNotPlayAltLanguageVideo || doNotPlayEnglishOrAltLanguageVideo) && switchToAltLanguageVideoUnlessThenCurrentPage)) {
            if (this.state.currentPage > pagesPerAltLanguage) {
                this.gotoPage(pagesPerAltLanguage);
                gotoPageNumberAfterReset = pagesPerAltLanguage;
            } else if (this.state.currentPage === 0 && switchToAltLanguageVideoUnlessThenCurrentPage && altLanguageImagesExistID) {
                gotoPageNumberAfterReset = 1;   //don't go to video but to next current page which is page 1. Otherwise do nothing and don't switch.
                this.gotoPage(1);
            } else if (this.state.currentPage === 0 && switchToAltLanguageVideoUnlessThenCurrentPage && !altLanguageImagesExistID) {
                return;
            } else {
                gotoPageNumberAfterReset = this.state.currentPage;
            }
        } else if ((switchingToEngLanguage && switchToSameCurrentPageAsAltLanguage && (allowEngAndAltLanguageVideosToPlay || !doNotPlayEnglishVideo))
            || (switchingToEngLanguage && (doNotPlayEnglishVideo || doNotPlayEnglishOrAltLanguageVideo) && switchToEnglishVideoUnlessThenCurrentPage)) {
            if (this.state.currentPage > pagesPerEnglish) {
                gotoPageNumberAfterReset = pagesPerEnglish;
                this.gotoPage(pagesPerEnglish);
            } else if (this.state.currentPage === 0 && switchToEnglishVideoUnlessThenCurrentPage && englishImagesExistID) {
                gotoPageNumberAfterReset = 1;   //don't go to video but to next current page which is page 1. Otherwise do nothing and don't switch.
                this.gotoPage(1);
            } else if (this.state.currentPage === 0 && switchToEnglishVideoUnlessThenCurrentPage && !englishImagesExistID) {
                return;
            } else {
                gotoPageNumberAfterReset = this.state.currentPage;
            }
        }

        this.setState({gotoPageNumber: gotoPageNumberAfterReset});
        this.setState({
            isEnglish:       !this.state.isEnglish,
            ready:           false,
            listenersSet:    false,
            pagesReady:      false,
            languageChanged: true
        });

    }

    toggleFrame = (show) => {
        if (show) {
            if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
                document.documentElement.style.overflow = 'auto';
                document.documentElement.style.position = 'relative';
            }
            document.getElementsByClassName('float-frame')[0].classList.add('frame-visible');
        } else {
            if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
                document.documentElement.style.overflow = 'hidden';
                document.documentElement.style.position = 'fixed';
            }
            document.getElementsByClassName('float-frame')[0].classList.remove('frame-visible');

        }

    }

    handleMute = () => {
        this.setState({isMuted: !this.state.isMuted});
    }

    getVideoPage = (videoParameters) => {
        //let isApple = navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/) ? true : false;
        let lengthURL = this.props.config.wrap.length - 1;
        let phoneNo;
        let hrefPhoneNo;
        let videoId;
        let displayVolume;
        let videoSize = this.props.config.wrap[this.props.config.wrap.length - 1].field5 === 'partial' ? 'partialvideopage' : 'videopage';
        let previewImageSize = this.props.config.wrap[this.props.config.wrap.length - 1].field5 === 'partial' ? 'contain' : 'cover';
        let bgUrl;
        if (this.state.isEnglish) {
            videoId = this.props.config.wrap[0].storageUUID;
            displayVolume = this.props.config.wrap[0].storageUUID ? true : false;
            hrefPhoneNo = 'tel:' + this.props.config.wrap[lengthURL].PhoneNo;
            phoneNo = this.props.config.wrap[lengthURL].PhoneNo;
            bgUrl = this.props.config.wrap[0].previewImage ? getUrlAndLicence(this.props.config.wrap[0].previewImage, false, '', 'VieroToolkitUtilsTypeStillSingle').url : getVideoThumbnail(videoId, 0, '');
        } else {
            videoId = this.props.config.wrap[0].storageUUID1;
            displayVolume = this.props.config.wrap[0].storageUUID1 ? true : false;
            hrefPhoneNo = 'tel:' + this.props.config.wrap[lengthURL].PhoneNo1;
            phoneNo = this.props.config.wrap[lengthURL].PhoneNo1;
            bgUrl = this.props.config.wrap[0].previewImage1 ? getUrlAndLicence(this.props.config.wrap[0].previewImage1, false, '', 'VieroToolkitUtilsTypeStillSingle').url : getVideoThumbnail(videoId, 0, '');
        }

        //let bgUrl = getVideoThumbnail(this.props.config.wrap[0].storageUUID, 0, '');
        //let bgUrl = this.props.config.wrap[0].previewImage && this.props.config.wrap[0].previewImage !== '' ? getUrlAndLicence(this.props.config.wrap[0].previewImage, false, '', 'VieroToolkitUtilsTypeStillSingle').url : getVideoThumbnail(videoId, 0, '');
        //let bgUrl = this.props.config.wrap[0].previewImage && this.props.config.wrap[0].previewImage !== '' ? getUrlAndLicence(this.props.config.wrap[0].previewImage, false, '', 'VieroToolkitUtilsTypeStillSingle').url : getVideoThumbnail(this.props.config.wrap[0].storageUUID, 0, '');
        return (
            <div id={videoSize} style={{height: this.state.height + 'px', marginTop: this.state.scrollValue + 'px'}}
                className={this.state.pages[0] ? 'page transition page-0' : 'page bg-dark flipped transition page-0'}>
                <div style={{
                    height:             this.state.height + 'px',
                    backgroundImage:    'url(' + bgUrl + ')',
                    backgroundPosition: 'center',
                    backgroundSize:     previewImageSize,
                    backgroundRepeat:   'no-repeat'
                }}
                className="position-relative bg-black">

                    {displayVolume ?
                        <VideoPlayer
                            url={videoParameters.url} isViero={true}
                            keySystems={videoParameters.keySystems}
                            videoClass={this.state.videoReady ? 'wrap-video hover video-background' : 'hidden wrap-video hover video-background'}
                            ref={(ref) => this.videoPlayer = ref}
                            id={videoId}
                            listenStatus={this.props.listenStatus}
                            status={{[videoId]: 'ready'}}
                            appleTech={videoParameters.appleTech}
                            showControls={false}
                            autoPlay={true}
                            isMuted={this.state.isMuted}
                        />
                        :
                        null
                    }
                    <div style={{height: this.state.height * 0.80 + 'px'}} className="position-relative"></div>
                    <div style={{height: this.state.height * 0.25 + 'px'}} className="position-relative">
                        {this.doesItContainPhoneNumber(this.props.config.wrap) &&
                        <div className="info-bg info-center text-center">
                            Ph: <a target="_top" href={hrefPhoneNo}>{phoneNo}</a>
                        </div>
                        }
                        {this.doesItContainUrl(this.props.config.wrap) &&
                        <div className="position-relative mt-1 text-center">
                            {this.state.isEnglish ?
                                <button className="btn btn-light btn-sm my-auto position-relative" id="URLButton"
                                    onClick={() => this.toggleFrame(true)}>{this.props.config.wrap[lengthURL].buttonLabel}</button>
                                :
                                <button className="btn btn-light btn-sm my-auto position-relative" id="URLButton"
                                    onClick={() => this.toggleFrame(true)}>{this.props.config.wrap[lengthURL].buttonLabel1}</button>
                            }
                        </div>
                        }
                    </div>
                </div>
            </div>
        );

    }

    scrollWrap = () => {
        this.setState({scrollValue: this.state.scrollValue - 3});
    }

    getMaxImageWidth = (max, url) => {
        const img = new Image();
        const scope = this;
        img.onload = function () {
            if (max <= this.width) {
                scope.setState({maxWidth: this.naturalWidth});
            }
        };
        img.src = url;
    }

    mapImages = () => {

        let images = [];
        let indexes = [];
        let index = 0;
        let url;
        let maxWidth = this.state.maxWidth;
        let indexAlt = 0;
        for (let i = this.props.config.wrap.length - 1; i--; i > 0) {
            if (i !== 0 && !this.props.config.wrap[i].url) {
                if (this.state.isEnglish) {
                    if (this.props.config.wrap[i].storageUUID) {
                        url = getUrlAndLicence(this.props.config.wrap[i].storageUUID, false, '', 'VieroToolkitUtilsTypeStillSingle').url;
                        if (!this.state.imagesMapped) {
                            maxWidth = this.getMaxImageWidth(maxWidth, url);
                        }
                        images.push(url);
                        indexes.push(index);
                        index++;
                    }
                } else {
                    if (this.props.config.wrap[i].storageUUID1) {
                        url = getUrlAndLicence(this.props.config.wrap[i].storageUUID1, false, '', 'VieroToolkitUtilsTypeStillSingle').url;
                        if (!this.state.imagesMapped) {
                            maxWidth = this.getMaxImageWidth(maxWidth, url);
                        }
                        images.push(url);
                        indexes.push(indexAlt);
                        indexAlt++;
                    }
                }
            }
        }
        if (!this.state.imagesMapped) {
            this.setState({imagesMapped: true});
        }
        return {images, indexes, maxWidth};
    }

    getImageClass = (position) => {
        let imageClass = 'bg-dark transition page page-' + position;
        if (!this.state.pages[position]) {
            imageClass += ' flipped';
        }

        if (this.state.pages[position] && (position !== this.state.currentPage && position > this.state.currentPage + 2)) {
            imageClass += ' invisible';
        }

        if (this.state.pages[position] && position > this.state.currentPage + 5 && (this.props.isFromTemplate || this.props.config.wrap[0].wrapName.indexOf('m3dsorig'.toLowerCase()) === -1)) {
            imageClass += ' d-none';
        }

        return imageClass;
    }

    getImageClass1 = (position) => {
        let imageClass = 'bg-dark transition page page-' + position;
        if (!this.state.pages[position]) {
            imageClass += ' flipped';
        }

        if (this.state.pages[position] && (position !== this.state.currentPage && position > this.state.currentPage + 2)) {
            imageClass += ' invisible';
        }

        if (this.state.pages[position] && position > this.state.currentPage + 5 && (this.props.isFromTemplate || this.props.config.wrap[0].wrapName.indexOf('m3dsorig'.toLowerCase()) === -1)) {
            imageClass += ' flipped';
        }

        return imageClass;
    }

    getProductButton = (i) => {
        let button;

        // case 0-10 where 0 = last page with no product
        // case 10 is pg 2, 9 is pg 3, 8 is pg 4, 7 is pg 5, 6 is pg 6, 5 is 7, 4 is 8, 3 is 9, 2 is 10, 1 is 11, 0 is pg 12 (no product image so no index below)
        // pg 1 = video page - not included in this index count
        switch (i) {
            case '9': //page 3
                button = (<div id='product-component-1603461994300'></div>);
                break;
            case '7': //page 5
                button = (<div id='product-component-1601373123940'></div>);
                break;
            case '5': //page 7
                button = (<div id='product-component-1602471470488'></div>);
                break;
            case '3': //page 9
                button = (<div id='product-component-1602471845925'></div>);
                break;
            case '1': //page 11 - temporary plan b roller
                button = (<div id='product-component-1603462032313'></div>);
                break;
            default:
                break;
        }

        return button;
    }

    getTextFields = (i) => {
        let wrap = this.props.config.wrap[i];
        return (
            <React.Fragment>
                <div className="first-field">{wrap.field1}</div>
                <div className="second-field">{wrap.field2}</div>
            </React.Fragment>
        );
    }

    generatePagesFromTemplates = () => {

        let pageTemplates = [
            {
                template: require('../../assets/wraps/m3ds/page12.html')
            },
            {
                template: require('../../assets/wraps/m3ds/page11.html')
            },
            {
                template: require('../../assets/wraps/m3ds/page10.html')
            },
            {
                template: require('../../assets/wraps/m3ds/page9.html')
            },
            {
                template: require('../../assets/wraps/m3ds/page8.html')
            },
            {
                template: require('../../assets/wraps/m3ds/page7.html')
            },
            {
                template: require('../../assets/wraps/m3ds/page6.html')
            },
            {
                template: require('../../assets/wraps/m3ds/page5.html')
            },
            {
                template: require('../../assets/wraps/m3ds/page4.html')
            },
            {
                template: require('../../assets/wraps/m3ds/page3.html')
            },
            {
                template: require('../../assets/wraps/m3ds/page2.html')
            },
            {
                template: require('../../assets/wraps/m3ds/page1.html')
            }
        ];
        let length = pageTemplates.length;
        //this.getWrapTemplates();
        //{this.getImageClass(length - 1)}
        let pagesWithButton = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
        return Object.keys(pageTemplates).map((i) => (
            <React.Fragment key={i}>
                <div id={'partialimagepage'}  className={this.getImageClass1(length - parseInt(i))}
                    style={{
                        height:    this.state.height + 'px',
                        marginTop: this.state.scrollValue + 'px',
                        maxWidth:  '570px'
                    }}>
                    <div className="h-100" dangerouslySetInnerHTML={{__html: pageTemplates[i].template}}></div>
                    <div className="template-shopify-button">
                        {pagesWithButton.indexOf(i) > -1 && this.getProductButton(i)}
                    </div>
                </div>

            </React.Fragment>
        ));
    }

    generatePages = () => {

        let lengthURL = this.props.config.wrap.length - 1;
        let phoneNo;
        let hrefPhoneNo;
        if (this.state.isEnglish) {
            hrefPhoneNo = 'tel:' + this.props.config.wrap[lengthURL].PhoneNo;
            phoneNo = this.props.config.wrap[lengthURL].PhoneNo;
        } else {
            hrefPhoneNo = 'tel:' + this.props.config.wrap[lengthURL].PhoneNo1;
            phoneNo = this.props.config.wrap[lengthURL].PhoneNo1;
        }
        let mappedWrap = this.mapImages();
        let maximumWidth = 'min-content';

        maximumWidth = mappedWrap.maxWidth > window.innerWidth ? mappedWrap.maxWidth : mappedWrap.maxWidth;

        if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
            maximumWidth = '100%';
        }
        let images = mappedWrap.images;
        let indexes = mappedWrap.indexes;
        let pagesPerLang = this.imagePageCountPerLanguage();
        let length = this.state.isEnglish ? pagesPerLang[0] : pagesPerLang[1];

        let imageSize = this.props.config.wrap[this.props.config.wrap.length - 1].field5 === 'partial' ? 'partialimagepage' : 'imagepage';
        let pagesWithButton = ['1', '3', '5', '7', '9'];  // shopifypages

        return Object.keys(images).map((i) => (
            <React.Fragment key={i}>
                <div id={imageSize}
                    style={{
                        height:          this.state.height + 'px',
                        marginTop:       this.state.scrollValue + 'px',
                        backgroundImage: 'url(' + images[i] + ')',
                        maxWidth:        maximumWidth
                    }}

                    className={this.getImageClass(length - indexes[i])}>
                    <React.Fragment>
                        {this.props.config.wrap[0].wrapName == 'm3ds1' && this.getTextFields(length - indexes[i])}
                        <div style={{height: this.state.height * 0.80 + 'px'}} className="position-relative">
                        </div>
                        <div style={{height: this.state.height * 0.20 + 'px'}} className="position-relative">
                            {pagesWithButton.indexOf(i) > -1 && this.props.config.wrap[0].wrapName.indexOf('m3dsorig'.toLowerCase()) > -1 ? this.getProductButton(i) : null}
                            {this.doesItContainPhoneNumber(this.props.config.wrap) && this.props.config.wrap[0].wrapName.indexOf('m3m3dsorig'.toLowerCase()) === -1 &&
                            <div className="info-bg info-center text-center">
                                Ph: <a target="_top" href={hrefPhoneNo}>{phoneNo}</a>
                            </div>
                            }
                            {this.doesItContainUrl(this.props.config.wrap) && this.props.config.wrap[0].wrapName.indexOf('m3dsorig'.toLowerCase()) === -1 &&
                            <div className="position-relative mt-1 text-center">
                                {this.state.isEnglish ?
                                    <button className="btn btn-light btn-sm my-auto position-relative" id="URLButton"
                                        onClick={() => this.toggleFrame(true)}>{this.props.config.wrap[lengthURL].buttonLabel}</button>
                                    :
                                    <button className="btn btn-light btn-sm my-auto position-relative" id="URLButton"
                                        onClick={() => this.toggleFrame(true)}>{this.props.config.wrap[lengthURL].buttonLabel1}</button>
                                }
                            </div>
                            }
                        </div>
                    </React.Fragment>

                </div>

            </React.Fragment>
        ));
    }

    render() {
        if (this.state.ready) {
            let webpage;
            let videoParameters;
            if (!this.props.isFromTemplate) {
                let playThis = this.state.isEnglish ? this.props.config.wrap[0].storageUUID : this.props.config.wrap[0].storageUUID1;
                videoParameters = window.VieroToolkitUtils ? getUrlAndLicence(playThis, false, document.documentElement.clientWidth + 'x' + document.documentElement.clientHeight, 'VieroToolkitUtilsTypeMotion') : '';

                if (this.doesItContainUrl(this.props.config.wrap)) {
                    let url = this.state.isEnglish ? this.props.config.wrap[this.props.config.wrap.length - 1].url : this.props.config.wrap[this.props.config.wrap.length - 1].url1;
                    //let url = this.props.config.wrap[this.props.config.wrap.length-1].url;
                    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
                        webpage = <iframe id="iframe"
                            src={url}
                            frameBorder="0"
                            height={'100vh'}
                            width="100%"
                            sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation"
                            className="ios-frame position-relative"

                        />;
                    } else {
                        webpage = <Iframe id="iframe"
                            url={url}
                            width="100%"
                            display="initial"
                            frameBorder="0"
                            position={'relative'}
                        />;
                    }
                }
            }
            /* let firstImage = getUrlAndLicence(this.props.config.wrap[1].storageUUID, false, '', 'VieroToolkitUtilsTypeStillSingle').url;
            let secondImage = getUrlAndLicence(this.props.config.wrap[2].storageUUID, false, '', 'VieroToolkitUtilsTypeStillSingle').url;
            let thirdImage = getUrlAndLicence(this.props.config.wrap[3].storageUUID, false, '', 'VieroToolkitUtilsTypeStillSingle').url;*/

            return (
                <React.Fragment>
                    <Helmet>
                        {this.props.isFromTemplate || this.props.config.wrap[0].wrapName.indexOf('m3dsorig'.toLowerCase()) > -1 ?
                            this.getBuy()
                            :
                            null
                        }
                    </Helmet>
                    <div className="float-frame">
                        <div className="hide-frame-button" onClick={() => this.toggleFrame(false)}>
                            <span>X</span>
                        </div>
                        {webpage}
                    </div>
                    <Row className="close-video video-buttons mute-icon video-mute">
                        {document.getElementsByTagName('video')[0] && document.getElementsByTagName('video')[0].readyState === 4 && this.state.currentPage === 0 ?

                            this.state.isMuted ?
                                <Col xs="12" sm="12" md="12" lg="12" xl="12"
                                    className="font-weight-bold p-1 prevent-zoom">
                                    <img src={mutedIcon} className="p-0 mute-icon img-fluid volume-icon"
                                        onClick={() => this.handleMute()}/>
                                </Col>
                                :
                                <Col xs="12" sm="12" md="12" lg="12" xl="12"
                                    className="font-weight-bold p-1 prevent-zoom">
                                    <img src={unmutedIcon} className="p-0 mute-icon img-fluid volume-icon"
                                        onClick={() => this.handleMute()}/>
                                </Col>
                            :
                            null

                        }
                    </Row>

                    <Row className="close-video slider-buttons mute-icon">
                        {/*<span onClick={() => this.scrollWrap()}>goup</span>*/}

                        {this.shouldDisplaySliders(this.props.config.wrap) ?
                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className="font-weight-bold p-1 prevent-zoom"
                                onClick={() => this.previousPage()}><img className="right-slider no-select"
                                    src={backButton}/></Col>
                            :
                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className="font-weight-bold p-1 prevent-zoom">{<img
                                className="right-slider" src={backButtonTransparent}/>}</Col>
                        }
                        {this.shouldDisplaySliders(this.props.config.wrap) ?
                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className="font-weight-bold p-1 prevent-zoom"
                                onClick={() => this.nextPage()}><img className="left-slider no-select"
                                    src={backButton}/></Col>
                            :
                            <Col xs="6" sm="6" md="6" lg="6" xl="6" className="font-weight-bold p-1 prevent-zoom"><img
                                className="left-slider" src={backButtonTransparent}/></Col>
                        }
                        {this.shouldSwitchBeDisplayed(this.props.config.wrap) ?
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" className="font-weight-bold p-1 prevent-zoom"
                                onClick={() => this.changelanguage()}><img
                                    className={this.props.config.wrap[this.props.config.wrap.length - 1].field4 === 'languageicon' ? 'language-selector' : 'switchDisplay-selector'}
                                    src={this.props.config.wrap[this.props.config.wrap.length - 1].field4 === 'languageicon' ? languageIcon : switchIcon}/></Col>
                            :
                            null
                        }
                    </Row>

                    {this.props.isFromTemplate === true ? this.generatePagesFromTemplates() : this.generatePages()}
                    {(this.props.isFromTemplate) ?
                        <div id={'partialvideopage'} style={{height: this.state.height + 'px', marginTop: this.state.scrollValue + 'px'}}
                            className={this.state.pages[0] ? 'page transition page-0' : 'page bg-dark flipped transition page-0' }></div>
                        :
                        null
                    }
                    {!this.props.isFromTemplate && this.getVideoPage(videoParameters, webpage)}

                </React.Fragment>
            );
        } else {
            return null;
        }
    }
}

Wrapfull.propTypes = {
    user:           PropTypes.object,
    listenStatus:   PropTypes.func,
    width:          PropTypes.string,
    height:         PropTypes.string,
    pages:          PropTypes.array,
    next:           PropTypes.func,
    prev:           PropTypes.func,
    setPages:       PropTypes.func,
    currentPage:    PropTypes.number,
    location:       PropTypes.object,
    dispatch:       PropTypes.func,
    config:         PropTypes.object,
    navigateTo:     PropTypes.func,
    isFromTemplate: PropTypes.bool
};
