import { getUrlAndLicence } from './VieroUtils';

const uuidv4 = require('uuid/v4');

export const knownTypes = ['facebook', 'twitter', 'spotify', 'youtube', 'youtu.be', 'deezer', 'instagram'];

export const isObjectExists = function(obj, key) {
    return key.split('.').every(function(x) {
        if (typeof obj != 'object' || obj === null || ! (x in obj))
            return false;
        obj = obj[x];
        return true;
    });
};

export const getObjectValue = function(obj, key) {
    return key.split('.').reduce(function(o, x) {
        return (typeof o == 'undefined' || o === null) ? o : o[x];
    }, obj);
};

export const removeCapsule = function(id, capsules) {

    let userCapsules = [];
    for (let i in capsules) {
        if (capsules[i].id !== id) {
            userCapsules.push(capsules[i]);
        }
    }

    return userCapsules;
};

export const getCapsuleName = function(capsule) {
    let name = '';

    if (undefined !== ((capsule || {}).name || {}).length) {
        name = capsule.name;
    } else {
        if (undefined !== (((((capsule || {}).items || {}).contact || {}).name || {}).givenName || {}).length) {
            name += capsule.items.contact.name.givenName;
        }
        if (undefined !== (((((capsule || {}).items || {}).contact || {}).name || {}).familyName || {}).length) {
            name += capsule.items.contact.name.familyName;
        }
    }

    if (name.length == 0) {
        name = 'No Capsule Name';
    }

    return name;
};

export const sortAlphabetically = function(capsule) {
    let sorted = [];
    if (capsule && 0 < capsule.length) {
        sorted = capsule.sort((a, b) => (getCapsuleName(a).toLowerCase() > getCapsuleName(b).toLowerCase()) ? 1 : ((getCapsuleName(b).toLowerCase() > getCapsuleName(a).toLowerCase()) ? -1 : 0));
    }
    return sorted;
};

export const sortByUpdatedDate = function(capsule) {
    let sorted = [];
    if (capsule && 0 < capsule.length) {
        sorted = capsule.sort((a, b) => (getDateUpdated(b) > getDateUpdated(a)) ? 1 : ((getDateUpdated(a) > getDateUpdated(b)) ? -1 : 0));
    }
    return sorted;
};

export const getDateUpdated = function(capsule) {
    let date = '';

    if (undefined !== capsule.updatedAt) {
        date = capsule.updatedAt;
    }

    if (capsule.length == 0) {
        date = '';
    }

    return date;
};

export const getUsersPhoneEmail = function(users) {
    let user = '';

    if (undefined !== users.name) {
        user = users.name;
    }

    if (user.length == 0) {
        user = 'No Name';
    }

    return user;
};

export const sortAlphabeticallyUsers = function(users) {
    let sorted = [];
    if (users && 0 < users.length) {
        sorted = users.sort((a, b) => (getUsersPhoneEmail(a).toLowerCase() > getUsersPhoneEmail(b).toLowerCase()) ? 1 : ((getUsersPhoneEmail(b).toLowerCase() > getUsersPhoneEmail(a).toLowerCase()) ? -1 : 0));
    }
    return sorted;
};

export const getUserTokenFromStorage = function() {
    var token ='';
    if (localStorage.getItem('MUUserLogin')) {
        token = JSON.parse(localStorage.getItem('MUUserLogin')).tokenObject.token;
    } else if (localStorage.getItem('MUUser')) {
        token = JSON.parse(localStorage.getItem('MUUser')).tokenObject.token;
    }

    return token;
};

export const generatePathToFiles = function(files) {
    let modifiedFiles = files;
    let path = Math.floor(Date.now() / 1000) + '--' + uuidv4();

    for (let i = 0; i < modifiedFiles.length; i++) {
        modifiedFiles[i].path = path;
    }

    return modifiedFiles;
};

export const mergeSearchResults = (results) => {
    let searchResults = {...results};
    let mergedResults = [];

    if (searchResults.userCapsules && searchResults.sharedCapsules && searchResults.publicCapsules) {
        for (let i in searchResults.userCapsules) {
            mergedResults.push(searchResults.userCapsules[i]);
        }

        for (let i in searchResults.sharedCapsules) {
            mergedResults.push(searchResults.sharedCapsules[i]);
        }

        for (let i in searchResults.publicCapsules) {
            mergedResults.push(searchResults.publicCapsules[i]);
        }
    } else {
        mergedResults = results;
    }

    return mergedResults;
};

export const isWebsiteSocial = (url) => {

    for (let i in knownTypes) {
        if (url && -1 !== url.indexOf(knownTypes[i]) && -1 === url.indexOf('profile.php')) {
            return knownTypes[i] === 'youtu.be' ? 'youtube' : knownTypes[i];
        }
    }
    return false;
};

export const addSocialProfiles = (originalvCard, vcardObject) => {
    let vCard = originalvCard;
    let urls = [];
    for (let i in vcardObject.socialProfiles) {
        switch (vcardObject.socialProfiles[i].label) {
            case 'facebook':
            case 'twitter':
                vCard.socialUrls[vcardObject.socialProfiles[i].label] = vcardObject.socialProfiles[i].value.urlString;
                break;
            default:
                urls.push('URL;CHARSET=UTF-8;type=' + vcardObject.socialProfiles[i].label + ':' + vcardObject.socialProfiles[i].value.urlString);
        }
    }

    return {vCard, urls};
};

export async function getImage(url) {
    let requestOptions = {
        method: 'GET',
    };
    let r = await fetch(url, requestOptions);

    let blob = await r.blob();
    return await getBase64Img(blob);

}

export const getBase64Img = (blob) => {
    var reader = new FileReader();
    return new Promise((resolve, reject) =>{
        reader.onerror = () => {
            reader.abort();
            reject(new DOMException('Problem parsing input file.'));
        };

        reader.onload = function() {
            resolve(reader.result.replace(/^data:.+;base64,/, ''));
        };
        reader.readAsDataURL(blob);
    });

};

export const getVcardString = (data) => {
    let vcardString = '';

    for (let i in data) {

        if (i == data.length-1) {
            vcardString += data[i];
        } else {
            vcardString += data[i] + '\n';
        }

    }

    return vcardString;
};

export function addDates(vCard, date) {
    let vCardWithDates = vCard;

    let dateString = '';
    let actualDate, index;
    if (date.dates.length > 0) {
        for (let i in date.dates) {
            index = parseInt(i + 1);
            actualDate = new Date(date.dates[i].value);
            dateString += 'item' + index + '.X-ABDATE:' + actualDate.getFullYear() + '-' + parseInt(actualDate.getMonth() + 1)  + '-' + actualDate.getDate() + '\n';
            dateString += 'item' + index + '.X-ABLabel:' + date.dates[i].label + '\n';

            if (date.dates[i].label == 'anniversary') {
                vCardWithDates.anniversary = new Date(date.dates[i].value);
            }
        }
    }

    if (date.birthday) {
        vCardWithDates.birthday = new Date(date.birthday);
    }

    return {vCardWithDates, dateString};
}

export function setName(vCard, vcardObject) {
    let vCardWithName = vCard;

    if (vcardObject.name.givenName !== '') {
        vCardWithName.firstName = vcardObject.name.givenName;
    } else {
        vCardWithName.firstName = vcardObject.capsuleName;
    }

    vCardWithName.lastName = vcardObject.name.familyName;

    return vCardWithName;
}

export async function getVcard(vcardObject) {

    var vCardsJS = require('vcards-js');

    var vCard = vCardsJS();
    let socialProfiles = addSocialProfiles(vCard, vcardObject);

    vCard = socialProfiles.vCard;

    let urls = socialProfiles.urls;
    let vcardUrls = '';
    let emailString = '';
    let phoneNumberString = '';
    let postalAddressString = '';
    let phoneNumbers = [];
    let emailAddresses = [];
    let postalAddresses = [];

    let splittedResult = '';
    let customString = '';
    let result = '';
    let dates = {dateString: ''};
    vCard.version = '3.0';

    vCard = setName(vCard, vcardObject);

    if (isObjectExists(vcardObject, 'date.dates')) {
        dates = addDates(vCard, vcardObject.date);
        vCard = dates.vCardWithDates;
    }

    if (isObjectExists(vcardObject, 'organisation')) {
        vCard.organization = vcardObject.organisation.organizationName;
    }

    if (isObjectExists(vcardObject.image, 'thumbnail.metadata')) {
        let link;
        if (vcardObject.image.thumbnail.metadata.storageType === 'S3') {
            link = process.env.REACT_APP_API_BASE_URI + '/cdn/files/' + vcardObject.image.thumbnail.fileId + '/w100-h100-cfill?token=' + getUserTokenFromStorage();
        } else {
            link = getUrlAndLicence(vcardObject.image.thumbnail.metadata.storageUUID, false, '', 'VieroToolkitUtilsTypeStillSingle').url;
        }

        vCard.photo.embedFromString(await getImage(link));

    }

    for (let i in vcardObject.phoneNumbers) {
        phoneNumbers.push('TEL;TYPE=' + vcardObject.phoneNumbers[i].label + ',VOICE:' + vcardObject.phoneNumbers[i].value);
    }

    for (let i in vcardObject.emailAddresses) {
        emailAddresses.push('EMAIL;CHARSET=UTF-8;type=' + vcardObject.emailAddresses[i].label + ',INTERNET:' + vcardObject.emailAddresses[i].value);
    }
    if (vcardObject.postalAddresses && 0 < vcardObject.postalAddresses.length) {
        for (let i in vcardObject.postalAddresses) {
            postalAddresses.push(
                'ADR;CHARSET=UTF-8;TYPE=' +
            vcardObject.postalAddresses[i].label
             + ':;;' + vcardObject.postalAddresses[i].value.street
             + ';' + vcardObject.postalAddresses[i].value.city
             + ';' + vcardObject.postalAddresses[i].value.state
             + ';' + vcardObject.postalAddresses[i].value.postalCode
             + ';' + vcardObject.postalAddresses[i].value.country
            );
        }
    }

    vCard.note = vcardObject.notes;
    phoneNumberString = getVcardString(phoneNumbers);
    emailString = getVcardString(emailAddresses);
    vcardUrls = getVcardString(urls);
    postalAddressString = getVcardString(postalAddresses);

    result = vCard.getFormattedString();
    result = result.replace(/X-SOCIALPROFILE;CHARSET=UTF-8;/g, 'X-SOCIALPROFILE;');

    splittedResult = result.split('END:VCARD');
    customString = phoneNumberString + '\n' + emailString + '\n' + postalAddressString + '\n' + vcardUrls + '\n' + dates.dateString + '\n';
    //customString = customString.replace(/CHARSET=UTF-8;/g, '');
    result = splittedResult[0] + customString + '\nEND:VCARD';

    return result;
}

export function convertCapsule(capsuleToEdit) {
    let defaultSocialProfiles = [{
        label: 'Website',
        value: {
            service:   'Website',
            toolbar:   true,
            urlString: ''
        }
    }];
    return {
        capsule: {
            name:          isObjectExists(capsuleToEdit, 'name') ? capsuleToEdit.name.trim() : '',
            category:      isObjectExists(capsuleToEdit, 'category') ? capsuleToEdit.category : '',
            id:            isObjectExists(capsuleToEdit, 'id') ? capsuleToEdit.id : '',
            groups:        isObjectExists(capsuleToEdit, 'groups') ? capsuleToEdit.groups : [],
            exploreGroups: isObjectExists(capsuleToEdit, 'exploreGroups') ? capsuleToEdit.exploreGroups : []

        },
        vCard: {
            firstName:       isObjectExists(capsuleToEdit, 'items.contact.name.givenName') ? capsuleToEdit.items.contact.name.givenName.trim() : '',
            lastName:        isObjectExists(capsuleToEdit, 'items.contact.name.familyName') ? capsuleToEdit.items.contact.name.familyName.trim() : '',
            company:         isObjectExists(capsuleToEdit, 'items.contact.organisation.organizationName') ? capsuleToEdit.items.contact.organisation.organizationName : '',
            phoneNumber:     isObjectExists(capsuleToEdit, 'items.contact.phoneNumbers') && 0 < capsuleToEdit.items.contact.phoneNumbers.length ? capsuleToEdit.items.contact.phoneNumbers : [],
            emailAddress:    isObjectExists(capsuleToEdit, 'items.contact.emailAddresses') && 0 < capsuleToEdit.items.contact.emailAddresses.length ? capsuleToEdit.items.contact.emailAddresses : [],
            locationAddress: isObjectExists(capsuleToEdit, 'items.contact.postalAddresses') && 0 < capsuleToEdit.items.contact.postalAddresses.length ? capsuleToEdit.items.contact.postalAddresses : [],
            urlAddress:      isObjectExists(capsuleToEdit, 'items.contact.urlAddresses') && 0 < capsuleToEdit.items.contact.urlAddresses.length ? capsuleToEdit.items.contact.urlAddresses : [],
            birthDay:        isObjectExists(capsuleToEdit, 'items.contact.date.birthday') ? capsuleToEdit.items.contact.date.birthday : '',
            dates:           isObjectExists(capsuleToEdit, 'items.contact.date.dates') ? capsuleToEdit.items.contact.date.dates : []

        },
        social: {
            socialProfiles: isObjectExists(capsuleToEdit, 'items.contact.socialProfiles') ? capsuleToEdit.items.contact.socialProfiles : defaultSocialProfiles,
            image:          {
                profile:   isObjectExists(capsuleToEdit, 'items.contact.image.profile') ? capsuleToEdit.items.contact.image.profile : '',
                thumbnail: isObjectExists(capsuleToEdit, 'items.contact.image.thumbnail') ? capsuleToEdit.items.contact.image.thumbnail : '',
            },

        },

        media: {
            images:    isObjectExists(capsuleToEdit, 'items.image_and_video.images') && 0 < capsuleToEdit.items.image_and_video.images.length ? capsuleToEdit.items.image_and_video.images : [],
            notes:     isObjectExists(capsuleToEdit, 'items.contact.notes') && 0 < capsuleToEdit.items.contact.notes.length ? capsuleToEdit.items.contact.notes : [''],
            documents: isObjectExists(capsuleToEdit, 'items.documents.documents') && 0 < capsuleToEdit.items.documents.documents.length ? capsuleToEdit.items.documents.documents : [],
            videos:    isObjectExists(capsuleToEdit, 'items.image_and_video.videos') && 0 < capsuleToEdit.items.image_and_video.videos.length ? capsuleToEdit.items.image_and_video.videos : [],
            audio:     isObjectExists(capsuleToEdit, 'items.audio.audio') && 0 < capsuleToEdit.items.audio.audio.length ? capsuleToEdit.items.audio.audio : []
        },
        artist: {
            bio: isObjectExists(capsuleToEdit, 'items.artist.bioOverview') ? capsuleToEdit.items.artist.bioOverview : ''
        },
        settings: {
            public: isObjectExists(capsuleToEdit, 'public') ? capsuleToEdit.public : false
        },
        payments: {
            paypal:   '',
            venmo:    '',
            cashApp:  '',
            bitcoin:  '',
            ethereum: ''
        }
    };

}
