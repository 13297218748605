import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

export default class MiniCapsule extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            showSearch:   false,
            nameToSearch: '',
        };
    }

    render() {

        return (
            <Row className="gradient-background">
                <Col lg="3" md="3" sm="3" xs="3" xl="3">
                    {
                        this.props.getThumbnailPicture(this.props.capsuleToShow)
                    }
                </Col>
                <Col lg="7" md="7" sm="7" xs="7" className="capsule-name">
                    <div>{this.props.capsuleToShow.name}</div>
                    <div>{this.props.capsuleToShow.category}</div>
                </Col>
      
                <Col lg="2" md="2" sm="2" xs="2" xl="2" className="right">
                   
                </Col>
            
            </Row>
        
        );
    }
}

MiniCapsule.propTypes = {
    capsuleToShow:       PropTypes.object,
    getThumbnailPicture: PropTypes.func

};