import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col, Form, FormGroup, Input } from 'reactstrap';
import { capsuleActions } from '../redux/actions';
import './CapsuleView.css';
import cardAddedIcon from '../../assets/images/CardAddedIcon@3x.png';

class AddCardView extends Component {

    constructor (props) {
        super(props);
        this.state = {
            cardAdded:     this.props.cardAdded,
            newGroup:      false,
            newGroupInput: ''
        };
    }

    onSaveGroup = () => {
        this.props.dispatch(capsuleActions.createCapsuleGroup(this.state.newGroupInput, [this.props.capsuleToShow.id]));
    }

    addCapsuleToGroup = (groupId) => {
        this.props.dispatch(capsuleActions.addCapsuleToGroup(groupId, this.props.capsuleToShow.id));
    }

    renderCancel = () => {
        return (
            <Col lg="12" className="share-cancel" 
                onClick={this.props.cardAdded ? 
                    () => {this.props.onMenuChange(''); this.props.changeCardAdded(false); this.props.setGotGroups(false);} : 
                    () => {this.props.onMenuChange(''); this.props.changeCardAdded(false);}
                }
            >
                { this.props.cardAdded ? 'OK' : 'Cancel' }
            </Col>
        );
    }

    renderOptions = () => {
        const groups = this.props.capsules.groups;
        return (
            <React.Fragment>
                <Row className="add-card-intro">
                    <Col>
                        Add card to a group, or create a new one.
                    </Col>
                </Row>
                {   groups &&
                    Object.keys(groups).map((i) => (
                        <Row key={i} className="add-card-option" onClick={() => this.props.changeCardAdded(true)}>
                            <Col onClick={() => this.addCapsuleToGroup(groups[i].id)}>
                                {groups[i].name}
                            </Col>
                        </Row>
                    ))
                }

                <Row className="add-card-option create-new" onClick={() => this.setState({newGroup: true})}>
                    <Col>
                        Create New Group
                    </Col>
                    {this.renderCancel()}
                </Row>
            </React.Fragment>
        );
    }

    renderNewGroup = () => {
        return ( 
            <React.Fragment>
                <Row className="add-card-intro">
                    <Col>
                    Create New Group
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form>
                            <FormGroup>
                                <Input 
                                    value={this.state.newGroupInput} 
                                    onChange={(e) => this.setState({newGroupInput: e.target.value})} 
                                    name="newGroup" 
                                    id="newGroup" 
                                    className="new-group-input" 
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                <Row className="add-card-option">
                    <Col onClick={() => {this.onSaveGroup(); this.props.changeCardAdded(true);}}>
                    Save
                    </Col>
                    {this.renderCancel()}
                </Row>
            </React.Fragment>);
    }

    renderCardAdded = () => { 
        let view; 
        if ((this.props.capsules.groupCreated || this.props.capsules.capsuleAdded) && this.props.cardAdded) {
            view = 
            
                <Row>
                    <Col>
                        <img className="img-fluid group-create-success" src={cardAddedIcon}/>
                        <div>Card Added!</div>
                    </Col>
                    {this.renderCancel()}
                </Row>
                
            ;
        }
        
        return view;
        
    }

    render() {
        let view;
        if (this.state.newGroup && !this.props.cardAdded) {
            view =  this.renderNewGroup();
        
        } else if (this.props.cardAdded) {
            view = this.renderCardAdded();
        } else {
            view =  this.renderOptions();
        }
        return (
      
            <div className="add-card-container">
                {view}
            </div>
          
        );
    }
}

AddCardView.propTypes = {
    dispatch:        PropTypes.func,
    capsule:         PropTypes.string,
    capsules:        PropTypes.object,
    user:            PropTypes.object,
    capsuleToShow:   PropTypes.object,
    cardAdded:       PropTypes.bool,
    changeCardAdded: PropTypes.func,
    onMenuChange:    PropTypes.func,
    setGotGroups:    PropTypes.func
};

function mapStateToProps(state) {
    const { capsules, user } = state;
    return {
        capsules,
        user
    };
}

const connectedAddCardPage = connect(mapStateToProps)(AddCardView);
export { connectedAddCardPage as AddCardView };

