import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import redButtonIcon from '../../assets/images/Red-button-icon.png';
import greenButtonIcon from '../../assets/images/Green-button-icon.png';

export class PublicExplore extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            exploreGroupValue: false,
            updatedExplore:    true
        };

        this.updateStatusText = 'Click circle to change status';
        this.publicText = 'Enabled';
        this.notPublicText = 'Disabled';

    }

    componentDidMount() {
        this.setPublicExploreGroupSettings();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.capsules.musicGroupSet !== this.props.capsules.musicGroupSet && this.props.capsules.musicGroupSet === true) {
            this.setPublicExploreGroupSettings();
            this.setState({updatedExplore: true});
        }

    }

    setPublicExploreGroupSettings = () => {
        let exploreGroupValue = false;

        //temp method to control music group on/off. Requires existing music group ID to remain the same. If deleted and re-added, vs disabled and enabled, this module won't control the music group. Group can be renamed without impact.
        for (let i in this.props.capsules.exploreGroups) {
            if (this.props.capsules.exploreGroups[i].id === '2bcf310e-94db-4428-afad-a650adb5a22e') {
                exploreGroupValue = true;
            }
        }

        this.setState({exploreGroupValue: exploreGroupValue});

    }

    setExploreGroupValue = (value) => {
        this.setState({updatedExplore: false});
        this.props.setExploreGroupValue(value);
    }

    renderUpdatePublicExplore = () => {
        return (
            <Container className="publish-background">
                <div className="verify-users-container stop-scroll">
                    <Row className="group-element lightgrey">
                        <Col><span>Music Group</span></Col>
                    </Row>
                    <Row className="group-element left-banned-box-spacing">
                        <Col className="group-list-divider letter-line color-black">
                            <img className="divider-circle" src={greenButtonIcon}/>
                            {this.publicText}
                            <img className="divider-circle" src={redButtonIcon}/>
                            {this.notPublicText}
                        </Col>
                    </Row>
                    <Row className="group-element">
                        <Col><span className="status-italics">{this.updateStatusText}</span></Col>
                    </Row>
                </div>
                <div className="verify-users-container">
                    {this.renderPublicExplore()}
                </div>
            </Container>
        );
    }

    renderPublicExplore = () => {
        return (
            <React.Fragment>

                {/*{Object.keys(this.state.exploreSettings).map((i) =>
                <React.Fragment key={i}> */}
                <Row className="group-element left-value-verified2">
                    <Col className="lightgrey">
                        Music
                    </Col>
                    <Col className='right-value-verified2'>
                        {this.state.updatedExplore ?
                            this.state.exploreGroupValue ?
                                <span onClick={() => this.setExploreGroupValue(!this.state.exploreGroupValue)} className="circle-green hover">
                                </span>
                                :
                                <span onClick={() => this.setExploreGroupValue(!this.state.exploreGroupValue)} className="circle-red hover">
                                </span>
                            :
                            this.state.exploreGroupValue ?
                                <span className="circle-green">
                                </span>
                                :
                                <span className="circle-red">
                                </span>
                        }
                    </Col>
                </Row>
                {/*</React.Fragment>
                )}*/}
            </React.Fragment>
        );
    }

    render() {

        return (
            <Container className="container-width">
                {this.renderUpdatePublicExplore()}
            </Container>);
    }

}

PublicExplore.propTypes = {
    renderHeader:         PropTypes.func,
    dispatch:             PropTypes.func,
    backToGroups:         PropTypes.func,
    exploreGroups:        PropTypes.array,
    setExploreGroupValue: PropTypes.func,
    capsules:             PropTypes.object

};
