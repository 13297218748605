import { Container, Row, Col, Collapse } from 'reactstrap';
import React from 'react';
import PropTypes from 'prop-types';
import backButton from '../../assets/images/Close@2x.png';
import Switch from 'react-switch';
import {ModalView} from './ModalView';

export class GroupEdit extends React.Component {

    constructor (props) {
        super(props);
        this.addMoreText = 'Add more groups';
        this.groupsText = 'Groups';

        this.state = {
            showNoPublicModal: false,
        };
    }

    getSubgroups = (groups, isTop) => {
        let subgroups = [];
        let subgroupObject = {};

        for (let i in groups) {

            if (groups[i].child && groups[i].child.length > 0) {
                for ( let j in groups[i].child) {
                    subgroupObject = groups[i].child[j];
                    subgroupObject.rootGroup = isTop ? groups[i].name : groups[i].rootGroup !== '' ? groups[i].rootGroup : '';
                    subgroupObject.path = (
                        <React.Fragment>
                            <div className="word-break">
                                {isTop ? subgroupObject.rootGroup + ' -> ' : subgroupObject.rootGroup + ' -> ... ->'}
                                {groups[i].child[j].name}</div>
                        </React.Fragment>
                    );
                    subgroups.push(subgroupObject);

                    if (subgroupObject.child && subgroupObject.child.length > 0) {
                        subgroups = subgroups.concat(this.getSubgroups([subgroupObject]));
                    }
                }
            }

        }

        return subgroups;
    }

    renderGroups = () => {

        let groupsOfCapsule = this.props.groupsOfCapsule || [];
        let groups = this.props.groups || [];
        let exploreGroupsOfCapsule = this.props.exploreGroupsOfCapsule || [];
        let exploreGroups = this.props.exploreGroups || [];
        exploreGroups = exploreGroups.concat(this.getSubgroups(exploreGroups, true));
        let groupsToShowWithoutSubGroups = groups.concat(exploreGroups);
        let groupsToShow = groupsToShowWithoutSubGroups;
        let sortedUserGroups = [];
        let sortedExploreGroups = [];
        let viewToRender = null;

        for (let i in groupsToShow) {
            if (groupsOfCapsule.indexOf(groupsToShow[i].id) > -1) {
                sortedUserGroups.push(groupsToShow[i]);
            }

            if (exploreGroupsOfCapsule.indexOf(groupsToShow[i].id) > -1) {
                sortedExploreGroups.push(groupsToShow[i]);
            }
        }

        sortedUserGroups = this.sortByName(sortedUserGroups);
        sortedExploreGroups = this.sortByName(sortedExploreGroups);

        groupsToShow = sortedUserGroups.concat(sortedExploreGroups);

        viewToRender = (
            <React.Fragment>
                {Object.keys(groupsToShow).map((i) => (
                    <React.Fragment key={i}>

                        <Row className="mb-1">

                            <Col xs="9" sm="9" md="9" lg="10">
                                {groupsToShow[i].path ? groupsToShow[i].path : groupsToShow[i].name}
                            </Col>
                            <Col className="mx-auto" xs="1" sm="1" md="1" lg="1">
                                <span className="hover delete" onClick={() => this.props.onRemoveGroup(groupsToShow[i].id, groupsToShow[i].ownerId ? false : true, true)}>X</span>
                            </Col>
                        </Row>

                    </React.Fragment>
                ))}
                <Row className="pb-2 pt-2">
                    <Col className="navigation-button" onClick={() => this.props.openGroups()}>{this.addMoreText}</Col>
                </Row>
            </React.Fragment>
        );

        return viewToRender;
    }

    onPublicChange = () => {
        const e = {
            target: {
                name:  'public',
                value: !this.props.isPublic
            }
        };

        if (this.props.user.canPublishCapsule === true && !this.props.forceNonPublic) {
            this.props.onCapsuleChange(e, 'settings');
        } else if (this.props.user.canPublishCapsule === false && this.props.isPublic === true) {
            this.props.onCapsuleChange(e, 'settings');
        } else {
            this.setState({showNoPublicModal: true});
        }

    }

    sortByName = (groups) => {
        let sorted = groups;

        sorted.sort(function (a, b) {

            let aName = a.path ? a.path : a.name.toLowerCase();
            let bName = b.path ? b.path : b.name.toLowerCase();

            if (aName < bName) {
                return -1;
            }

            if (aName > bName) {
                return 1;
            }
            return 0;

        });

        return sorted;

    }

    render() {
        return (
            !this.props.isSectionOpen ?
                <Container className="profile-closed mt-10">
                    <Row onClick={() => this.props.toggleMenu('groupCategory')}>
                        <Col >
                            <div onClick={() => this.props.toggleMenu('groupCategory')}>
                                <span>{this.groupsText}</span>
                            </div>

                        </Col>
                        <Col xs="2" className="right"><img src={backButton} className="hover section-button"/></Col>
                    </Row>
                </Container>
                :
                <Collapse isOpen={this.props.isSectionOpen}>
                    <ModalView isOpen={this.state.showNoPublicModal}
                        toggle={() => this.setState({showNoPublicModal: !this.state.showNoPublicModal})}
                        title="You do not have permission to make a capsule public"
                        modalType="noPublic"
                    />
                    <Container className="profile-card mt-10" >
                        <Row onClick={() => this.props.toggleMenu('groupCategory')} className="mb-2 hover">
                            <Col className="font-weight-bold color-black">
                                <span>{this.groupsText}</span>
                            </Col>
                            <Col xs="2" className="right">
                                <img src={backButton} className="hover button-up section-button"/>
                            </Col>

                        </Row>
                        {this.renderGroups()}
                        <Row className="settings-row pb-3 my-auto">

                            <Col className="my-auto" xs="8" sm="8" md="8" lg="8">
                                <div>Make Capsule Public</div>
                            </Col>
                            <Col className="my-auto right" xs="4" sm="4" md="4" lg="4">
                                <Switch
                                    checked={this.props.isPublic}
                                    onChange={(e) => this.onPublicChange(e)}
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={20}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={5}
                                    width={38}
                                    className="react-switch"
                                    id="material-switch"
                                />
                            </Col>

                        </Row>
                    </Container>
                </Collapse>
        );
    }
}

GroupEdit.propTypes = {
    toggleMenu:             PropTypes.func,
    isSectionOpen:          PropTypes.bool,
    openGroups:             PropTypes.func,
    groups:                 PropTypes.array,
    groupsOfCapsule:        PropTypes.array,
    exploreGroupsOfCapsule: PropTypes.array,
    exploreGroups:          PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onRemoveGroup:          PropTypes.func,
    onSaveGroup:            PropTypes.func,
    isPublic:               PropTypes.bool,
    forceNonPublic:         PropTypes.bool,
    onCapsuleChange:        PropTypes.func,
    user:                   PropTypes.object,
};
