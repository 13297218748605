import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { capsuleActions } from '../../redux/actions';

export class ShareBackCapsuleView extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            name:      this.props.user && this.props.user.name ? this.props.user.name : '',
            phone:     this.props.user && this.props.user.phone ? this.props.user.phone : '',
            email:     this.props.user && this.props.user.email ? this.props.user.email : '',
            shareSent: false

        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.capsules.shareSent === false && this.props.capsules.shareSent === true) {
            this.setState({shareSent: true});
        }
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    shareBack = (capsuleId) => {
        this.props.dispatch(capsuleActions.shareBackCapsule(window.location.href.split('form=')[1], capsuleId));
    }

    renderCapsuleList = () => {
        let capsules = this.props.user.userCapsules;

        return (
            <Container className="scrollable">

                {Object.keys(capsules).map((i) => (
                    <Row key={i}>
                        <Col className="text-dark" xs="8" sm="8" md="8" lg="8">
                            {capsules[i].name}
                        </Col>
                        <Col class="text-right" xs="4" sm="4" md="4" lg="4">
                            <span className="navigation-button text-dark" onClick={() => this.shareBack(capsules[i].id)}>Send</span>
                        </Col>
                    </Row>

                )
                )
                }

            </Container>
        );
    }

    renderSuccess = () => {
        return (
            <Container>
                <Row><Col className="text-dark">Your capsule sent back successfully!</Col></Row>
                <Row className="text-center button-row">

                    <Col>
                        <button type="button" className="btn btn-light create-button btn-sm"
                            onClick={() => this.props.toggle()}>{'OK'}</button>
                    </Col>
                </Row>
            </Container>

        );
    }

    render() {
        if (!this.state.shareSent) {
            return this.renderCapsuleList();
        } else {
            return this.renderSuccess();
        }
    }

}

ShareBackCapsuleView.propTypes = {
    dispatch:        PropTypes.func,
    user:            PropTypes.object,
    capsules:        PropTypes.array,
    toggle:          PropTypes.func,
    isSectionOpen:   PropTypes.bool,
    onCapsuleChange: PropTypes.func,
    paymentData:     PropTypes.object
};
