export const validateEmail = (value) => {
    let isValid = false;

    // eslint-disable-next-line no-useless-escape
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || value === '') {
        isValid = true;
    }

    return isValid;
};

export const validatePhoneNumber = (e, value, obj, fullValue) => {
    let isValid = true;
    const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
    try {
        let num = phoneUtil.parseAndKeepRawInput(fullValue, obj.iso2.toUpperCase());

        isValid = phoneUtil.isValidNumberForRegion(num, obj.iso2.toUpperCase());
    } catch (e) {
        isValid = false;
    }

    if (fullValue == '' || !fullValue) {
        isValid = true;
    }

    return isValid;
};