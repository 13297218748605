import { sortAlphabetically, removeCapsule } from '../../../utils/Helpers';

export function user(state = {}, action) {
    switch (action.type) {
        case 'GET_LOGIN_WITH_KEY_REQUEST':
        case 'GET_USER_REQUEST':
            return {
                ...state,
                loaded: false,
            };
        case 'LOGIN_REQUEST':
            return {
                ...state,
                loaded:     false,
                publicPage: false
            };
        case 'GET_USER_SUCCESS':
            localStorage.setItem('userId', action.response.id);
            return {
                ...state,
                ...action.response,
                token:     action.token,
                loaded:    true,
                withToken: true,
                withKey:   false
            };
        case 'GET_LOGIN_WITH_KEY_SUCCESS':
            return {
                ...state,
                ...action.response,
                withToken:  false,
                withKey:    true,
                publicPage: state.publicPage || false
            };
        case 'LOGIN_SUCCESS':
            return {
                ...action.response,
                login:      true,
                withToken:  true,
                withKey:    false,
                loaded:     true,
                publicPage: state.publicPage
            };
        case 'GET_ANONYMOUS_SPAWN_SUCCESS':
        case 'GET_ANONYMOUS_SPAWN_REQUEST':
            return {
                exploreCount:     state.exploreCount,
                mostRecentCount:  state.mostRecentCount,
                verifyError:      state.verifyError,
                ...action.response,
                anonymousSpawned: true,
                error:            {}
            };
        case 'GET_LOGIN_WITH_KEY_ERROR':
        case 'LOGIN_ERROR':
        case 'GET_USER_ERROR':
            return {
                ...state,
                error:  action.error.messages && action.error.messages.error !== 'undefined' ? action.error.messages.error : null,
                loaded: false
            };
        case 'GET_ANONYMOUS_SPAWN_ERROR':
            return {
                ...state,
                error:            action.error.messages.error !== 'undefined' ? action.error.messages.error : null,
                anonymousSpawned: false //lds was set to true by attila, check it out later, seems ok since updating to false.
            };

        case 'SET_CAPSULES_OF_GROUP_CONFIG':
            return {
                ...state,
                userGroupCapsules: setCapsules(action.id, action.content, state.userGroupCapsules)
            };

        case 'UPDATE_USER_CAPSULES_REQUEST':
            return {
                ...state
            };
        case 'UPDATE_USER_CAPSULES_SUCCESS':
            return {
                ...state,
                userCapsules: action.response
            };
        case 'GET_CAPSULES_REQUEST':
            return {
                ...state,
                //userCapsules:          state.items,
                capsules:              state.capsules,
                capsulesLoaded:        state.capsulesLoaded || false,
                //userGroupCapsules:     [],
                capsuleDeleted:        false,
                capsuleGotAfterCreate: false,
                error:                 {}
            };
        case 'GET_CAPSULES_SUCCESS':
            return {
                ...state,
                userCapsules:          sortAlphabetically(action.response),
                userGroupCapsules:     getCapsulesByGroup(action.response, state.userGroupCapsules),
                capsulesLoaded:        true,
                capsuleCreated:        false,
                capsuleDeleted:        false,
                capsuleGotAfterCreate: false
            };
        case 'GET_CAPSULES_AFTER_CREATE_SUCCESS':
            return {
                ...state,
                userCapsules:          sortAlphabetically(action.response),
                capsulesLoaded:        true,
                capsuleDeleted:        false,
                capsuleGotAfterCreate: true
            };
        case 'GET_CAPSULES_ERROR':
            return {
                ...state,
                userCapsules:   action.error,
                capsulesLoaded: false,
                capsuleDeleted: false
            };
        case 'CREATE_CAPSULE_REQUEST':
            return {
                ...state,
                createdCapsule: state.items,
                capsuleCreated: false,
                saveInProgress: true,
                error:          {}
            };
        case 'CREATE_CAPSULE_SUCCESS':
            return {
                ...state,
                userCapsules:   sortAlphabetically([...state.userCapsules, action.response]),
                createdCapsule: action.response,
                saveInProgress: false,
                capsuleCreated: true
            };
        case 'CREATE_CAPSULE_ERROR':
            return {
                ...state,
                createdCapsule: action.error,
                capsuleCreated: false,
                saveInProgress: false
            };

        case 'UPDATE_IAV_REQUEST':
        case 'UPDATE_CAPSULE_REQUEST' :
            return {
                ...state,
                capsuleUpdated: false,
                saveInProgress: true,
                error:          {}
            };
        case 'UPDATE_IAV_SUCCESS':
        case 'UPDATE_CAPSULE_SUCCESS' :
        case 'UPDATE_CAPSULE_EXP_SUCCESS' :
            state = refreshCapsuleLists(state, action.response);
            return {
                ...state,
                updatedCapsule: action.response,
                capsuleUpdated: true,
                saveInProgress: false
            };
        case 'UPDATE_IAV_ERROR':
        case 'UPDATE_CAPSULE_ERROR' :
            return {
                ...state,
                error:          action.error,
                capsuleUpdated: false,
                saveInProgress: false
            };

        case 'UPLOAD_FILES_REQUEST':
            if (action.fileType === 'video') {
                return {
                    ...state,
                    picture:               false,
                    document:              false,
                    audio:                 false,
                    video:                 false,
                    fileUploaded:          false,
                    uploadInProgress:      true,
                    videoUploadInProgress: true,
                    error:                 {}
                };
            } else if (action.fileType === 'picture') {
                return {
                    ...state,
                    picture:                 false,
                    document:                false,
                    audio:                   false,
                    video:                   false,
                    fileUploaded:            false,
                    uploadInProgress:        true,
                    pictureUploadInProgress: true,
                    error:                   {}
                };
            } else if (action.fileType === 'audio') {
                return {
                    ...state,
                    picture:               false,
                    document:              false,
                    audio:                 false,
                    video:                 false,
                    fileUploaded:          false,
                    uploadInProgress:      true,
                    audioUploadInProgress: true,
                    error:                 {}
                };
            } else if (action.fileType === 'document') {
                return {
                    ...state,
                    picture:                  false,
                    document:                 false,
                    audio:                    false,
                    video:                    false,
                    fileUploaded:             false,
                    uploadInProgress:         true,
                    documentUploadInProgress: true,
                    error:                    {}
                };
            } else if (action.fileType === 'photo') {
                return {
                    ...state,
                    picture:               false,
                    document:              false,
                    audio:                 false,
                    video:                 false,
                    fileUploaded:          false,
                    uploadInProgress:      true,
                    photoUploadInProgress: true,
                    error:                 {}
                };
            } else {
                return {
                    ...state,
                    picture:          false,
                    document:         false,
                    audio:            false,
                    video:            false,
                    uploadInProgress: true,
                    fileUploaded:     false,
                    error:            {}
                };
            }
        case 'UPLOAD_FILES_PICTURE_SUCCESS':
            return {
                ...state,
                newFileId:               action.response,
                picture:                 true,
                document:                false,
                audio:                   false,
                video:                   false,
                fileUploaded:            true,
                pictureUploadInProgress: false,
                uploadInProgress:        false
            };
        case 'UPLOAD_FILES_DOCUMENT_SUCCESS':
            return {
                ...state,
                newFileId:                action.response,
                document:                 true,
                picture:                  false,
                audio:                    false,
                video:                    false,
                fileUploaded:             true,
                documentUploadInProgress: false,
                uploadInProgress:         false
            };
        case 'UPLOAD_FILES_AUDIO_SUCCESS':
            return {
                ...state,
                newFileId:             action.response,
                audio:                 true,
                picture:               false,
                document:              false,
                video:                 false,
                fileUploaded:          true,
                uploadInProgress:      false,
                audioUploadInProgress: false
            };
        case 'UPLOAD_FILES_VIDEO_SUCCESS':
            return {
                ...state,
                newFileId:             action.response,
                video:                 true,
                picture:               false,
                document:              false,
                audio:                 false,
                fileUploaded:          true,
                uploadInProgress:      false,
                videoUploadInProgress: false
            };
        case 'UPLOAD_FILES_PHOTO_SUCCESS':
            return {
                ...state,
                newFileId:             action.response,
                video:                 false,
                fileUploaded:          true,
                uploadInProgress:      false,
                photoUploadInProgress: false,
                picture:               true
            };
        case 'UPLOAD_FILES_ERROR':
            return {
                ...state,
                error:            action.error,
                fileUploaded:     false,
                uploadInProgress: false,
            };

        case 'GET_FILES_REQUEST':
            return {
                ...state,
                gotFiles: false,
                error:    {}
            };
        case 'GET_FILES_SUCCESS':
            return {
                ...state,
                fileId:   action.response,
                gotFiles: true
            };
        case 'GET_FILES_ERROR':
            return {
                ...state,
                error:    action.error,
                gotFiles: false
            };
        case 'GET_FILE_REQUEST':
            return {
                ...state,
                gotFile:       false,
                requestedFile: {},
                error:         {}
            };
        case 'GET_FILE_SUCCESS':
            return {
                ...state,
                requestedFile: action.response,
                gotFile:       true
            };
        case 'GET_FILE_ERROR':
            return {
                ...state,
                error:   action.error,
                gotFile: false
            };
        case 'DELETE_FILE_REQUEST':
            return {
                ...state,
                fileDeleted: false,
                error:       {}
            };
        case 'DELETE_FILE_SUCCESS':
            return {
                ...state,
                fileDeleted: true
            };
        case 'DELETE_FILE_ERROR':
            return {
                ...state,
                error:       action.error,
                fileDeleted: false
            };
        case 'ADD_FILE_TO_CAPSULE_REQUEST':
            return {
                ...state,
                fileAdded: false,
                error:     {}
            };
        case 'ADD_FILE_TO_CAPSULE_SUCCESS':
            return {
                ...state,
                fileAdded: true
            };
        case 'ADD_FILE_TO_CAPSULE_ERROR':
            return {
                ...state,
                error:     action.error,
                fileAdded: false
            };
        case 'DELETE_CAPSULE_REQUEST':
            return {
                ...state,
                capsuleDeleted: false,
                error:          {}
            };
        case 'DELETE_CAPSULE_SUCCESS':
            state=removeBCapsule(state, action.capsuleId);
            return {
                ...state,
                userCapsules:   removeCapsule(action.capsuleId, state.userCapsules),
                capsuleDeleted: true
            };
        case 'DELETE_CAPSULE_ERROR':
            return {
                ...state,
                error:          action.error,
                capsuleDeleted: false
            };
        case 'SIGN_UP_REQUEST':
            return {
                ...state,
                userSignedUp: false,
                error:        {}
            };
        case 'SIGN_UP_SUCCESS':
            return {
                ...state,
                signedUpId:   action.response,
                userSignedUp: true
            };
        case 'SIGN_UP_ERROR':
            return {
                ...state,
                error:        action.error,
                userSignedUp: false
            };
        case 'GET_FIREBASE_STATUS_REQUEST':
            return {
                ...state,
                gettingFireBase: true,
                error:           {}
            };
        case 'GET_FIREBASE_STATUS_SUCCESS':
            var newStatusObject = {...state.fireBaseStatus};
            newStatusObject[action.id] = action.response;
            // eslint-disable-next-line no-console
            //console.log('viero success action.id=', action.id);
            // eslint-disable-next-line no-console
            //console.log('viero success newStatusObject=', newStatusObject);
            return {
                ...state,
                fireBaseStatus:  newStatusObject,
                gettingFireBase: false
            };
        case 'GET_FIREBASE_STATUS_ERROR':
            return {
                ...state,
                error:           action.error,
                gettingFireBase: false
            };
        case 'VERIFY_EMAIL_REQUEST':
            return {
                ...state,
                emailVerified: false,
                verifyError:   {}
            };
        case 'VERIFY_EMAIL_SUCCESS':
            return {
                ...state,
                verifiedResponse: action.response,
                emailVerified:    true
            };
        case 'VERIFY_EMAIL_ERROR':
            return {
                ...state,
                verifyError:   action.error,
                emailVerified: true
            };
        case 'VERIFY_PIN_REQUEST':
            return {
                ...state,
                pinVerified:  false,
                verifyingPin: true,
                verifyError:  {}
            };
        case 'VERIFY_PIN_SUCCESS':
            return {
                ...state,
                verifiedResponse: action.response,
                pinVerified:      true,
                verifyingPin:     false,
                userSignedUp:     false
            };
        case 'VERIFY_PIN_ERROR':
            return {
                ...state,
                verifyError:  action.error,
                pinVerified:  false,
                verifyingPin: 'error',
            };
        case 'SET_UPDATED_USER_BANNED':
            return {
                ...state,
                updatedBannedValue: action.response
            };
        case 'SET_BANNED_VALUE_REQUEST':
            return {
                ...state,
                updatedBannedValue: false,
                error:              {}
            };
        case 'SET_BANNED_VALUE_SUCCESS':
            return {
                ...state,
                updatedBannedValue: true,
                bannedResponse:     action.response,
                bannedUserId:       action.userId,
                banned:             action.canPublishValue
            };
        case 'SET_BANNED_VALUE_ERROR':
            return {
                ...state,
                updatedBannedValue: false,
                error:              action.error
            };
        case 'SET_GOT_VERIFIED_USERS':
            return {
                ...state,
                gotVerifiedUsers: action.response
            };
        case 'SET_UPDATED_USER_CAN_PUBLISH':
            return {
                ...state,
                updatedCanPublishValue: action.response
            };
        case 'GET_VERIFIED_USERS_REQUEST':
            return {
                ...state,
                gotVerifiedUsers: false,
                error:            {}
            };
        case 'GET_VERIFIED_USERS_SUCCESS':
            return {
                ...state,
                gotVerifiedUsers: true,
                verifiedUsers:    action.response
            };
        case 'GET_VERIFIED_USERS_ERROR':
            return {
                ...state,
                gotVerifiedUsers: false,
                error:            action.error
            };

        case 'SET_CAN_PUBLISH_VALUE_REQUEST':
            return {
                ...state,
                updatedCanPublishValue: false,
                error:                  {}
            };
        case 'SET_CAN_PUBLISH_VALUE_SUCCESS':
            return {
                ...state,
                updatedCanPublishValue: true,
                canPublishResponse:     action.response,
                publishUserId:          action.userId,
                canPublish:             action.canPublishValue
            };
        case 'SET_CAN_PUBLISH_VALUE_ERROR':
            return {
                ...state,
                updatedCanPublishValue: false,
                error:                  action.error
            };
        case 'LOGOUT_REQUEST':
            return {
                exploreCount:     state.exploreCount,
                anonymousSpawned: true,
                withKey:          false,
                withToken:        false,
                error:            {},
                publicPage:       false
            };
        case 'LOGOUT_SUCCESS':
            return {
                anonymousSpawned: false,
                withKey:          false,
                withToken:        false,
                isLoggedOut:      true,
            };
        case 'LOGOUT_ERROR':
            return {
                error:          action.error,
                capsulesLoaded: false,
                withKey:        false,
                withToken:      false
            };
        case 'CLEAR_ERROR':
            return {
                ...state,
                error:        '',
                userSignedUp: false

            };
        case 'DELETE_MY_ACCOUNT_REQUEST':
            return {
                ...state,
                deletedAccount: false
            };
        case 'DELETE_MY_ACCOUNT_SUCCESS':
            return {
                ...state,
                deleteAccount:  action.response,
                deletedAccount: true
            };
        case 'DELETE_MY_ACCOUNT_ERROR':
            return {
                ...state,
                deleteAccountError: action.error,
                deletedAccount:     false
            };
        case 'CANCEL_DELETE_MY_ACCOUNT_REQUEST':
            return {
                ...state,
                cancelDeleteAccount: false
            };
        case 'CANCEL_DELETE_MY_ACCOUNT_SUCCESS':
            return {
                ...state,
                cancelDeleteAccountSuccess: action.response,
                cancelDeleteAccount:        true
            };
        case 'CANCEL_DELETE_MY_ACCOUNT_ERROR':
            return {
                ...state,
                cancelDeleteAccountError: action.error,
                cancelDeleteAccount:      false
            };
        case 'SUSPEND_MY_ACCOUNT_REQUEST':
            return {
                ...state,
                suspendedAccount: false
            };
        case 'SUSPEND_MY_ACCOUNT_SUCCESS':
            return {
                ...state,
                suspendAccount:   action.response,
                suspendedAccount: true
            };
        case 'SUSPEND_MY_ACCOUNT_ERROR':
            return {
                ...state,
                suspendAccountError: action.error,
                suspendedAccount:    false
            };
        case 'SET_USER_OBJECT':
            var user;
            if (localStorage.getItem('MUUserLogin'))  {
                user = JSON.parse(localStorage.getItem('MUUserLogin')).token;
            } else {
                user = JSON.parse(localStorage.getItem('MUUser')).token;
            }
            return {
                ...state,
                token: user
            };
        case 'GENERATE_PREVIEW_REQUEST':
            return {
                ...state,
                previewGenerated: false,
                error:            {}
            };
        case 'GENERATE_PREVIEW_SUCCESS':
            return {
                ...state,
                previewGenerated: true
            };
        case 'GENERATE_PREVIEW_ERROR':
            return {
                ...state,
                previewGenerated: false
            };
        case 'CONNECT_SENDGRID_REQUEST':
            return {
                ...state,
                sendgridConnected: false,
                error:             {}
            };
        case 'CONNECT_SENDGRID_SUCCESS':
            return {
                ...state,
                sendgridConnected: true
            };
        case 'CONNECT_SENDGRID_ERROR':
            return {
                ...state,
                error:             action.error,
                sendgridConnected: false
            };
        case 'RESET_PASSWORD_REQUEST':
            return {
                ...state,
                passwordReset: false,
                error:         {}
            };
        case 'RESET_PASSWORD_SUCCESS':
            return {
                ...state,
                passwordReset: true,
                passwordSet:   false,
                signedUpId:    action.response
            };
        case 'RESET_PASSWORD_ERROR':
            return {
                ...state,
                error:         action.error,
                passwordReset: false
            };
        case 'UPDATE_EMAIL_REQUEST':
            return {
                ...state,
                updateEmailSent: false,
                error:           {}
            };
        case 'UPDATE_EMAIL_SUCCESS':
            return {
                ...state,
                updateEmailSent: true,
            };
        case 'UPDATE_EMAIL_ERROR':
            return {
                ...state,
                error:           action.error,
                updateEmailSent: false
            };
        case 'UPDATE_PHONE_REQUEST':
            return {
                ...state,
                updatePhoneSent: false,
                error:           {}
            };
        case 'UPDATE_PHONE_SUCCESS':
            return {
                ...state,
                updatePhoneSent: true,
            };
        case 'UPDATE_PHONE_ERROR':
            return {
                ...state,
                error:           action.error,
                updatePhoneSent: false
            };
        case 'SET_PASSWORD_REQUEST':
            return {
                ...state,
                passwordSet: false,
                error:       {}
            };
        case 'SET_PASSWORD_SUCCESS':
            return {
                ...state,
                passwordSet:   true,
                passwordReset: false
            };
        case 'SET_PASSWORD_ERROR':
            return {
                ...state,
                passwordSet: false
            };
        case 'SET_DELETED': {
            return {
                ...state,
                capsuleDeleted: action.response
            };
        }
        case 'SET_ACCOUNT_DELETE_STATUS': {
            return {
                ...state,
                deletedAccount: action.response
            };
        }
        case 'SET_ACCOUNT_CANCEL_DELETE_STATUS': {
            return {
                ...state,
                cancelDeleteAccount: action.response
            };
        }
        case 'SET_ACCOUNT_SUSPEND_STATUS': {
            return {
                ...state,
                suspendedAccount: action.response
            };
        }
        case 'CONFIG_USER_EXPLORE_REQUEST':
            return {
                ...state,
                userConfigured: false,
                error:          {}
            };
        case 'CONFIG_USER_EXPLORE_SUCCESS':
            return {
                ...state,
                config:         action.groups,
                userConfigured: true
            };
        case 'SET_GROUP_CONFIG':
            var newConfig = state.config;
            newConfig['my_category'].push({groupId: action.id, order: state.config['my_category'].length + 1, display: true, name: action.name});
            return {
                ...state,
                config: newConfig
            };
        case 'CONFIG_USER_EXPLORE_ERROR':
            return {
                ...state,
                userConfigured: false,
                error:          action.error
            };
        case 'CONFIG_USER_GROUPS_REQUEST':
            return {
                ...state,
                userConfigured: false,
                error:          {}
            };
        case 'CONFIG_USER_GROUPS_SUCCESS':
            return {
                ...state,
                config:         action.groups,
                userConfigured: true
            };
        case 'CONFIG_USER_GROUPS_ERROR':
            return {
                ...state,
                userConfigured: false,
                error:          action.error
            };
        case 'GET_COUNT_REQUEST':
        case 'GET_USER_COUNT_REQUEST':
            return {
                ...state,
                gotCount: false,
                error:    {}
            };
        case 'GET_COUNT_SUCCESS':
            return {
                ...state,
                exploreCount: action.response,
                gotCount:     true
            };
        case 'GET_USER_COUNT_SUCCESS':
            return {
                ...state,
                userCapsuleCount: action.response,
                gotCount:         true
            };
        case 'GET_COUNT_ERROR':
            return {
                ...state,
                error:    action.error,
                gotCount: false
            };
        case 'GET_MOST_RECENT_COUNT_SUCCESS':
            return {
                ...state,
                mostRecentCount: action.response,
                gotCount:        true
            };
        case 'GET_MOST_RECENT_COUNT_ERROR':
            return {
                ...state,
                error:    action.error,
                gotCount: false
            };
        case 'REFRESH_TOKEN_REQUEST':
            return {
                ...state,
                refreshingToken: true,
                error:           {}
            };
        case 'REFRESH_TOKEN_SUCCESS':
            return {
                ...state,
                refreshingToken: false
            };
        case 'REFRESH_TOKEN_ERROR':
            return {
                ...state,
                refreshingToken: false,
                error:           action.error
            };
        case 'SET_USER_PROPS_DEFAULT':
            return {
                ...state,
                pinVerified:  false,
                userSignedUp: null,
                error:        {}
            };
        case 'GET_PUBLIC_ACCOUNT_REQUEST':
            return {
                ...state,
                publicPage: 'null'
            };
        case 'GET_PUBLIC_ACCOUNT_ERROR':
            return {
                ...state,
                publicPage: false,
                error:      action.error,
            };
        case 'GET_PUBLIC_ACCOUNT_SUCCESS': {
            return {
                ...state,
                userGroupCapsules: getPublicCapsulesByGroup(action.response.capsules),
                publicPage:        true,
                publicUserId:      action.response.userId,
                config:            action.response.config
            };
        }
        case 'SET_MAIN_CAPSULE_REQUEST':
            return {
                ...state,
                mainSet: false
            };
        case 'SET_MAIN_CAPSULE_ERROR':
            return {
                ...state,
                mainSet: false,
                error:   action.error,
            };
        case 'SET_MAIN_CAPSULE_SUCCESS': {
            return {
                ...state,
                mainSet: true,

            };
        }
        case 'SYNC_GOOGLE_CONTACTS_REQUEST':
            return {
                ...state,
                syncingContacts: true
            };
        case 'SYNC_GOOGLE_CONTACTS_ERROR':
            return {
                ...state,
                syncingContacts: false,
                error:           action.error,
            };
        case 'SYNC_GOOGLE_CONTACTS_SUCCESS': {
            return {
                ...state,
                syncingContacts: 'synced',
            };
        }
        case 'GET_GOOGLE_AUTH_LINK_REQUEST':
            return {
                ...state,
                authUrl: ''
            };
        case 'GET_GOOGLE_AUTH_LINK_ERROR':
            return {
                ...state,
                authUrl: '',
                error:   action.error,
            };
        case 'GET_GOOGLE_AUTH_LINK_SUCCESS': {
            return {
                ...state,
                authUrl: action.response.url
            };
        }
        case 'GET_GROUP_SHARE_TOKEN_REQUEST':
            return {
                ...state,
                groupShareToken: ''
            };
        case 'GET_GROUP_SHARE_TOKEN_ERROR':
            return {
                ...state,
                groupShareToken: '',
                error:           action.error,
            };
        case 'GET_GROUP_SHARE_TOKEN_SUCCESS': {
            return {
                ...state,
                groupShareToken: action.response.shareToken
            };
        }
        case 'GET_SHARED_GROUPS_REQUEST':
            return {
                ...state,
                sharedGroups:    [],
                gotSharedGroups: false
            };
        case 'GET_SHARED_GROUPS_ERROR':
            return {
                ...state,
                sharedGroups:    [],
                gotSharedGroups: false,
                error:           action.error,
            };
        case 'GET_SHARED_GROUPS_SUCCESS': {
            return {
                ...state,
                userGroupCapsules: addGroupsToList(action.response, state.userGroupCapsules),
                sharedGroups:      action.response,
                gotSharedGroups:   true
            };
        }

        default:
            return state;
    }
}

function getPublicCapsulesByGroup(groups) {
    let result = [];

    for (let i in groups) {

        result.push({id: i, content: groups[i]});

    }
    return result;
}

function addGroupsToList(groups, list) {
    let modifiedList = list || [];
    let content;
    for (let i in groups) {
        content = sortAlphabetically(groups[i].capsules);
        modifiedList.push({id: groups[i].id, content});
    }

    return modifiedList;
}

function getCapsulesByGroup(capsules, groups) {
    let result = [];
    let groupCreated = [];
    for (let i in capsules) {
        for (let j in capsules[i].groups) {
            if (groupCreated.indexOf(capsules[i].groups[j]) === -1) {
                result.push({id: capsules[i].groups[j], content: [capsules[i]]});
                groupCreated.push(capsules[i].groups[j]);
            } else {
                result[getIndexOfGroup(capsules[i].groups[j], result)].content = [...result[getIndexOfGroup(capsules[i].groups[j], result)].content, capsules[i]];
            }
        }

    }

    result = mergeGroups(result, groups);

    return result;
}

function setCapsules(id, content, capsulesOfGroup) {
    let modifiedCapsulesOfGroup = capsulesOfGroup;
    for (let i in modifiedCapsulesOfGroup) {
        if (modifiedCapsulesOfGroup[i].id === id) {
            modifiedCapsulesOfGroup[i].content = content;
            return modifiedCapsulesOfGroup;
        }
    }
    //6-16-22 added this when creating new capsule in empty group - not sure if it's the reason but the existing group wasn't in capsulesOfNewGroup above
    //could be other reasons why wasn't in the group - should add logic to verify group id is valid
    modifiedCapsulesOfGroup.push({id: id, content: content});
    return modifiedCapsulesOfGroup;
}

function mergeGroups(groups, stateGroups) {
    let mergedGroups = stateGroups || [];
    let groupsToAdd = groups || [];

    let isInList;
    for (let i in groupsToAdd) {
        isInList = false;
        for (let j in mergedGroups) {
            if (mergedGroups[j].id === groupsToAdd[i].id) {
                mergedGroups[j].content = groupsToAdd[i].content;
                isInList = true;
                break;
            }
        }
        if (!isInList) {
            mergedGroups.push(groupsToAdd[i]);
        }
    }
    return mergedGroups;
}

function getIndexOfGroup(id, groupArray) {
    for (let i in groupArray) {
        if (groupArray[i].id === id) {
            return i;
        }
    }
}

function refreshCapsuleLists(state, capsule) {
    let modifiedState = state;
    for (let i in modifiedState.userCapsules) {
        if (capsule.id === modifiedState.userCapsules[i].id) {
            modifiedState.userCapsules[i] = capsule;
        }
    }
    let reOrderUserCapsules = modifiedState.userCapsules;
    modifiedState.userCapsules = sortAlphabetically(reOrderUserCapsules);

    if (capsule.groups.length > 0) {
        for (let i in capsule.groups) {
            for (let j in modifiedState.userGroupCapsules) {
                if (modifiedState.userGroupCapsules[j].id === capsule.groups[i]) {
                    for (let z in modifiedState.userGroupCapsules[j].content) {
                        if (capsule.id === modifiedState.userGroupCapsules[j].content[z].id) {
                            modifiedState.userGroupCapsules[j].content[z] = capsule;
                            break;
                        }
                    }
                }
            }
        }
    }
    reOrderUserCapsules = modifiedState.userGroupCapsules;
    modifiedState.userGroupCapsules = sortAlphabetically(reOrderUserCapsules);

    return modifiedState;
}

function removeBCapsule(state, capsuleId) {

    let modifiedState = state;

    if (modifiedState.userGroupCapsules && modifiedState.userGroupCapsules.length > 0) {
        for (let i in modifiedState.userGroupCapsules) {
            modifiedState.userGroupCapsules[i].content = removeCapsule(capsuleId, modifiedState.userGroupCapsules[i].content);
        }
    }
    let reOrderUserCapsules = modifiedState.userGroupCapsules;
    modifiedState.userGroupCapsules = sortAlphabetically(reOrderUserCapsules);

    return modifiedState;
}
